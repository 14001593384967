import { reactive, computed, readonly } from "vue";
import sideMenuCheckboxService from "./sideMenuCheckboxService"

const state  = reactive({
    vastuuyksikko: NaN as number,
    huonekortit: computed(() => { return sideMenuCheckboxService.state.valinnat}) 
})

const methods = {
    setVastuuyksikko(vastuuyksikko : number) {
        state.vastuuyksikko = vastuuyksikko
    },
    clearHuonekortit() {
        sideMenuCheckboxService.methods.clear()
    }
}

export default {
    state: readonly(state),
    methods
}