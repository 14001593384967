import { KayttajaRequest } from "@/api/models/kayttaja-request"
import { OikeusProperties } from "@/api/models/oikeus-properties"
import { OikeusRequest } from "@/api/models/oikeus-request"
import { OikeusResponse } from "@/api/models/oikeus-response"
import adminApi from "@/apis/adminApi"
import kayttajaApi from "@/apis/kayttajaApi"
import { ElMessage } from 'element-plus';
import useSWRV from "swrv"
import { computed } from "vue"

const methods = {
    getVastuuyksikkoOikeudet(vastuuyksikot : number[]) {
        return adminApi.getVastuuyksikkoOikeudet(vastuuyksikot).then(response => {
            return response
        }).catch(error => console.log(error))
    },
    updateOikeus(id : number, properties : OikeusProperties) {
        return adminApi.updateOikeus(id, properties).then(response => {
            return response
        }).catch(error => console.log(error))
    },
    addOikeus(request : OikeusRequest) {
        return adminApi.addOikeus(request).then(response => {
            return response
        }).catch(error => console.log(error))
    },
    async addOikeudet(requests : OikeusRequest[]) {
        try {
            return await adminApi.addOikeudet(requests)
        }
        catch(error) {
            console.log(error)
        }
    },
    deleteOikeus(id : number) {
        return adminApi.deleteOikeus(id).then(() => {
            return true
        }).catch(error => console.log(error))
    },
    deleteAllOikeudet(ids : number[]) {
        return adminApi.deleteAllOikeudet(ids).then(() => {
            return true
        }).catch(error => console.log(error))
    },
    async getSairaalaKayttajatByHankeId(id : number) {
        try {
            return await adminApi.getSairaalaKayttajatByHankeId(id)
        }
        catch(error) {
            console.log(error)
        }
    },
    async getKayttajaById(id : number) {
        try {
            return await kayttajaApi.getKayttajaById(id)
        }
        catch(error) {
            console.log(error)
        }
    },
    async getKayttajat(email? : string, page? : number) {
        const params = new URLSearchParams()
        const base = "kayttajat/"

        if(email) {
            params.set("email", email)
        }
        if(page) {
            params.set("page", page.toString())
        }

        const url = base + "?" + params.toString()

        try {
            return await kayttajaApi.getKayttajat(url)
        }
        catch(error) {
            console.log(error)
        }
    },
    async addKayttaja(kayttaja : KayttajaRequest) {
        try{
            return await kayttajaApi.addKayttaja(kayttaja)
        }
        catch(error) {
            console.log(error)
        }
    },
    async updateKayttaja(id : number, kayttaja : KayttajaRequest) {
        const response = await kayttajaApi.updateKayttaja(id, kayttaja)

        if(response) {
            ElMessage.success("Käyttäjän muokkaus tallennettu!")

            return response
        }
        else {
            ElMessage.error("Käyttäjän muokkaus ei onnistunut!")
        }

    },
    async getPaakayttajatByHankeId(id : number) {
        try {
            return await adminApi.getHankePaakayttajat(id)
        }
        catch(error) {
            console.log(error)
        }
    },
    async addPaakayttaja(hankeId : number, kayttajaId : number) {
        try {
            return await adminApi.addHankePaakayttaja(hankeId, kayttajaId)
        }
        catch(error) {
            console.log(error)
        }
    },
    async addPaakayttajat(hankeId : number, kayttajaIds : number[]) {
        try {
            return await adminApi.addHankePaakayttajat(hankeId, kayttajaIds)
        }
        catch(error) {
            console.log(error)
        }
    },
    async deletePaakayttaja(id : number) {
        try {
            await adminApi.deleteHankePaakayttaja(id)
            
            return true
        }
        catch(error) {
            console.log(error)
        }
    },
    mapProperties(response : OikeusResponse) {
        const properties : OikeusProperties = {}

        properties.kirjoitus = response.kirjoitus
        properties.luku = response.luku
        properties.raportointi = response.raportointi

        return properties
    }
}

const usePaakayttajat = (hankeId : number | undefined) => {
    const key = computed(() => '/oikeudet/paakayttajat/hankeId/' + hankeId)
    const fetcher = () => {
        if(hankeId) {
            return adminApi.getHankePaakayttajat(hankeId)
        }
    }
    const { data, error, mutate } = useSWRV(() => key.value, fetcher)

    const del = async (id : number) => {
        try {
            await adminApi.deleteHankePaakayttaja(id)
            
            return true
        }
        catch(error) {
            ElMessage.error("Pääkäyttäjän poisto ei onnistunut!")
        }
    }

    const add = async (hankeId : number, kayttajaId : number) => {
        try {
            return await adminApi.addHankePaakayttaja(hankeId, kayttajaId)
        }
        catch(error) {
            ElMessage.error("Pääkäyttäjän lisäys ei onnistunut!")
        }
    }

    return {
        data,
        error,
        mutate,
        del,
        add,
    }
}

export default {
    methods,
    usePaakayttajat
}