import adminService from '@/methods/adminService';
import { defineComponent, ref, watch } from 'vue';
import ComSelect from '../common/ComSelect.vue';
export default defineComponent({
  emits: ["valitutKayttajat"],
  setup(props, {
    emit
  }) {
    const getKayttajat = adminService.methods.getKayttajat;
    const kayttajat = ref([]);
    const valitutKayttajat = ref();
    const loading = ref(false);
    const loadKayttajat = async val => {
      if (val && val.length > 2) {
        loading.value = true;
        const response = await getKayttajat(val);
        if (response) {
          kayttajat.value = response;
        }
        loading.value = false;
      } else {
        kayttajat.value = [];
      }
    };
    watch(valitutKayttajat, () => {
      emit("valitutKayttajat", valitutKayttajat.value);
    });
    return {
      kayttajat,
      valitutKayttajat,
      loading,
      loadKayttajat
    };
  },
  components: {
    ComSelect
  }
});