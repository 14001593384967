import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComFilterInput = _resolveComponent("ComFilterInput");
  const _component_ComHuonekorttiNimikeHistoriaItem = _resolveComponent("ComHuonekorttiNimikeHistoriaItem");
  const _component_el_timeline_item = _resolveComponent("el-timeline-item");
  const _component_el_timeline = _resolveComponent("el-timeline");
  const _component_ComEmpty = _resolveComponent("ComEmpty");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_ComFilterInput, {
    modelValue: _ctx.filter,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filter = $event)
  }, null, 8, ["modelValue"]), _ctx.data ? (_openBlock(), _createBlock(_component_el_timeline, {
    key: 0
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredData, muutos => {
      return _openBlock(), _createBlock(_component_el_timeline_item, {
        key: muutos.id,
        timestamp: muutos.aika,
        placement: "top",
        type: _ctx.type(muutos.tyyppi),
        size: "large"
      }, {
        default: _withCtx(() => [_createVNode(_component_ComHuonekorttiNimikeHistoriaItem, {
          data: _ctx.currentData(muutos)
        }, null, 8, ["data"])]),
        _: 2
      }, 1032, ["timestamp", "type"]);
    }), 128))]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_ComEmpty, {
    key: 1
  }))], 64);
}