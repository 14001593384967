import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComImportButton = _resolveComponent("ComImportButton");
  const _component_ComHuonekorttiValinta = _resolveComponent("ComHuonekorttiValinta");
  const _component_ComVastuuyksikkoValinta = _resolveComponent("ComVastuuyksikkoValinta");
  const _component_ComVarmistus = _resolveComponent("ComVarmistus");
  const _component_ArrowLeft = _resolveComponent("ArrowLeft");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_ArrowRight = _resolveComponent("ArrowRight");
  const _component_el_button_group = _resolveComponent("el-button-group");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_step = _resolveComponent("el-step");
  const _component_el_steps = _resolveComponent("el-steps");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_ComImportButton, {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.visible = true)
  }), _ctx.visible ? (_openBlock(), _createBlock(_component_el_dialog, {
    key: 0,
    title: _ctx.header[_ctx.active],
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.visible = $event),
    "destroy-on-close": "",
    "append-to-body": ""
  }, {
    default: _withCtx(() => [_withDirectives(_createVNode(_component_ComHuonekorttiValinta, {
      class: "card-margin-bottom"
    }, null, 512), [[_vShow, _ctx.active === 0]]), _withDirectives(_createVNode(_component_ComVastuuyksikkoValinta, {
      class: "card-margin-bottom"
    }, null, 512), [[_vShow, _ctx.active === 1]]), _withDirectives(_createVNode(_component_ComVarmistus, {
      class: "card-margin-bottom",
      onPeruuta: _ctx.peruuta
    }, null, 8, ["onPeruuta"]), [[_vShow, _ctx.active === 2]]), _createVNode(_component_el_row, {
      class: "card-margin-bottom"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 5
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button_group, null, {
          default: _withCtx(() => [_ctx.active > 0 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            size: "small",
            onClick: _cache[1] || (_cache[1] = $event => _ctx.active--)
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_ArrowLeft)]),
              _: 1
            }), _createTextVNode(" Edellinen ")]),
            _: 1
          })) : _createCommentVNode("", true), _ctx.active < 2 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            size: "small",
            onClick: _ctx.seuraava
          }, {
            default: _withCtx(() => [_createTextVNode(" Seuraava "), _createVNode(_component_el_icon, {
              class: "el-icon--right"
            }, {
              default: _withCtx(() => [_createVNode(_component_ArrowRight)]),
              _: 1
            })]),
            _: 1
          }, 8, ["onClick"])) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_steps, {
      active: _ctx.active
    }, {
      default: _withCtx(() => [_createVNode(_component_el_step, {
        title: "Vaihe 1",
        description: "Valitse kopioitavat huonekortit"
      }), _createVNode(_component_el_step, {
        title: "Vaihe 2",
        description: "Valitse kopioinnin kohde"
      }), _createVNode(_component_el_step, {
        title: "Vaihe 3",
        description: "Varmistus"
      })]),
      _: 1
    }, 8, ["active"])]),
    _: 1
  }, 8, ["title", "modelValue"])) : _createCommentVNode("", true)], 64);
}