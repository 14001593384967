import { defineComponent } from 'vue';
import { useAuth0 } from "@/auth/auth0-plugin";
export default defineComponent({
  name: "LoginButton",
  setup() {
    const auth0 = useAuth0();
    const login = () => {
      if (!auth0) {
        return;
      }
      const url = window.localStorage.getItem("targetUrl");
      const targetUrl = url ? {
        appState: {
          targetUrl: url
        }
      } : undefined;
      auth0.login(targetUrl);
    };
    return {
      login
    };
  }
});