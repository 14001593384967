import { HuonekorttiHistoriaTyyppi } from '@/api/models/huonekortti-historia-tyyppi';
import { computed, defineComponent } from 'vue';
import ComCard from '@/components/common/ComCard.vue';
export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const item = computed(() => {
      var _a, _b;
      const item = {
        otsikko: "",
        sisalto: ""
      };
      const nimike = (_b = (_a = props.data.nimike) === null || _a === void 0 ? void 0 : _a.nimi) === null || _b === void 0 ? void 0 : _b.toLowerCase();
      if (props.data.tyyppi == HuonekorttiHistoriaTyyppi.Lisays) {
        item.otsikko = `Nimike ${nimike} lisätty`;
      }
      if (props.data.tyyppi == HuonekorttiHistoriaTyyppi.Poisto) {
        item.otsikko = `Nimike ${nimike} poistettu`;
      }
      if (props.data.tyyppi == HuonekorttiHistoriaTyyppi.Muutos) {
        item.otsikko = `Nimikettä ${nimike} muokattu`;
      }
      return item;
    });
    const ominaisuudet = computed(() => {
      var _a;
      return (_a = props.data.ominaisuudet) === null || _a === void 0 ? void 0 : _a.map(o => o.nimi).join(", ");
    });
    return {
      item,
      ominaisuudet
    };
  },
  components: {
    ComCard
  }
});