import { defineComponent, ref } from 'vue';
import ComLoading from '../common/ComLoading.vue';
import ComYllapitoHankeLisaaVastuuyksikko from './ComYllapitoHankeLisaaVastuuyksikko.vue';
import { useAddHanke } from './yllapitoHankeComposables';
export default defineComponent({
  components: {
    ComLoading,
    ComYllapitoHankeLisaaVastuuyksikko
  },
  setup() {
    const {
      sairaalat,
      hanke,
      add
    } = useAddHanke();
    const ruleFormRef = ref();
    const rules = ref({
      nimi: [{
        required: true,
        message: 'Nimi vaaditaan',
        trigger: 'blur'
      }],
      sairaala: [{
        required: true,
        message: 'Sairaala vaaditaan',
        trigger: 'blur'
      }]
    });
    const addHankeClick = async () => {
      var _a;
      await ((_a = ruleFormRef.value) === null || _a === void 0 ? void 0 : _a.validate(valid => {
        if (!valid) {
          return;
        } else {
          add();
        }
      }));
    };
    return {
      hanke,
      sairaalat,
      rules,
      ruleFormRef,
      addHankeClick
    };
  }
});