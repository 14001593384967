import { KommenttiResponse } from "@/api/models/kommentti-response";
import { KommenttiRequest } from "@/api/models/kommentti-request";
import http from "@/http-common";
import { errorHandler } from "./baseApi";
import kommenttiTyyppi from "@/types/Kommentti";

class KommentitApi {

    getKommentit(viite : number, tyyppi : kommenttiTyyppi) : Promise<KommenttiResponse[] | undefined> {
        return http.get("/kommentit", { params: {
            viite: viite,
            tyyppi: tyyppi
        }}).then(response => {
            return response.data
        }).catch(error  => {
            errorHandler(error)
        })
    }
    addKommentti(request : KommenttiRequest) : Promise<KommenttiResponse | undefined>{
        return http.post("/kommentit", request).then(reponse => {
            return reponse.data
        }).catch(error => {
            errorHandler(error)
        })
    }
    deleteKommentti(id : number) : Promise<boolean> {
        return http.delete(`/kommentit/${id}`).then(() => {
            return true
        }).catch(error => {
            errorHandler(error)

            return false
        })
    }
}

export default new KommentitApi