import kommenttiService from '@/methods/kommenttiService';
import { defineComponent, provide, watch } from 'vue';
import ComCard from '../common/ComCard.vue';
import ComDeleteKommentti from './ComDeleteKommentti.vue';
import ComLoading from '../common/ComLoading.vue';
import ComEmpty from '../common/ComEmpty.vue';
import ComAddKommentti from './ComAddKommentti.vue';
export default defineComponent({
  components: {
    ComCard,
    ComDeleteKommentti,
    ComLoading,
    ComEmpty,
    ComAddKommentti
  },
  props: {
    viite: {
      type: Number,
      required: true
    },
    tyyppi: {
      type: Number,
      required: true
    }
  },
  emits: ["kommenttiChange"],
  setup(props, {
    emit
  }) {
    const getKommentit = kommenttiService.methods.getKommentit;
    const {
      data: kommentit
    } = getKommentit(props.viite, props.tyyppi);
    provide("kommentit", kommentit);
    watch(kommentit, () => {
      emit("kommenttiChange", kommentit.value);
    });
    return {
      kommentit
    };
  }
});