import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_alert = _resolveComponent("el-alert");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_ComDeleteButton = _resolveComponent("ComDeleteButton");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_alert, {
    type: "info",
    title: "Huom! Mallihuonekortit ovat hyvinvointialue kohtaisia."
  }), _createVNode(_component_el_table, {
    data: _ctx.data
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      prop: "tilanimike.nimi",
      label: "Tilanimike"
    }), _createVNode(_component_el_table_column, {
      prop: "toiminta",
      label: "Tilanimikkeen täsmennys"
    }), _createVNode(_component_el_table_column, null, {
      default: _withCtx(scope => [_createVNode(_component_ComDeleteButton, {
        onClick: $event => _ctx.deleteClick(scope.row.id)
      }, null, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])], 64);
}