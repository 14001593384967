import { defineComponent, inject, computed } from 'vue';
import { useMallihuonekortit } from '@/components/mallihuonekortit/mallihuonekortitComposables';
import ComDeleteButton from '../common/buttons/ComDeleteButton.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { provideHanke } from '@/types/Symbols';
export default defineComponent({
  components: {
    ComDeleteButton
  },
  setup() {
    const hanke = inject(provideHanke);
    const sairaala = computed(() => {
      var _a, _b;
      return (_b = (_a = hanke === null || hanke === void 0 ? void 0 : hanke.value) === null || _a === void 0 ? void 0 : _a.sairaala) === null || _b === void 0 ? void 0 : _b.id;
    });
    const {
      data,
      del,
      mutate
    } = useMallihuonekortit(sairaala);
    const deleteClick = async id => {
      ElMessageBox.confirm("Haluatko varmasti poistaa mallihuonekortin?").then(async () => {
        const response = await del(id);
        if (response) {
          ElMessage.success("Mallihuonekortti poistettu!");
          mutate();
        }
      });
    };
    return {
      data,
      sairaala,
      deleteClick
    };
  }
});