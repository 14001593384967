import "core-js/modules/es.array.push.js";
import Orientation from '@/types/OrientationEnum';
import { defineComponent, ref, inject, computed, watch } from 'vue';
import ComCardExpand from '../common/ComCardExpand.vue';
import ComLisaaNimike from './ComLisaaNimike.vue';
import { provideHuonekortti, provideHuonekorttiIsEdited, provideNimikkeet } from '../../types/Symbols';
import { ElMessage, ElMessageBox } from 'element-plus';
import huonekorttiAPI from '@/apis/huonekorttiAPI';
import ComEmpty from '../common/ComEmpty.vue';
import ComEditButton from '../common/buttons/ComEditButton.vue';
import ComDeleteButton from '../common/buttons/ComDeleteButton.vue';
import ComSaveButton from '../common/buttons/ComSaveButton.vue';
import ComCancelIconButton from '../common/buttons/ComCancelIconButton.vue';
import { Folder, FolderOpened, Warning } from '@element-plus/icons-vue';
import { HuonekorttiStatusResponse } from '@/api/models/huonekortti-status-response';
import huonekorttiHistoriaService from '@/methods/huonekorttiHistoriaService';
import { HuonekorttiHistoriaTyyppi } from '@/api/models/huonekortti-historia-tyyppi';
import ComNimikeHistoriaPopover from './historia/suunnittuelutiedot/ComNimikeHistoriaPopover.vue';
import useSWRV from 'swrv';
export default defineComponent({
  props: {
    kategoriat: {
      required: true,
      type: Object
    }
  },
  components: {
    ComCardExpand,
    ComLisaaNimike,
    ComEmpty,
    ComEditButton,
    ComDeleteButton,
    ComSaveButton,
    ComCancelIconButton,
    Folder,
    FolderOpened,
    Warning,
    ComNimikeHistoriaPopover
  },
  setup(props) {
    const writingRights = inject("writingRights");
    const huonekortti = inject(provideHuonekortti);
    const nimikkeet = inject(provideNimikkeet);
    const isEdited = inject(provideHuonekorttiIsEdited);
    if (!isEdited) {
      throw new Error("Injektointi epäonnistui!");
    }
    const getHuonekorttiHistoria = huonekorttiHistoriaService.methods.getHuonekorttiHistoria;
    const diffHistoriaNimike = huonekorttiHistoriaService.methods.diffHistoriaNimike;
    const o = Orientation.vertical;
    const active = ref([]);
    const muutoksetActive = ref(false);
    const edit = ref(undefined);
    const selectedOminaisuudet = ref([]);
    const ominaisuudet = ref([]);
    const oldItem = ref();
    const hyvaksytty = computed(() => {
      var _a;
      return ((_a = huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value) === null || _a === void 0 ? void 0 : _a.status) === HuonekorttiStatusResponse.Hyvaksytty ? true : false;
    });
    const historiaKey = computed(() => (huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value) && hyvaksytty.value && muutoksetActive.value ? getHuonekorttiHistoria.key(huonekortti.value.id) : undefined);
    const {
      data: historia
    } = useSWRV(() => historiaKey.value, getHuonekorttiHistoria.fetcher, getHuonekorttiHistoria.options);
    const nimikkeetHistoria = computed(() => {
      var _a;
      if (historiaKey.value && ((_a = historia.value) === null || _a === void 0 ? void 0 : _a.nimikkeet)) {
        const historiaNimikkeet = historia.value.nimikkeet;
        const result = [];
        nimikkeet === null || nimikkeet === void 0 ? void 0 : nimikkeet.value.forEach(n => {
          const hs = historiaNimikkeet === null || historiaNimikkeet === void 0 ? void 0 : historiaNimikkeet.find(h => h.huonekorttiNimikeId == n.id);
          if (hs) {
            result.push(diffHistoriaNimike(hs, n));
          } else {
            result.push(n);
          }
        });
        historiaNimikkeet === null || historiaNimikkeet === void 0 ? void 0 : historiaNimikkeet.filter(h => h.tyyppi == HuonekorttiHistoriaTyyppi.Poisto).forEach(p => {
          result.push(diffHistoriaNimike(p));
        });
        return result;
      } else {
        return nimikkeet === null || nimikkeet === void 0 ? void 0 : nimikkeet.value;
      }
    });
    const isLisays = item => {
      return huonekorttiHistoriaService.methods.tyyppiIsLisays(item);
    };
    const isPoisto = item => {
      return huonekorttiHistoriaService.methods.tyyppiIsPoisto(item);
    };
    const hasPaakategoriaItems = id => {
      let children = [];
      let b = false;
      props.kategoriat.forEach(k => {
        if (k.id == id && k.children) {
          children = k.children;
        }
      });
      if (nimikkeetHistoria.value) {
        nimikkeetHistoria === null || nimikkeetHistoria === void 0 ? void 0 : nimikkeetHistoria.value.forEach(i => {
          children.forEach(c => {
            var _a, _b;
            if (c.id === ((_b = (_a = i.nimike) === null || _a === void 0 ? void 0 : _a.kategoria) === null || _b === void 0 ? void 0 : _b.id)) {
              b = true;
            }
          });
        });
      }
      return b;
    };
    const hasAlikategoriaItems = id => {
      let b = false;
      if (nimikkeetHistoria.value) {
        nimikkeetHistoria.value.forEach(i => {
          var _a, _b;
          if (id == ((_b = (_a = i.nimike) === null || _a === void 0 ? void 0 : _a.kategoria) === null || _b === void 0 ? void 0 : _b.id)) b = true;
        });
      }
      return b;
    };
    const editNimike = (item, aliKategoria) => {
      var _a;
      const n = (_a = aliKategoria.nimikkeet) === null || _a === void 0 ? void 0 : _a.find(a => {
        var _a;
        return a.id == ((_a = item === null || item === void 0 ? void 0 : item.nimike) === null || _a === void 0 ? void 0 : _a.id);
      });
      oldItem.value = {
        ...item
      };
      selectedOminaisuudet.value = [];
      edit.value = item.id;
      if (n && n.ominaisuudet) {
        ominaisuudet.value = n.ominaisuudet;
      } else ominaisuudet.value = [];
      if (item.ominaisuudet) item.ominaisuudet.forEach(i => {
        selectedOminaisuudet.value.push(i.id);
      });
    };
    const cancelEdit = item => {
      edit.value = undefined;
      Object.assign(item, oldItem.value);
    };
    const saveNimike = item => {
      const saveOminaisuudet = [];
      const nimikeRequest = {};
      const ominaisuudetRequest = [];
      selectedOminaisuudet.value.forEach(s => {
        ominaisuudet.value.forEach(o => {
          if (o.id === s) {
            saveOminaisuudet.push(o);
            ominaisuudetRequest.push({
              id: s
            });
          }
        });
      });
      nimikeRequest.maara = item.maara;
      nimikeRequest.tasmennys = item.tasmennys;
      nimikeRequest.ominaisuudet = ominaisuudetRequest;
      huonekorttiAPI.updateNimike(item.id, nimikeRequest).then(result => {
        var _a;
        item.ominaisuudet = saveOminaisuudet;
        edit.value = undefined;
        ElMessage({
          showClose: true,
          message: "Nimike " + ((_a = item.nimike) === null || _a === void 0 ? void 0 : _a.nimi) + " tallennettu",
          center: true,
          type: 'success'
        });
      });
    };
    const deleteNimike = (item, index) => {
      var _a;
      if (item.id != undefined) {
        const id = item.id;
        ElMessageBox.confirm('Haluatko varmasti poistaa nimikkeen: ' + ((_a = item.nimike) === null || _a === void 0 ? void 0 : _a.nimi)).then(() => {
          huonekorttiAPI.deleteNimike(id).then(() => {
            var _a;
            if (nimikkeet === null || nimikkeet === void 0 ? void 0 : nimikkeet.value) {
              nimikkeet.value.splice(index, 1);
            }
            ElMessage({
              showClose: true,
              message: "Nimike " + ((_a = item.nimike) === null || _a === void 0 ? void 0 : _a.nimi) + " poistettu",
              center: true,
              type: 'success'
            });
          });
        }).catch(() => {
          console.log("ei poistettu");
        });
      }
    };
    const togleAll = () => {
      if (active.value.length > 0) {
        active.value = [];
      } else {
        props.kategoriat.forEach(k => {
          if (k != undefined && k.id != undefined) active.value.push(k.id);
        });
      }
    };
    watch(edit, () => {
      if (!edit.value) {
        isEdited.value.suunnittelutiedot = false;
      } else {
        isEdited.value.suunnittelutiedot = true;
      }
    });
    return {
      o,
      active,
      nimikkeet,
      ominaisuudet,
      edit,
      selectedOminaisuudet,
      writingRights,
      hyvaksytty,
      muutoksetActive,
      nimikkeetHistoria,
      hasPaakategoriaItems,
      hasAlikategoriaItems,
      deleteNimike,
      editNimike,
      saveNimike,
      cancelEdit,
      togleAll,
      isLisays,
      isPoisto
    };
  }
});