import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComStatusTag = _resolveComponent("ComStatusTag");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  return !_ctx.readOnly ? (_openBlock(), _createBlock(_component_el_dropdown, {
    key: 0,
    class: "cursor"
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
      default: _withCtx(() => [_ctx.modelValue == _ctx.HuonekorttiStatusResponse.Tyo ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 0,
        onClick: _ctx.julkaiseHuonekorttiClick
      }, {
        default: _withCtx(() => [_createTextVNode(" Julkaise ")]),
        _: 1
      }, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.modelValue == _ctx.HuonekorttiStatusResponse.Julkaistu ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_ctx.isPaakayttaja ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 0,
        onClick: _ctx.hyvaksyHuonekorttiClick
      }, {
        default: _withCtx(() => [_createTextVNode(" Hyväksy ")]),
        _: 1
      }, 8, ["onClick"])) : (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 1,
        onClick: _ctx.hyvaksytaHuonekorttiClick
      }, {
        default: _withCtx(() => [_createTextVNode(" Lähetä hyväksyttäväksi ")]),
        _: 1
      }, 8, ["onClick"]))], 64)) : _createCommentVNode("", true), _ctx.modelValue == _ctx.HuonekorttiStatusResponse.Odottaa ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 2
      }, [_ctx.isPaakayttaja ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_el_dropdown_item, {
        onClick: _ctx.hyvaksyHuonekorttiClick
      }, {
        default: _withCtx(() => [_createTextVNode(" Hyväksy ")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_dropdown_item, {
        onClick: _ctx.hylkaaHuonekorttiClick
      }, {
        default: _withCtx(() => [_createTextVNode(" Hylkää ")]),
        _: 1
      }, 8, ["onClick"])], 64)) : (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 1,
        onClick: _ctx.hylkaaHuonekorttiClick
      }, {
        default: _withCtx(() => [_createTextVNode(" Peruuta ")]),
        _: 1
      }, 8, ["onClick"]))], 64)) : _createCommentVNode("", true)]),
      _: 1
    })]),
    default: _withCtx(() => [_createVNode(_component_ComStatusTag, {
      status: _ctx.modelValue
    }, null, 8, ["status"])]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_ComStatusTag, {
    key: 1,
    status: _ctx.modelValue
  }, null, 8, ["status"]));
}