import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_ComSelect = _resolveComponent("ComSelect");
  const _component_ComDeleteButton = _resolveComponent("ComDeleteButton");
  const _component_el_space = _resolveComponent("el-space");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, null, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Pääkäyttäjät"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_space, null, {
        default: _withCtx(() => [_createVNode(_component_ComSelect, {
          modelValue: _ctx.selected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.selected = $event)
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paakayttajat, (paakayttaja, index) => {
            return _openBlock(), _createBlock(_component_el_option, {
              key: index,
              value: paakayttaja.id,
              label: paakayttaja.kayttaja.etunimi + ' ' + paakayttaja.kayttaja.sukunimi
            }, null, 8, ["value", "label"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_ComDeleteButton, {
          onClick: _ctx.deletePaakayttajaClick
        }, null, 8, ["onClick"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}