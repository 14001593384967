import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "float": "right"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComPagination = _resolveComponent("ComPagination");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_link = _resolveComponent("el-link");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ComPagination, {
        total: _ctx.count,
        onPageChange: _ctx.pageChangeClicked
      }, null, 8, ["total", "onPageChange"])])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_table, {
    data: _ctx.huonekortit
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      label: "Tilanimike",
      prop: "tilanimike.nimi"
    }), _createVNode(_component_el_table_column, {
      label: "Tilanimikeen täsmennys",
      prop: "toiminta"
    }), _createVNode(_component_el_table_column, {
      label: "Vastuuyksikkö",
      prop: "vastuuyksikko.nimi"
    }), _createVNode(_component_el_table_column, {
      width: "100px"
    }, {
      default: _withCtx(scope => [_createVNode(_component_el_link, {
        type: "primary",
        onClick: $event => _ctx.palautaButtonClicked(scope.row.id)
      }, {
        default: _withCtx(() => [_createTextVNode("Palauta")]),
        _: 2
      }, 1032, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])], 64);
}