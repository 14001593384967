import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_ComSelect = _resolveComponent("ComSelect");
  const _component_el_link = _resolveComponent("el-link");
  const _component_ComOikeusButtons = _resolveComponent("ComOikeusButtons");
  const _component_el_space = _resolveComponent("el-space");
  return _openBlock(), _createBlock(_component_el_space, {
    wrap: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_ComSelect, {
      modelValue: _ctx.vastuuyksikkoSelected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.vastuuyksikkoSelected = $event),
      multiple: ""
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vastuuyksikot, (vastuuyksikko, index) => {
        return _openBlock(), _createBlock(_component_el_option, {
          key: index,
          label: vastuuyksikko.nimi,
          value: vastuuyksikko.id
        }, null, 8, ["label", "value"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue"]), _createVNode(_component_el_link, {
      type: "primary",
      onClick: _ctx.valitseKaikkiclick
    }, {
      default: _withCtx(() => [_createTextVNode("valitse kaikki")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_ComOikeusButtons, {
      oikeudet: _ctx.oikeudet,
      onOikeusChange: _cache[1] || (_cache[1] = val => _ctx.oikeudet = val)
    }, null, 8, ["oikeudet"])]),
    _: 1
  });
}