import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComHanketiedotHanke = _resolveComponent("ComHanketiedotHanke");
  const _component_el_col = _resolveComponent("el-col");
  const _component_ComHanketiedotHuonekortti = _resolveComponent("ComHanketiedotHuonekortti");
  const _component_el_row = _resolveComponent("el-row");
  const _component_ComHanketiedotVaatimukset = _resolveComponent("ComHanketiedotVaatimukset");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 12
    }, {
      default: _withCtx(() => [_createVNode(_component_ComHanketiedotHanke, {
        editable: _ctx.editable
      }, null, 8, ["editable"])]),
      _: 1
    }), _ctx.huonekortti ? (_openBlock(), _createBlock(_component_el_col, {
      key: 0,
      span: 12
    }, {
      default: _withCtx(() => [_createVNode(_component_ComHanketiedotHuonekortti)]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createElementVNode("div", null, [_createVNode(_component_ComHanketiedotVaatimukset, {
    editable: _ctx.editable
  }, null, 8, ["editable"])])], 64);
}