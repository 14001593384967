import ComPagination from '@/components/common/ComPagination.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    huonekortit: {
      type: Object,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  emits: ["palautaHuonekortti", "pageChange"],
  components: {
    ComPagination
  },
  setup(props, {
    emit
  }) {
    const palautaButtonClicked = async id => {
      emit("palautaHuonekortti", id);
    };
    const pageChangeClicked = page => {
      emit("pageChange", page);
    };
    return {
      palautaButtonClicked,
      pageChangeClicked
    };
  }
});