import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_button, {
    onClick: _ctx.login,
    type: "success"
  }, {
    default: _withCtx(() => [_createTextVNode(" Kirjaudu sisään ")]),
    _: 1
  }, 8, ["onClick"]);
}