import { defineComponent, ref, computed } from 'vue';
import ComHuonekorttiRaportti from './ComHuonekorttiRaportti.vue';
import raportointiService from '@/methods/raportointiService';
import huonekorttiTableStore from '../huonekortit/table/huonekorttiTableStore';
import { ElMessage } from 'element-plus';
import ComSelect from '@/components/common/ComSelect.vue';
import ComMuutoslogiRaportti from './ComMuutoslogiRaportti.vue';
import ComNimikeRaportti from './ComNimikeRaportti.vue';
import ComTilaRaportti from './ComTilaRaportti.vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
export default defineComponent({
  components: {
    ComHuonekorttiRaportti,
    ComSelect,
    ComMuutoslogiRaportti,
    ComNimikeRaportti,
    ComTilaRaportti,
    ComSuccessButton
  },
  props: {
    vastuuyksikot: {
      type: Array
    }
  },
  setup(props) {
    const loadHuonekorttiRaportti = raportointiService.methods.loadHuonekorttiRaportti;
    const loadHistoriaRaportti = raportointiService.methods.loadHistoriaRaportti;
    const loadTilaRaportti = raportointiService.methods.loadTilaRaportti;
    const loadNimikeRaportti = raportointiService.methods.loadNimikeRaportti;
    const getSelectedHuonekortit = huonekorttiTableStore.getters.getSelected;
    const loadingRaportti = computed(() => raportointiService.state.loading);
    const tyyppi = ['Huonekortti', 'Tila', 'Historia', 'Nimike'];
    const valittu = ref("");
    const historiaRange = ref();
    const huonekorteittain = ref(false);
    const nimikkeet = ref();
    const rangeChange = val => {
      historiaRange.value = val;
    };
    const huonekorteittainChange = val => {
      huonekorteittain.value = val;
    };
    const nimikkeetChange = val => {
      nimikkeet.value = val;
    };
    const tulosta = val => {
      var _a, _b, _c, _d;
      if (valittu.value == "Huonekortti") {
        if (anyHuonekorttiChecked()) {
          loadHuonekorttiRaportti(val, getSelectedHuonekortit());
        }
      }
      if (valittu.value == "Historia") {
        if (anyHuonekorttiChecked() && ((_a = historiaRange.value) === null || _a === void 0 ? void 0 : _a.length) == 2) {
          loadHistoriaRaportti(val, getSelectedHuonekortit(), historiaRange.value[0], historiaRange.value[1]);
        } else if (historiaRange.value && ((_b = historiaRange.value) === null || _b === void 0 ? void 0 : _b.length) > 2) {
          ElMessage({
            message: 'Valitse raportoitava aikaväli!',
            type: 'error'
          });
        }
      }
      if (valittu.value == "Tila") {
        if (huonekorteittain.value) {
          if (anyHuonekorttiChecked()) {
            loadTilaRaportti(val, undefined, getSelectedHuonekortit());
          }
        } else {
          if (props.vastuuyksikot && ((_c = props.vastuuyksikot) === null || _c === void 0 ? void 0 : _c.length) > 0) {
            loadTilaRaportti(val, props.vastuuyksikot, undefined);
          } else {
            ElMessage({
              message: 'Valitse raportoitava vastuuyksikkö!',
              type: 'error'
            });
          }
        }
      }
      if (valittu.value == "Nimike") {
        if (anyNimikeChecked()) {
          if (huonekorteittain.value) {
            if (anyHuonekorttiChecked()) {
              loadNimikeRaportti(nimikkeet.value, undefined, getSelectedHuonekortit());
            }
          } else {
            if (props.vastuuyksikot && ((_d = props.vastuuyksikot) === null || _d === void 0 ? void 0 : _d.length) > 0) {
              loadNimikeRaportti(nimikkeet.value, props.vastuuyksikot);
            } else {
              ElMessage({
                message: 'Valitse raportoitava vastuuyksikkö!',
                type: 'error'
              });
            }
          }
        }
      }
    };
    const anyHuonekorttiChecked = () => {
      if (getSelectedHuonekortit().length > 0) {
        return true;
      } else {
        ElMessage({
          message: 'Valitse raportoitava kohde!',
          type: 'error'
        });
        return false;
      }
    };
    const anyNimikeChecked = () => {
      if (nimikkeet.value && nimikkeet.value.length > 0) {
        return true;
      } else {
        ElMessage({
          message: 'Valitse vähintään yksi raportoitava nimike!',
          type: 'error'
        });
        return false;
      }
    };
    return {
      tyyppi,
      valittu,
      loadingRaportti,
      historiaRange,
      tulosta,
      rangeChange,
      huonekorteittainChange,
      nimikkeetChange
    };
  }
});