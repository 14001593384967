import { defineComponent } from 'vue';
import ComLisaaHuonekortti from './ComLisaaHuonekortti.vue';
import ComFilterInput from '../../common/ComFilterInput.vue';
import ComPagination from '../../common/ComPagination.vue';
import ComHuonekorttiTableEditable from './table/ComHuonekorttiTableEditable.vue';
import { useHuonekorttiListing } from './huonekortit';
import ComLoading from '@/components/common/ComLoading.vue';
export default defineComponent({
  components: {
    ComLisaaHuonekortti,
    ComFilterInput,
    ComPagination,
    ComHuonekorttiTableEditable,
    ComLoading
  },
  setup() {
    const {
      huonekortit,
      writableVastuuyksikot,
      loading,
      filter,
      totalItems,
      paging,
      sortChanged,
      cancelEdit
    } = useHuonekorttiListing();
    return {
      huonekortit,
      writableVastuuyksikot,
      loading,
      filter,
      totalItems,
      paging,
      sortChanged,
      cancelEdit
    };
  }
});