import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "card-margin-bottom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComLoading = _resolveComponent("ComLoading");
  const _component_el_cascader_panel = _resolveComponent("el-cascader-panel");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_ComHuonekorttiTableWithFilterAndPagination = _resolveComponent("ComHuonekorttiTableWithFilterAndPagination");
  return !_ctx.nimikkeet ? (_openBlock(), _createBlock(_component_ComLoading, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, null, {
      default: _withCtx(() => [_createVNode(_component_el_cascader_panel, {
        options: _ctx.nimikkeet,
        props: _ctx.props,
        modelValue: _ctx.valitutNimikkeet,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.valitutNimikkeet = $event)
      }, null, 8, ["options", "props", "modelValue"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, null, {
      default: _withCtx(() => [_createVNode(_component_el_checkbox, {
        label: "Valitse erikseen raportoitavat huonekortit",
        modelValue: _ctx.huonekorteittain,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.huonekorteittain = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }), _ctx.huonekorteittain ? (_openBlock(), _createBlock(_component_el_divider, {
    key: 0
  })) : _createCommentVNode("", true), _ctx.huonekorteittain ? (_openBlock(), _createBlock(_component_el_row, {
    key: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24
    }, {
      default: _withCtx(() => [_createVNode(_component_ComHuonekorttiTableWithFilterAndPagination, {
        selection: true
      })]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true)]));
}