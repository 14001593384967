import "core-js/modules/es.array.push.js";
import ComCancelIconButton from '@/components/common/buttons/ComCancelIconButton.vue';
import ComMinusButton from '@/components/common/buttons/ComMinusButton.vue';
import ComPlusButton from '@/components/common/buttons/ComPlusButton.vue';
import ComSaveButton from '@/components/common/buttons/ComSaveButton.vue';
import huonekorttiService from '@/methods/huonekorttiService';
import { useHuonekorttiTable } from './huonekorttiTable';
import { ElMessage } from 'element-plus';
import { computed, defineComponent, inject, ref, watchEffect } from 'vue';
import ComKommentointi from '@/components/kommentointi/ComKommentointi.vue';
import ComHuonekorttiDeleteButton from './ComHuonekorttiDeleteButton.vue';
import { provideHankeHuonekorttiListingParams, provideHasPaakayttajaRights, provideWritableVastuuyksikot } from '@/types/Symbols';
import ComStatus from '../ComStatus.vue';
import hankeService from '@/methods/hankeService';
import { Paperclip } from '@element-plus/icons-vue';
import ComMoreButton from '@/components/common/buttons/ComMoreButton.vue';
import { HuonekorttiStatusResponse } from '@/api/models/huonekortti-status-response';
import ComMallihuonekorttiEditToimintaDialog from '@/components/hanke/huonekortit/table/ComMallihuonekorttiEditToimintaDialog.vue';
export default defineComponent({
  components: {
    ComCancelIconButton,
    ComSaveButton,
    ComPlusButton,
    ComMinusButton,
    ComStatus,
    ComKommentointi,
    ComHuonekorttiDeleteButton,
    Paperclip,
    ComMoreButton,
    ComMallihuonekorttiEditToimintaDialog
  },
  props: {
    huonekortit: {
      type: Object
    }
  },
  emits: ["sortChanged", "cancelEdit"],
  setup(props, {
    emit
  }) {
    const hankeVastuuyksikot = inject("vastuuyksikot");
    const tilanimikkeet = inject("tilanimikkeet");
    const hankeId = inject("hankeId");
    const getTilanumero = hankeService.getters.getTilanumero;
    const increaseTilanumero = hankeService.methods.increaseTilanumero;
    const {
      formatTilat
    } = useHuonekorttiTable();
    const getHuonekortti = huonekorttiService.methods.getHuonekortti;
    const updateHuonekortti = huonekorttiService.methods.updateHuonekortti;
    const queryParams = inject(provideHankeHuonekorttiListingParams);
    const hankePaakayttaja = inject(provideHasPaakayttajaRights);
    const writableVastuuyksikot = inject(provideWritableVastuuyksikot);
    const edit = ref();
    const more = ref();
    const tilanumero = computed(() => getTilanumero(hankeId.value));
    const currentTilanumero = ref(0);
    const sort = computed(() => {
      var _a, _b;
      return (_b = (_a = queryParams === null || queryParams === void 0 ? void 0 : queryParams.value) === null || _a === void 0 ? void 0 : _a.sort) !== null && _b !== void 0 ? _b : {
        prop: "id",
        order: "ascending"
      };
    });
    const mallihuonekorttiDialog = ref(false);
    const huonekortti = ref();
    let oldItem;
    let huonekorttiRequest = {};
    const oikeudet = val => {
      if (val.status == HuonekorttiStatusResponse.Hyvaksytty && (hankePaakayttaja === null || hankePaakayttaja === void 0 ? void 0 : hankePaakayttaja.value)) {
        return true;
      } else if (val.status != HuonekorttiStatusResponse.Hyvaksytty && (writableVastuuyksikot === null || writableVastuuyksikot === void 0 ? void 0 : writableVastuuyksikot.value.some(v => v.id == val.vastuuyksikko.id))) {
        return true;
      } else {
        return false;
      }
    };
    const sortChanged = val => {
      emit("sortChanged", val);
    };
    const vastuuyksikkoChanged = (value, item) => {
      const vastuuyksikko = hankeVastuuyksikot.value.find(v => v.id === value);
      if (vastuuyksikko) {
        item.nimi = vastuuyksikko.nimi;
      }
    };
    const tilanimikeChanged = (value, item) => {
      const tilanimike = tilanimikkeet.value.find(v => v.id === value);
      if (tilanimike) {
        item.nimi = tilanimike.nimi;
      }
    };
    const addTila = item => {
      var _a;
      currentTilanumero.value++;
      (_a = item.tilat) === null || _a === void 0 ? void 0 : _a.push({
        tilatunnus: currentTilanumero.value.toString(),
        pintaAla: 0
      });
    };
    const removeTila = (item, index) => {
      var _a, _b;
      if (((_a = item.tilat) === null || _a === void 0 ? void 0 : _a.length) !== 1) {
        (_b = item.tilat) === null || _b === void 0 ? void 0 : _b.splice(index, 1);
      } else {
        ElMessage({
          message: "Huonekortilla pitää olla vähintään yksi tila!",
          type: "warning"
        });
      }
    };
    const editHuonekortti = item => {
      oldItem = JSON.parse(JSON.stringify(item));
      edit.value = item.id;
      currentTilanumero.value = tilanumero.value == undefined ? 1 : tilanumero.value;
    };
    const cancelEdit = index => {
      edit.value = undefined;
      emit("cancelEdit", oldItem, index);
    };
    const saveEdit = async item => {
      var _a, _b;
      if (((_a = item.vastuuyksikko) === null || _a === void 0 ? void 0 : _a.id) && ((_b = item.tilanimike) === null || _b === void 0 ? void 0 : _b.id) && item.tilat && item.tilat.length > 0 && item.id) {
        const huonekortti = await getHuonekortti(item.id);
        huonekorttiRequest = item;
        huonekorttiRequest.toiminnanKuvaus = huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.toiminnanKuvaus;
        huonekorttiRequest.toiminnanVaatimukset = huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.toiminnanVaatimukset;
        if (huonekorttiRequest.tilat) {
          huonekorttiRequest.tilat.forEach(t => {
            t.vastuuyksikko = {
              id: item.vastuuyksikko.id
            };
            t.huonekortti = {
              id: item.id
            };
            t.pintaAla = t.pintaAla || 0;
            if (!t.id) {
              increaseTilanumero(hankeId.value);
            }
          });
        }
        const response = await updateHuonekortti(item.id, huonekorttiRequest);
        if (response) {
          ElMessage.success("Huonekortti tallennettu!");
          item.tilat = response.tilat;
          edit.value = undefined;
        }
      }
    };
    const addMallihuonekorttiClick = val => {
      huonekortti.value = val;
      mallihuonekorttiDialog.value = true;
    };
    watchEffect(() => {
      var _a;
      currentTilanumero.value = (_a = tilanumero.value) !== null && _a !== void 0 ? _a : 0;
    });
    return {
      edit,
      tilanimikkeet,
      writableVastuuyksikot,
      sort,
      more,
      hankePaakayttaja,
      mallihuonekorttiDialog,
      huonekortti,
      sortChanged,
      vastuuyksikkoChanged,
      tilanimikeChanged,
      formatTilat,
      addTila,
      removeTila,
      editHuonekortti,
      cancelEdit,
      saveEdit,
      oikeudet,
      addMallihuonekorttiClick
    };
  }
});