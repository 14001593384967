import huonekorttiTuontiService from '@/methods/huonekorttiTuontiService';
import { defineComponent, inject, ref, watch } from 'vue';
import ComSelect from '../../common/ComSelect.vue';
export default defineComponent({
  setup() {
    const vastuuyksikot = inject("vastuuyksikot");
    const vastuuyksikko = ref();
    watch(vastuuyksikko, () => {
      if (vastuuyksikko.value) {
        huonekorttiTuontiService.methods.setVastuuyksikko(vastuuyksikko.value);
      }
    });
    return {
      vastuuyksikot,
      vastuuyksikko
    };
  },
  components: {
    ComSelect
  }
});