import { HuonekorttiResponse } from '@/api/models/huonekortti-response';
import { HuonekorttiNimikeResponse } from '@/api/models/huonekortti-nimike-response';
import { InjectionKey, Ref } from 'vue';
import { HankeResponse } from '@/api/models/hanke-response';
import { VastuuyksikkoResponse } from '@/api/models/vastuuyksikko-response';
import { TilanimikeResponse } from '@/api/models/tilanimike-response';
import HuonekorttiEdited from './HuonekorttiEdited';
import HankeHuonekorttiListingQueryParams from './HankeHuonekorttiListingQueryParams';
import HankeTilaListingQueryParams from './HankeTilaListingQueryParams'

export const provideNimikkeet: InjectionKey<Ref<HuonekorttiNimikeResponse[]>> = Symbol()
export const provideHuonekortti: InjectionKey<Ref<HuonekorttiResponse | undefined>> = Symbol()
export const provideHuonekortit: InjectionKey<Ref<HuonekorttiResponse[]>> = Symbol()
export const provideHanke: InjectionKey<Ref<HankeResponse | undefined>> = Symbol()
export const provideSelectedVastuuyksikot: InjectionKey<Ref<VastuuyksikkoResponse[]>> = Symbol()
export const provideHankeVastuuyksikot: InjectionKey<Ref<VastuuyksikkoResponse[]>> = Symbol()
export const provideHasWritingRights: InjectionKey<Ref<boolean>> = Symbol()
export const provideHasPaakayttajaRights: InjectionKey<Ref<boolean>> = Symbol()
export const provideTilanimikkeet: InjectionKey<Ref<TilanimikeResponse>> = Symbol()
export const provideTilatString: InjectionKey<Ref<string>> = Symbol()
export const provideWritableVastuuyksikot: InjectionKey<Ref<VastuuyksikkoResponse[]>> = Symbol()
export const provideHuonekorttiIsEdited: InjectionKey<Ref<HuonekorttiEdited>> = Symbol()
export const provideHankeHuonekorttiListingParams: InjectionKey<Ref<HankeHuonekorttiListingQueryParams | undefined>> = Symbol()
export const provideHankeTilaListingParams: InjectionKey<Ref<HankeTilaListingQueryParams | undefined>> = Symbol()