import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComAttatchmenButton = _resolveComponent("ComAttatchmenButton");
  const _component_ComLiiteList = _resolveComponent("ComLiiteList");
  const _component_el_drawer = _resolveComponent("el-drawer");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_ComAttatchmenButton, {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.show = true)
  }), _ctx.show ? (_openBlock(), _createBlock(_component_el_drawer, {
    key: 0,
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.show = $event),
    "append-to-body": "",
    "destroy-on-close": "",
    title: "Liitteet",
    size: "50%"
  }, {
    default: _withCtx(() => [_createVNode(_component_ComLiiteList, {
      tyyppi: _ctx.tyyppi,
      viite: _ctx.viite,
      onLiiteChange: _ctx.liiteChangeClick
    }, null, 8, ["tyyppi", "viite", "onLiiteChange"])]),
    _: 1
  }, 8, ["modelValue"])) : _createCommentVNode("", true)]);
}