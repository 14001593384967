import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");
  const _component_el_descriptions = _resolveComponent("el-descriptions");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_el_space = _resolveComponent("el-space");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_ComCancelButton = _resolveComponent("ComCancelButton");
  const _component_el_form = _resolveComponent("el-form");
  return !_ctx.edit ? (_openBlock(), _createBlock(_component_el_space, {
    key: 0,
    direction: "vertical",
    alignment: "normal"
  }, {
    default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_el_descriptions, {
      direction: "horizontal",
      column: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_el_descriptions_item, {
        label: "Hankkeen nimi"
      }, {
        default: _withCtx(() => {
          var _a;
          return [_createTextVNode(_toDisplayString((_a = _ctx.hanke) === null || _a === void 0 ? void 0 : _a.nimi), 1)];
        }),
        _: 1
      }), _createVNode(_component_el_descriptions_item, {
        label: "Hankenumero"
      }, {
        default: _withCtx(() => {
          var _a;
          return [_createTextVNode(_toDisplayString((_a = _ctx.hanke) === null || _a === void 0 ? void 0 : _a.hankenumero), 1)];
        }),
        _: 1
      }), _createVNode(_component_el_descriptions_item, {
        label: "Hankkeen työnumero"
      }, {
        default: _withCtx(() => {
          var _a;
          return [_createTextVNode(_toDisplayString((_a = _ctx.hanke) === null || _a === void 0 ? void 0 : _a.tyonumero), 1)];
        }),
        _: 1
      }), _ctx.huonekortti ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
        key: 0,
        label: "Vastuuyksikkö"
      }, {
        default: _withCtx(() => {
          var _a;
          return [_createTextVNode(_toDisplayString((_a = _ctx.vastuuyksikko) === null || _a === void 0 ? void 0 : _a.nimi), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_el_descriptions_item, {
        label: "Organisaatio"
      }, {
        default: _withCtx(() => {
          var _a, _b;
          return [_createTextVNode(_toDisplayString((_b = (_a = _ctx.hanke) === null || _a === void 0 ? void 0 : _a.sairaala) === null || _b === void 0 ? void 0 : _b.nimi), 1)];
        }),
        _: 1
      })]),
      _: 1
    })]), _ctx.editable ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_ComSuccessButton, {
      onClick: _cache[0] || (_cache[0] = $event => _ctx.edit = true)
    }, {
      default: _withCtx(() => [_createTextVNode("Muokkaa")]),
      _: 1
    })])) : _createCommentVNode("", true)]),
    _: 1
  })) : _ctx.edit && _ctx.hankeRequest ? (_openBlock(), _createBlock(_component_el_form, {
    key: 1,
    modelValue: _ctx.hankeRequest,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.hankeRequest = $event),
    "label-width": "150px",
    "label-position": "left"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Hankkeen nimi"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.hankeRequest.nimi,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.hankeRequest.nimi = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Hankenumero"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.hankeRequest.hankenumero,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.hankeRequest.hankenumero = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Hankkeen työnumero"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.hankeRequest.tyonumero,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.hankeRequest.tyonumero = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Organisaatio"
    }, {
      default: _withCtx(() => {
        var _a, _b;
        return [_createElementVNode("div", null, _toDisplayString((_b = (_a = _ctx.hanke) === null || _a === void 0 ? void 0 : _a.sairaala) === null || _b === void 0 ? void 0 : _b.nimi), 1)];
      }),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_space, null, {
        default: _withCtx(() => [_createVNode(_component_ComSuccessButton, {
          onClick: _ctx.tallennaHankeClick
        }, {
          default: _withCtx(() => [_createTextVNode("Tallenna")]),
          _: 1
        }, 8, ["onClick"]), _createVNode(_component_ComCancelButton, {
          onClick: _cache[4] || (_cache[4] = $event => _ctx.edit = false)
        }, {
          default: _withCtx(() => [_createTextVNode("Peruuta")]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])) : _createCommentVNode("", true);
}