import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "padding-left": "0px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComInfoButton = _resolveComponent("ComInfoButton");
  const _component_ComHuonekorttiNimikeHistoriaItem = _resolveComponent("ComHuonekorttiNimikeHistoriaItem");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_popover = _resolveComponent("el-popover");
  return _openBlock(), _createBlock(_component_el_popover, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => _ctx.visible = $event),
    placement: "left",
    width: "800px"
  }, {
    reference: _withCtx(() => [_createVNode(_component_ComInfoButton, {
      onClick: _cache[0] || (_cache[0] = $event => _ctx.visible = !_ctx.visible)
    })]),
    default: _withCtx(() => [_ctx.visible ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createVNode(_component_ComHuonekorttiNimikeHistoriaItem, {
      data: _ctx.historiaNimikkeet[_ctx.active]
    }, null, 8, ["data"]), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createElementVNode("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historiaNimikkeet, (historia, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: historia.id,
          style: {
            "display": "inline-block"
          }
        }, [_createVNode(_component_el_button, {
          type: _ctx.buttonColor(historia.tyyppi),
          onClick: $event => _ctx.active = index,
          text: ""
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(historia.aika), 1)]),
          _: 2
        }, 1032, ["type", "onClick"])]);
      }), 128))])]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, null, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = $event => _ctx.visible = false)
        }, {
          default: _withCtx(() => [_createTextVNode("Sulje")]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })], 64)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["visible"]);
}