import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComTooltip = _resolveComponent("ComTooltip");
  return _openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.logout && _ctx.logout(...args))
  }, [_createVNode(_component_ComTooltip, {
    content: "Kirjaudu ulos",
    placement: "bottom"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  })]);
}