import { HuonekorttiStatusResponse } from '@/api/models/huonekortti-status-response';
import ComFilterInput from '@/components/common/ComFilterInput.vue';
import ComPagination from '@/components/common/ComPagination.vue';
import { defineComponent, computed, watch } from 'vue';
import ComHuonekorttiTable from './ComHuonekorttiTable.vue';
import { useHuonekorttiListing } from '../huonekortit';
export default defineComponent({
  components: {
    ComHuonekorttiTable,
    ComFilterInput,
    ComPagination
  },
  emits: ["loading"],
  props: {
    status: {
      type: Array,
      default: () => {
        return [HuonekorttiStatusResponse.Julkaistu, HuonekorttiStatusResponse.Odottaa, HuonekorttiStatusResponse.Hyvaksytty];
      }
    },
    selection: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const {
      huonekortit,
      loading,
      filter,
      totalItems,
      status,
      paging
    } = useHuonekorttiListing();
    watch(loading, () => {
      emit("loading", loading.value);
    });
    status.value = computed(() => props.status).value;
    return {
      huonekortit,
      loading,
      filter,
      totalItems,
      paging
    };
  }
});