import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import ComEditor from '@/components/common/ComEditor.vue';
import ComEmpty from '@/components/common/ComEmpty.vue';
import { provideSelectedVastuuyksikot } from '@/types/Symbols';
import sanitize from 'sanitize-html';
import { defineComponent, inject, ref, watch } from 'vue';
export default defineComponent({
  props: {
    editable: {
      type: Boolean,
      required: true
    },
    vaatimus: {
      type: String,
      required: false,
      default: ""
    }
  },
  emits: ["saveVaatimus"],
  setup(props, {
    emit
  }) {
    const edit = ref(false);
    const vaatimusEditable = ref(props.vaatimus);
    const selectedVastuuyksikot = inject(provideSelectedVastuuyksikot);
    const editVaatimusClick = () => {
      edit.value = true;
    };
    const sanitizeVaatimus = vaatimus => {
      return sanitize(vaatimus);
    };
    const saveVaatimusClick = () => {
      emit("saveVaatimus", vaatimusEditable.value);
      edit.value = false;
    };
    watch(() => selectedVastuuyksikot, () => {
      edit.value = false;
    });
    watch(() => props.vaatimus, () => {
      vaatimusEditable.value = props.vaatimus;
    });
    return {
      edit,
      vaatimusEditable,
      saveVaatimusClick,
      editVaatimusClick,
      sanitizeVaatimus
    };
  },
  components: {
    ComEditor,
    ComSuccessButton,
    ComCancelButton,
    ComEmpty
  }
});