import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "float-right-flex"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_DocumentCopy = _resolveComponent("DocumentCopy");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_tree = _resolveComponent("el-tree");
  const _component_ComCardExpand = _resolveComponent("ComCardExpand");
  const _component_ComLoading = _resolveComponent("ComLoading");
  const _component_ComCard = _resolveComponent("ComCard");
  return _ctx.data ? (_openBlock(), _createBlock(_component_ComCardExpand, {
    key: 0,
    title: "Hankkeet",
    show: true,
    class: "etusivu-hankkeet"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_checkbox, {
      label: "Arkistoidut",
      modelValue: _ctx.arkistoidut,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.arkistoidut = $event),
      border: ""
    }, null, 8, ["modelValue"])]), _createVNode(_component_el_tree, {
      data: _ctx.data,
      props: _ctx.props
    }, {
      default: _withCtx(({
        data
      }) => [_createElementVNode("div", {
        onClick: $event => _ctx.hankeLink(data.id),
        class: _normalizeClass({
          'arkistoitu-text': data.arkistoitu
        })
      }, [_createVNode(_component_el_icon, {
        class: "hanke-icon"
      }, {
        default: _withCtx(() => [_createVNode(_component_DocumentCopy)]),
        _: 1
      }), _createElementVNode("span", null, _toDisplayString(data.nimi), 1)], 10, _hoisted_2)]),
      _: 1
    }, 8, ["data", "props"])]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_ComCard, {
    key: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_ComLoading)]),
    _: 1
  }));
}