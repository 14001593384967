import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");
  const _component_ComStatusTag = _resolveComponent("ComStatusTag");
  const _component_el_descriptions = _resolveComponent("el-descriptions");
  return _ctx.huonekortti ? (_openBlock(), _createBlock(_component_el_descriptions, {
    key: 0,
    direction: "horizontal",
    column: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_el_descriptions_item, {
      label: "Tilanimike"
    }, {
      default: _withCtx(() => {
        var _a;
        return [_createTextVNode(_toDisplayString((_a = _ctx.huonekortti.tilanimike) === null || _a === void 0 ? void 0 : _a.nimi), 1)];
      }),
      _: 1
    }), _createVNode(_component_el_descriptions_item, {
      label: "Tilanimikkeen täsmennys"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.huonekortti.toiminta), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, {
      label: "Status"
    }, {
      default: _withCtx(() => [_createVNode(_component_ComStatusTag, {
        status: _ctx.huonekortti.status
      }, null, 8, ["status"])]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, {
      label: "Koskee tiloja"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.tilat), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, {
      label: "Luotu"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.huonekortti.lisaysAika), 1)]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true);
}