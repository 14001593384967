import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_ComSelect = _resolveComponent("ComSelect");
  return _openBlock(), _createBlock(_component_ComSelect, {
    modelValue: _ctx.vastuuyksikko,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.vastuuyksikko = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vastuuyksikot, vastuuyksikko => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: vastuuyksikko.id,
        label: vastuuyksikko.nimi,
        value: vastuuyksikko.id
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]);
}