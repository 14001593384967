import { UploadFilled } from '@element-plus/icons-vue';
import { computed, defineComponent } from 'vue';
export default defineComponent({
  emits: ["fileRemoveClicked", "update:modelValue"],
  components: {
    UploadFilled
  },
  props: {
    modelValue: {
      type: Object
    }
  },
  setup(props, {
    emit
  }) {
    const file = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      }
    });
    return {
      file
    };
  }
});