import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComLoading = _resolveComponent("ComLoading");
  const _component_ComHuonekorttiTableWithFilterAndPagination = _resolveComponent("ComHuonekorttiTableWithFilterAndPagination");
  return _ctx.loading ? (_openBlock(), _createBlock(_component_ComLoading, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_ComHuonekorttiTableWithFilterAndPagination, {
    selection: true,
    onLoading: _ctx.loadingChanged
  }, null, 8, ["onLoading"])]));
}