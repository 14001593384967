import http from "@/http-common";
import { errorHandler }  from "./baseApi";
import { TilaResponseExtended } from "@/api/models/tila-response-extended";
import { AxiosResponseHeaders } from "axios";

class TilaApi {
    getTilat(url : string) : Promise<{ data: TilaResponseExtended[], headers: AxiosResponseHeaders} | void> {
        return http.get(url).then(response => {
            return { 
                data: response.data,
                headers: response.headers as AxiosResponseHeaders
            }
        }).catch(error => { 
            errorHandler(error) 
        })
    } 
}

export default new TilaApi