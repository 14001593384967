import { provideHuonekortti } from '@/types/Symbols';
import { computed, defineComponent, inject, ref } from 'vue';
import ComHuonekorttiHistoriaItem from './ComHuonekorttiHistoriaItem.vue';
import ComEmpty from '@/components/common/ComEmpty.vue';
export default defineComponent({
  components: {
    ComHuonekorttiHistoriaItem,
    ComEmpty
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const huonekortti = inject(provideHuonekortti);
    const tyhjat = ref(0);
    const show = computed(() => {
      return historia.value && historia.value.length > 0;
    });
    const historia = computed(() => {
      const temp = props.data;
      return temp.sort((a, b) => a.id - b.id).reverse();
    });
    const currentData = id => {
      let temp = undefined;
      const i = historia.value.findIndex(h => h.id == id);
      temp = historia.value[i - 1];
      return temp !== null && temp !== void 0 ? temp : huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value;
    };
    return {
      huonekortti,
      historia,
      tyhjat,
      show,
      currentData
    };
  }
});