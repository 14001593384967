import { HuonekorttiHistoriaTyyppi } from '@/api/models/huonekortti-historia-tyyppi';
import ComEmpty from '@/components/common/ComEmpty.vue';
import ComFilterInput from '@/components/common/ComFilterInput.vue';
import huonekorttiHistoriaService from '@/methods/huonekorttiHistoriaService';
import { provideHuonekortti } from '@/types/Symbols';
import { computed, defineComponent, inject, ref } from 'vue';
import ComHuonekorttiNimikeHistoriaItem from './ComHuonekorttiNimikeHistoriaItem.vue';
export default defineComponent({
  components: {
    ComEmpty,
    ComHuonekorttiNimikeHistoriaItem,
    ComFilterInput
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const filter = ref("");
    const huonekortti = inject(provideHuonekortti);
    const historia = computed(() => {
      return props.data;
    });
    const filteredData = computed(() => {
      if (!filter.value) {
        return historia.value;
      }
      if (historia.value) {
        return historia.value.filter(n => {
          return n.nimike.nimi.toLowerCase().indexOf(filter.value.toLowerCase()) > -1 || n.tasmennys.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
        });
      } else {
        return [];
      }
    });
    const currentData = muutos => {
      var _a, _b;
      if (muutos.tyyppi == HuonekorttiHistoriaTyyppi.Muutos) {
        const temp = historia.value.filter(n => {
          return n.huonekorttiNimikeId == muutos.huonekorttiNimikeId;
        });
        const i = temp.findIndex(h => h.id == muutos.id);
        if (temp[i - 1]) {
          return huonekorttiHistoriaService.methods.diffHistoriaNimike(muutos, temp[i - 1]);
        } else {
          const huonekorttiNimike = (_b = (_a = huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value) === null || _a === void 0 ? void 0 : _a.nimikkeet) === null || _b === void 0 ? void 0 : _b.find(n => n.id == muutos.huonekorttiNimikeId);
          if (huonekorttiNimike) {
            return huonekorttiHistoriaService.methods.diffHistoriaNimike(muutos, huonekorttiNimike);
          }
        }
      } else {
        return huonekorttiHistoriaService.methods.diffHistoriaNimike(muutos);
      }
    };
    const type = type => {
      return huonekorttiHistoriaService.methods.tyyppiColor(type);
    };
    return {
      filter,
      filteredData,
      historia,
      type,
      currentData
    };
  }
});