import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createBlock(_component_el_pagination, {
    layout: "total, prev, pager, next, sizes",
    total: _ctx.total,
    "page-size": _ctx.page.pageSize,
    "onUpdate:pageSize": _cache[0] || (_cache[0] = $event => _ctx.page.pageSize = $event),
    "current-page": _ctx.page.currentPage,
    "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => _ctx.page.currentPage = $event),
    "page-sizes": _ctx.pageSizes,
    onSizeChange: _cache[2] || (_cache[2] = $event => _ctx.emit('page-change', _ctx.page)),
    onCurrentChange: _cache[3] || (_cache[3] = $event => _ctx.emit('page-change', _ctx.page)),
    small: ""
  }, null, 8, ["total", "page-size", "current-page", "page-sizes"]);
}