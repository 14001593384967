import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComLoading = _resolveComponent("ComLoading");
  const _component_Document = _resolveComponent("Document");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_space = _resolveComponent("el-space");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_ComDownloadButton = _resolveComponent("ComDownloadButton");
  const _component_ComDeleteButton = _resolveComponent("ComDeleteButton");
  const _component_el_table = _resolveComponent("el-table");
  const _component_ComLiiteAdd = _resolveComponent("ComLiiteAdd");
  return !_ctx.liitteet ? (_openBlock(), _createBlock(_component_ComLoading, {
    key: 0
  })) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createVNode(_component_el_table, {
    data: _ctx.liitteet,
    style: {
      "padding-bottom": "40px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      label: "Nimi"
    }, {
      default: _withCtx(scope => [_createVNode(_component_el_space, null, {
        default: _withCtx(() => [_createElementVNode("span", null, [_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [_createVNode(_component_Document)]),
          _: 1
        })]), _createElementVNode("span", null, _toDisplayString(scope.row.nimi ? scope.row.nimi : scope.row.tiedosto), 1)]),
        _: 2
      }, 1024)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      label: "Seloste",
      prop: "seloste"
    }), _createVNode(_component_el_table_column, {
      width: "85px"
    }, {
      default: _withCtx(scope => [_createVNode(_component_el_space, {
        style: {
          "float": "right"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_ComDownloadButton, {
          onClick: $event => _ctx.lataaLiiteClick(scope.row.id)
        }, null, 8, ["onClick"]), _ctx.writingRights ? (_openBlock(), _createBlock(_component_ComDeleteButton, {
          key: 0,
          onClick: $event => _ctx.deleteLiiteClick(scope.row)
        }, null, 8, ["onClick"])) : _createCommentVNode("", true)]),
        _: 2
      }, 1024)]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"]), _ctx.writingRights ? (_openBlock(), _createBlock(_component_ComLiiteAdd, {
    key: 0,
    tyyppi: _ctx.tyyppi,
    viite: _ctx.viite,
    onLiiteChange: _ctx.liiteChangeClick
  }, null, 8, ["tyyppi", "viite", "onLiiteChange"])) : _createCommentVNode("", true)], 64));
}