import { defineComponent } from 'vue';
import ComHanketiedotHanke from './ComHanketiedotHanke.vue';
import ComHanketiedotHuonekortti from './ComHanketiedotHuonekortti.vue';
import ComHanketiedotVaatimukset from './ComHanketiedotVaatimukset.vue';
export default defineComponent({
  components: {
    ComHanketiedotHanke,
    ComHanketiedotHuonekortti,
    ComHanketiedotVaatimukset
  },
  props: {
    editable: {
      type: Boolean,
      required: true
    },
    huonekortti: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {};
  }
});