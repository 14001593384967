import { defineComponent, ref, watch } from 'vue';
import { CaretRight, CaretBottom } from '@element-plus/icons-vue';
import ComSideMenuHuonekortit from './ComSideMenuHuonekortit.vue';
export default defineComponent({
  components: {
    CaretRight,
    CaretBottom,
    ComSideMenuHuonekortit
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    checkbox: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const vastuuyksikko = ref(false);
    const opened = ref(false);
    const pages = ref(1);
    const total = ref(null);
    const perPage = ref(30);
    const filterInput = ref("");
    const filter = ref("");
    const awaitFilter = ref(false);
    const scrollbarRef = ref();
    watch(filterInput, n => {
      var _a;
      pages.value = 1;
      (_a = scrollbarRef.value) === null || _a === void 0 ? void 0 : _a.setScrollTop(0);
      if (!awaitFilter.value) {
        setTimeout(() => {
          filter.value = filterInput.value;
          awaitFilter.value = false;
        }, 1000);
      }
      awaitFilter.value = true;
    });
    const loadHuonekortit = () => {
      vastuuyksikko.value = true;
      opened.value = !opened.value;
    };
    const scroll = ({
      scrollTop
    }) => {
      let p = 0;
      if (total.value) {
        p = Math.ceil(total.value / perPage.value);
      }
      if (scrollTop > 220 * pages.value && pages.value < p) {
        pages.value++;
      }
    };
    const setTotalCount = (val, val2) => {
      total.value = val;
      perPage.value = val2;
    };
    return {
      vastuuyksikko,
      opened,
      pages,
      filter,
      filterInput,
      scrollbarRef,
      scroll,
      loadHuonekortit,
      setTotalCount
    };
  }
});