import liiteService from '@/methods/liiteService';
import { Document } from '@element-plus/icons-vue';
import { ElMessageBox } from 'element-plus';
import { defineComponent, provide, inject } from 'vue';
import ComDeleteButton from '../common/buttons/ComDeleteButton.vue';
import ComDownloadButton from '../common/buttons/ComDownloadButton.vue';
import ComLoading from '../common/ComLoading.vue';
import ComLiiteAdd from './ComLiiteAdd.vue';
export default defineComponent({
  props: {
    viite: {
      type: Number,
      required: true
    },
    tyyppi: {
      type: Number,
      required: true
    }
  },
  components: {
    ComDownloadButton,
    ComLiiteAdd,
    Document,
    ComDeleteButton,
    ComLoading
  },
  emits: ["liiteChange"],
  setup(props, {
    emit
  }) {
    const {
      data: liitteet
    } = liiteService.methods.getLiitteet(props.viite, props.tyyppi);
    const getLiite = liiteService.methods.getLiite;
    const deleteLiite = liiteService.methods.deleteLiite;
    const writingRights = inject("writingRights");
    const lataaLiiteClick = id => {
      getLiite(id);
    };
    const deleteLiiteClick = async liite => {
      ElMessageBox.confirm("Haluatko varmasti poistaa liitteen " + liite.tiedosto).then(async () => {
        const response = await deleteLiite(liite.id);
        if (response && liitteet.value) {
          const index = liitteet.value.findIndex(l => l.id == liite.id);
          liitteet.value.splice(index, 1);
          liiteChangeClick();
        }
      });
    };
    const liiteChangeClick = () => {
      emit("liiteChange", liitteet.value);
    };
    provide("liitteet", liitteet);
    return {
      liitteet,
      writingRights,
      lataaLiiteClick,
      deleteLiiteClick,
      liiteChangeClick
    };
  }
});