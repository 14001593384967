import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createBlock(_component_el_tooltip, {
    content: _ctx.content,
    "show-after": 500,
    placement: _ctx.placement
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["content", "placement"]);
}