import ComLoading from '@/components/common/ComLoading.vue';
import ComHuonekorttiTableWithFilterAndPagination from '@/components/hanke/huonekortit/table/ComHuonekorttiTableWithFilterAndPagination.vue';
import nimikeKategoriaService from '@/methods/nimikeKategoriaService';
import { defineComponent, ref, watch, watchEffect } from 'vue';
export default defineComponent({
  components: {
    ComHuonekorttiTableWithFilterAndPagination,
    ComLoading
  },
  emits: ["huonekorteittain", "nimikkeet"],
  setup(prop, {
    emit
  }) {
    const getNimikeKategoriat = nimikeKategoriaService.methods.getNimikeKategoriat;
    const setNodes = nimikeKategoriaService.methods.setNodes;
    const {
      data: kategoriaNimikeData
    } = getNimikeKategoriat(true);
    const nimikkeet = ref();
    const valitutNimikkeet = ref();
    const huonekorteittain = ref(false);
    const props = {
      disabled: false,
      multiple: true
    };
    watch(valitutNimikkeet, () => {
      var _a;
      let n = [];
      if (valitutNimikkeet.value) {
        n = (_a = valitutNimikkeet.value) === null || _a === void 0 ? void 0 : _a.map(v => {
          return v[2];
        });
      }
      emit("nimikkeet", n);
    });
    watch(huonekorteittain, () => {
      emit("huonekorteittain", huonekorteittain.value);
    });
    watchEffect(() => {
      var _a;
      nimikkeet.value = setNodes((_a = kategoriaNimikeData.value) !== null && _a !== void 0 ? _a : [], true);
    });
    return {
      nimikkeet,
      valitutNimikkeet,
      props,
      huonekorteittain
    };
  }
});