import { defineComponent } from 'vue';
import ComHanketiedotVaatimuksetField from './ComHanketiedotVaatimuksetField.vue';
import vastuuyksikkoService from '@/methods/vastuuyksikkoService';
import { ElMessage } from 'element-plus';
export default defineComponent({
  components: {
    ComHanketiedotVaatimuksetField
  },
  props: {
    vastuuyksikko: {
      type: Object,
      required: true
    },
    hankeId: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  emits: ["save"],
  setup(props, {
    emit
  }) {
    const updateVastuuyksikko = vastuuyksikkoService.methods.update;
    const mapResponseToRequest = vastuuyksikkoService.methods.mapResponseToRequest;
    const saveVaatimusClick = async val => {
      const request = mapResponseToRequest(props.vastuuyksikko, props.hankeId);
      request.vaatimukset = val;
      const response = await updateVastuuyksikko(props.vastuuyksikko.id, request);
      if (response) {
        ElMessage.success("Vaatimukset tallennettu!");
        emit("save", response);
      }
    };
    return {
      saveVaatimusClick
    };
  }
});