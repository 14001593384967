import raportointiApi from "@/apis/raportointiApi"
import { reactive } from "vue"

const openWindow = (val : Blob, type : number) => {
    let blob = new Blob()

    if(type == 1) {
        blob = new Blob([val as BlobPart], { type: 'application/pdf' })
    }
    else if (type == 2) {
        blob = new Blob([val as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    }

    const objectUrl = window.URL.createObjectURL(blob)
    window.open(objectUrl, "_blank")
}

const state = reactive({
    loading: false
 })

const methods = {
    loadHuonekorttiRaportti(tyyppi : number, huonekortit : number[]) {
        if(huonekortit.length > 0 && (tyyppi == 1 || tyyppi == 2)) {
            state.loading = true

            return raportointiApi.getHuonekorttiRaportti(tyyppi, huonekortit).then((response) => {
                openWindow(response, tyyppi) 

                state.loading = false
            }).catch((error) => {
                console.log(error)
            
                state.loading = false
            })
        }
    },
    loadHistoriaRaportti(tyyppi : number, huonekortit : number[], alku : string, loppu : string) {
        if(huonekortit.length > 0 && alku && loppu && (tyyppi == 1 || tyyppi == 2)) {
            state.loading = true

            return raportointiApi.getHistoriaRaportti(tyyppi, huonekortit,alku,loppu).then((response) => {
                openWindow(response, tyyppi)   

                state.loading = false
            }).catch((error) => {
                console.log(error)
            
                state.loading = false
            })                
        }
    },
    loadTilaRaportti(tyyppi : number, vastuuyksikot? : number[], huonekortit? : number[]) {
        if((vastuuyksikot || huonekortit)) {
            state.loading = true

            return raportointiApi.getTilaRaportti(tyyppi, vastuuyksikot, huonekortit).then((response) => {
                openWindow(response, tyyppi)   

                state.loading = false
            }).catch((error) => {
                console.log(error)
            
                state.loading = false
            })
        }
    },
    loadNimikeRaportti(nimikkeet : number[], vastuuyksikot? : number[], huonekortit? : number[]) {
        if((vastuuyksikot || huonekortit)) {
            state.loading = true

            return raportointiApi.getNimikeRaportti(nimikkeet, vastuuyksikot, huonekortit).then((response) => {
                openWindow(response, 2)   

                state.loading = false
            }).catch((error) => {
                console.log(error)
            
                state.loading = false
            })
        }
    }
}

export default {
    methods,
    state
}