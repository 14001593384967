import { defineComponent } from 'vue';
import ComInfoButton from '../common/buttons/ComInfoButton.vue';
export default defineComponent({
  components: {
    ComInfoButton
  },
  props: {
    title: {
      type: String,
      required: false
    }
  },
  setup() {
    return {};
  }
});