
import { HankeRequest } from "@/api/models/hanke-request";
import { HankeResponse } from "@/api/models/hanke-response";
import { HankeVaatimusRequest } from "@/api/models/hanke-vaatimus-request";
import { HankeVaatimusRequestPut } from "@/api/models/hanke-vaatimus-request-put";
import adminApi from "@/apis/adminApi";
import hankeApi from "@/apis/hankeApi";
import { AxiosResponseHeaders } from "axios";
import { ElMessage } from "element-plus";
import useSWRV from "swrv";
import { reactive, readonly } from "vue";

interface tilanumeroObject {
    [key: string]: number
}

const state = reactive({
    tilanumero : {} as tilanumeroObject
})

const url = "hankkeet"
const hankeVastuuyksikkoUrl = url + "/vastuuyksikkoid"

const options = {
    dedupingInterval: 900000,
    revalidateOnFocus: true,
}

const methods = {
    getHankeById : {
        key(id : number) {
            if(id) {
                return url  + "/" + id
            }
            else {
                return undefined
            }
        },
        fetcher: (key : any) => hankeApi.getHankeByUrl(key),
        options: options
    },
    getHankeByVastuuyksikkoId : {
        key(id : number) {
            return hankeVastuuyksikkoUrl + "/" + id
        },
        fetcher: (key : any) => hankeApi.getHankeByVastuuyksikkoId(key),
        options: options
    },
    async updateHanke (id : number, request : HankeRequest) {
        const response = await adminApi.updateHanke(id, request)

        if(response) {
            return response
        }
        else {
            ElMessage.error("Hanketietojen päivittäminen ei onnistunut!")
        }
    },
    getHankeVaatimuskategoriat() {
        const key = "hankkeet/kategoriat"
        const fetcher = () => {
            return hankeApi.getHankeVaatimuskategoriat()
        }
        const { data, error } = useSWRV(key, fetcher)

        if(error.value) {
            ElMessage.error("Vaatimuskategorioiden lataaminen ei onnistunut!")
        }
        
        return { data, error }
    },
    async addHankeVaatimus(request : HankeVaatimusRequest) {
        const response = await hankeApi.addHankeVaatimus(request)

        if(response) {
            return response
        }
        else {
            ElMessage.error("Vaatimuksen lisäys ei onnistunut!")
        }
    },
    async updateHankeVaatimus(id : number, request : HankeVaatimusRequestPut) {
        const response = await hankeApi.updateHankeVaatimus(id, request)

        if(response) {
            return response
        }
        else {
            ElMessage.error("Vaatimuksen päivitys ei onnistunut!")
        }
    },
    async addHanke(request : HankeRequest) {
        const response = await adminApi.addHanke(request)

        if(response) {
            return response
        }
        else {
            ElMessage.error("Hankkeen lisäys ei onnistunut!")
        }
    },
    setTilanumero(headers : AxiosResponseHeaders, id : number) {
        if(headers["hanke-tilanumero"]) {
            state.tilanumero[id] = parseInt(headers["hanke-tilanumero"])
        }
        else {
            state.tilanumero[id] = 0
        }
    },
    increaseTilanumero(id : number) {
        if(state.tilanumero[id]) {
            state.tilanumero[id] = state.tilanumero[id] + 1
        }
    },
    mapResponseToRequest(response : HankeResponse) {
        const request : HankeRequest = {
            nimi: response.nimi,
            hankenumero: response.hankenumero,
            tyonumero: response.tyonumero,
            vaatimukset: response.vaatimukset,
            sairaala: {
                id: response.id
            },
            vastuuyksikot: response.vastuuyksikot ?? []
        }

        return request
    }
}

const getters = {
    getTilanumero(id : number) {
        if(!state.tilanumero[id]) {
            return undefined
        }

        return state.tilanumero[id]
    }
}

export default { 
    state: readonly(state),
    methods,
    getters
}