import { AxiosError } from "axios";
import router from '@/router'

const errorHandler = (error : AxiosError) : void => {
    if (error.response) {
        // Request made and server responded
        if(error.response.status == 404) {
          router.push("pagenotfound")
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        if(error.code === "login_required") {
          router.push({name: "Kirjaudu"})
        }
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
}

export { errorHandler }