import { defineComponent, inject, ref } from 'vue';
import ComSelect from '@/components/common/ComSelect.vue';
import ComDeleteButton from '@/components/common/buttons/ComDeleteButton.vue';
import adminService from '@/methods/adminService';
import { ElMessage, ElMessageBox } from 'element-plus';
export default defineComponent({
  components: {
    ComSelect,
    ComDeleteButton
  },
  setup() {
    const paakayttajat = inject("paakayttajat");
    const selected = ref();
    const deletePaakayttaja = adminService.methods.deletePaakayttaja;
    const deletePaakayttajaClick = () => {
      if (selected.value) {
        ElMessageBox.confirm("Haluatko varmasti poistaa käyttäjältä pääkäyttäjäoikeudet?", "", {
          type: "warning"
        }).then(() => {
          deletePaakayttaja(selected.value).then(response => {
            if (response) {
              paakayttajat.value = paakayttajat.value.filter(p => p.id != selected.value);
              ElMessage.success("Pääkäytäjä poistettu!");
              selected.value = undefined;
            } else {
              ElMessage.error("Pääkäyttäjän poistaminen ei onnistunut!");
            }
          });
        });
      } else {
        ElMessage.warning("Valitse poistettava pääkäyttäjä!");
      }
    };
    return {
      paakayttajat,
      selected,
      deletePaakayttajaClick
    };
  }
});