import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_ComUploadFile = _resolveComponent("ComUploadFile");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    "label-width": "80px"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Nimi"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.liite.nimi,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.liite.nimi = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Seloste"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        type: "textarea",
        modelValue: _ctx.liite.seloste,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.liite.seloste = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Tiedosto"
    }, {
      default: _withCtx(() => [_createVNode(_component_ComUploadFile, {
        style: {
          "width": "100%"
        },
        drag: "",
        modelValue: _ctx.file,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.file = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_ComSuccessButton, {
        onClick: _ctx.addFile
      }, {
        default: _withCtx(() => [_createTextVNode("Lisää liite")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  });
}