import { HuonekorttiId } from "@/api/models/huonekortti-id";
import mallihuonekorttiApi from "@/apis/mallihuonekorttiApi";
import { ElMessage } from "element-plus";
import useSWRV from "swrv";
import { computed, ref, Ref } from "vue";
import { MallihuonekorttiRequestPut } from "../../api/models/mallihuonekortti-request-put";

export function useMallihuonekortit(sairaala : Ref<number | undefined>) {
    const tilanimike = ref<number | undefined>()
    const url = computed(() => {
        const base = `/sairaalat/${sairaala.value}/mallihuonekortit`
        const query = tilanimike.value  
            ? "?" + new URLSearchParams({tilanimike: tilanimike.value.toString()}) 
            : ""
        const response = base + query
        
        return response
    })

    const fetcher = (url : string) => {
        if(sairaala.value) {
            return mallihuonekorttiApi.getAll(url)
        }
    }

    const { data, error, isValidating, mutate } = useSWRV(url, fetcher)
    const loading = computed(() => isValidating && !data)

    const add = async(huonekortti : HuonekorttiId) => {
        if(!sairaala.value) {
            ElMessage.error("Sairaalaa ei ole valittu!")
            
            return
        }
            const response = await mallihuonekorttiApi.add(sairaala.value, huonekortti)

            if(response) {
                return response
            }
            else {
                ElMessage.error("Mallihuonekortin lisäys ei onnistunut!")
            }
    }

    const del = async(id : number) => {
        const response = await mallihuonekorttiApi.delete(id)

        if(response) {
            return response
        }
        else {
            ElMessage.error("Mallihuonekortin poistaminen ei onnistunut!")
        }
    }

    const update = async(id : number, request : MallihuonekorttiRequestPut) => {
        const response = await mallihuonekorttiApi.update(id, request)

        if(response) {
            return response
        }
        else {
            ElMessage.error("Mallihuonekortin päivitys ei onnistunut!")
        }
    }

    return {
        data,
        error,
        isValidating,
        loading,
        sairaala,
        tilanimike,
        mutate,
        add,
        del,
        update
    }
}