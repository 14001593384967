import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-63cab9d0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1
};
const _hoisted_2 = {
  class: "float-right-flex",
  style: {
    "padding-bottom": "20px"
  }
};
const _hoisted_3 = {
  class: "label"
};
const _hoisted_4 = {
  colspan: "5"
};
const _hoisted_5 = {
  style: {
    "width": "15%"
  }
};
const _hoisted_6 = {
  style: {
    "width": "5%"
  }
};
const _hoisted_7 = ["innerHTML"];
const _hoisted_8 = {
  style: {
    "width": "35%"
  }
};
const _hoisted_9 = ["innerHTML"];
const _hoisted_10 = {
  style: {
    "width": "35%"
  }
};
const _hoisted_11 = ["innerHTML"];
const _hoisted_12 = {
  style: {
    "padding-right": "20px"
  }
};
const _hoisted_13 = {
  key: 0,
  style: {
    "width": "100px"
  }
};
const _hoisted_14 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComLisaaNimike = _resolveComponent("ComLisaaNimike");
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_space = _resolveComponent("el-space");
  const _component_Folder = _resolveComponent("Folder");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_FolderOpened = _resolveComponent("FolderOpened");
  const _component_el_input_number = _resolveComponent("el-input-number");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_ComSaveButton = _resolveComponent("ComSaveButton");
  const _component_ComCancelIconButton = _resolveComponent("ComCancelIconButton");
  const _component_ComEditButton = _resolveComponent("ComEditButton");
  const _component_ComDeleteButton = _resolveComponent("ComDeleteButton");
  const _component_Warning = _resolveComponent("Warning");
  const _component_ComTooltip = _resolveComponent("ComTooltip");
  const _component_ComNimikeHistoriaPopover = _resolveComponent("ComNimikeHistoriaPopover");
  const _component_el_collapse_item = _resolveComponent("el-collapse-item");
  const _component_el_collapse = _resolveComponent("el-collapse");
  const _component_ComEmpty = _resolveComponent("ComEmpty");
  const _component_ComCardExpand = _resolveComponent("ComCardExpand");
  return _openBlock(), _createBlock(_component_ComCardExpand, {
    title: "Suunnittelutiedot",
    way: _ctx.o,
    show: true
  }, {
    default: _withCtx(() => {
      var _a;
      return [_ctx.writingRights ? (_openBlock(), _createBlock(_component_ComLisaaNimike, {
        key: 0,
        nimikkeet: _ctx.kategoriat
      }, null, 8, ["nimikkeet"])) : _createCommentVNode("", true), ((_a = _ctx.nimikkeet) === null || _a === void 0 ? void 0 : _a.length) != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_space, null, {
        default: _withCtx(() => [_ctx.hyvaksytty ? (_openBlock(), _createBlock(_component_el_checkbox, {
          key: 0,
          label: "Muutokset",
          modelValue: _ctx.muutoksetActive,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.muutoksetActive = $event),
          border: ""
        }, null, 8, ["modelValue"])) : _createCommentVNode("", true), _createVNode(_component_el_button, {
          onClick: _ctx.togleAll
        }, {
          default: _withCtx(() => [_createTextVNode("Avaa/Sulje")]),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      })]), _createVNode(_component_el_collapse, {
        modelValue: _ctx.active,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.active = $event)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kategoriat, (kategoria, index1) => {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: index1
          }, [_ctx.hasPaakategoriaItems(kategoria.id) ? (_openBlock(), _createBlock(_component_el_collapse_item, {
            key: 0,
            class: "suunnittelu-collapse-item",
            name: kategoria.id
          }, {
            title: _withCtx(() => [!_ctx.active.includes(kategoria.id) ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              class: "collapse-icon-right"
            }, {
              default: _withCtx(() => [_createVNode(_component_Folder)]),
              _: 1
            })) : (_openBlock(), _createBlock(_component_el_icon, {
              key: 1,
              class: "collapse-icon-right"
            }, {
              default: _withCtx(() => [_createVNode(_component_FolderOpened)]),
              _: 1
            })), _createElementVNode("span", _hoisted_3, _toDisplayString(kategoria.nimi), 1)]),
            default: _withCtx(() => [_createElementVNode("table", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(kategoria.children, (aliKategoria, index2) => {
              return _openBlock(), _createElementBlock(_Fragment, {
                key: index2
              }, [_ctx.hasAlikategoriaItems(aliKategoria.id) ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_4, _toDisplayString(aliKategoria.nimi), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nimikkeetHistoria, (item, index3) => {
                var _a, _b, _c;
                return _openBlock(), _createElementBlock(_Fragment, {
                  key: index3
                }, [((_b = (_a = item.nimike) === null || _a === void 0 ? void 0 : _a.kategoria) === null || _b === void 0 ? void 0 : _b.id) === aliKategoria.id ? (_openBlock(), _createElementBlock("tr", {
                  key: 0,
                  class: _normalizeClass({
                    poisto: _ctx.isPoisto(item),
                    'lisays': _ctx.isLisays(item)
                  }),
                  style: {
                    "height": "40px"
                  }
                }, [_createElementVNode("td", _hoisted_5, _toDisplayString((_c = item.nimike) === null || _c === void 0 ? void 0 : _c.nimi), 1), _createElementVNode("td", _hoisted_6, [_ctx.edit === item.id ? (_openBlock(), _createBlock(_component_el_input_number, {
                  key: 0,
                  min: 0,
                  class: "number-input-mini",
                  size: "small",
                  modelValue: item.maara,
                  "onUpdate:modelValue": $event => item.maara = $event
                }, null, 8, ["modelValue", "onUpdate:modelValue"])) : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  innerHTML: item.maara
                }, null, 8, _hoisted_7))]), _createElementVNode("td", _hoisted_8, [_ctx.edit === item.id ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  modelValue: item.tasmennys,
                  "onUpdate:modelValue": $event => item.tasmennys = $event,
                  type: "textarea",
                  autosize: "",
                  style: {
                    "width": "100%"
                  }
                }, null, 8, ["modelValue", "onUpdate:modelValue"])) : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  innerHTML: item.tasmennys
                }, null, 8, _hoisted_9))]), _createElementVNode("td", _hoisted_10, [_ctx.edit === item.id ? (_openBlock(), _createBlock(_component_el_select, {
                  key: 0,
                  multiple: "",
                  modelValue: _ctx.selectedOminaisuudet,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.selectedOminaisuudet = $event),
                  placeholder: "Valitse ominaisuudet",
                  size: "small",
                  style: {
                    "width": "80%"
                  }
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ominaisuudet, (ominaisuus, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      label: ominaisuus.nimi,
                      value: ominaisuus.id
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])) : (_openBlock(true), _createElementBlock(_Fragment, {
                  key: 1
                }, _renderList(item.ominaisuudet, (ominaisuus, index) => {
                  return _openBlock(), _createBlock(_component_el_tag, {
                    type: "info",
                    size: "small",
                    style: {
                      "margin-right": "2px"
                    },
                    key: index
                  }, {
                    default: _withCtx(() => [_createElementVNode("span", {
                      innerHTML: ominaisuus.nimi
                    }, null, 8, _hoisted_11)]),
                    _: 2
                  }, 1024);
                }), 128))]), _createElementVNode("td", _hoisted_12, [!_ctx.muutoksetActive ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_ctx.edit === item.id ? (_openBlock(), _createElementBlock(_Fragment, {
                  key: 0
                }, [_createVNode(_component_ComSaveButton, {
                  onClick: $event => _ctx.saveNimike(item)
                }, null, 8, ["onClick"]), _createVNode(_component_ComCancelIconButton, {
                  onClick: $event => _ctx.cancelEdit(item)
                }, null, 8, ["onClick"])], 64)) : _ctx.writingRights ? (_openBlock(), _createElementBlock(_Fragment, {
                  key: 1
                }, [_createVNode(_component_ComEditButton, {
                  onClick: $event => _ctx.editNimike(item, aliKategoria)
                }, null, 8, ["onClick"]), _createVNode(_component_ComDeleteButton, {
                  onClick: $event => _ctx.deleteNimike(item, index3)
                }, null, 8, ["onClick"]), !item.nimike.kaytossa ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  text: "",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                  size: "small",
                  circle: ""
                }, {
                  default: _withCtx(() => [_createVNode(_component_ComTooltip, {
                    content: "Nimike on poistettu nimikkeistöstä. Korvaa nimike toisella."
                  }, {
                    default: _withCtx(() => [_createVNode(_component_el_icon, {
                      size: 20
                    }, {
                      default: _withCtx(() => [_createVNode(_component_Warning)]),
                      _: 1
                    })]),
                    _: 1
                  })]),
                  _: 1
                })) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)])) : _ctx.muutoksetActive && item.tyyppi ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_ComNimikeHistoriaPopover, {
                  id: item.huonekorttiNimikeId
                }, null, 8, ["id"])])) : _createCommentVNode("", true)])], 2)) : _createCommentVNode("", true)], 64);
              }), 128))], 64)) : _createCommentVNode("", true)], 64);
            }), 128))])]),
            _: 2
          }, 1032, ["name"])) : _createCommentVNode("", true)], 64);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])])) : (_openBlock(), _createBlock(_component_ComEmpty, {
        key: 2
      }))];
    }),
    _: 1
  }, 8, ["way"]);
}