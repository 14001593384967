import hankeService from '@/methods/hankeService';
import { ElMessage } from 'element-plus';
import { defineComponent } from 'vue';
import ComHanketiedotVaatimuksetField from './ComHanketiedotVaatimuksetField.vue';
export default defineComponent({
  components: {
    ComHanketiedotVaatimuksetField
  },
  props: {
    editable: {
      type: Boolean,
      required: true
    },
    hanke: {
      type: Object,
      required: true
    }
  },
  emits: ["save"],
  setup(props, {
    emit
  }) {
    const updateHanke = hankeService.methods.updateHanke;
    const mapResponseToRequest = hankeService.methods.mapResponseToRequest;
    const saveVaatimusClick = async val => {
      const request = mapResponseToRequest(props.hanke);
      request.vaatimukset = val;
      const response = await updateHanke(props.hanke.id, request);
      if (response) {
        ElMessage.success("Vaatimukset tallennettu!");
        emit("save", response);
      }
    };
    return {
      saveVaatimusClick
    };
  }
});