import { defineComponent, inject, ref, watchEffect, computed, watch } from 'vue';
import tilaService from '@/methods/tilaService';
import useSWRV from "swrv";
import ComPagination from '../../common/ComPagination.vue';
import ComFilterInput from '../../common/ComFilterInput.vue';
import ComLoading from '@/components/common/ComLoading.vue';
import { provideHankeTilaListingParams, provideSelectedVastuuyksikot } from '@/types/Symbols';
import TilaSortEnum from '@/types/TilaSortEnum';
import OrderEnum from '@/types/OrderEnum';
export default defineComponent({
  components: {
    ComPagination,
    ComFilterInput,
    ComLoading
  },
  setup() {
    const {
      methods,
      fetcher,
      state
    } = tilaService;
    const vastuuyksikotSelected = inject(provideSelectedVastuuyksikot);
    const vastuuyksikot = inject("vastuuyksikot");
    const hankeId = inject("hankeId");
    const queryParams = inject(provideHankeTilaListingParams);
    const pintaAlaTotal = ref(0);
    const pageTotal = ref(0);
    const selectedVastuuyksikkoIds = computed(() => vastuuyksikotSelected === null || vastuuyksikotSelected === void 0 ? void 0 : vastuuyksikotSelected.value.map(v => v.id));
    const filter = computed({
      get: () => {
        var _a, _b;
        return (_b = (_a = queryParams === null || queryParams === void 0 ? void 0 : queryParams.value) === null || _a === void 0 ? void 0 : _a.filter) !== null && _b !== void 0 ? _b : "";
      },
      set: val => {
        if (queryParams) {
          if (!queryParams.value) {
            queryParams.value = {};
          }
          queryParams.value.filter = val;
        }
      }
    });
    const paging = computed({
      get: () => {
        var _a, _b;
        return (_b = (_a = queryParams === null || queryParams === void 0 ? void 0 : queryParams.value) === null || _a === void 0 ? void 0 : _a.page) !== null && _b !== void 0 ? _b : {
          currentPage: 1,
          pageSize: 30
        };
      },
      set: val => {
        if (queryParams) {
          if (!queryParams.value) {
            queryParams.value = {};
          }
          methods.setPage(val);
          queryParams.value.page = val;
        }
      }
    });
    const sort = computed(() => {
      var _a, _b;
      return (_b = (_a = queryParams === null || queryParams === void 0 ? void 0 : queryParams.value) === null || _a === void 0 ? void 0 : _a.sort) !== null && _b !== void 0 ? _b : {
        prop: TilaSortEnum.id,
        order: OrderEnum.asc
      };
    });
    const key = computed(() => {
      if (vastuuyksikotSelected && vastuuyksikotSelected.value.length != 0 && !Number.isNaN(state.hankeId)) {
        const request = {
          vastuuyksikot: selectedVastuuyksikkoIds.value,
          page: paging.value.currentPage,
          perPage: paging.value.pageSize,
          sort: sort.value.prop,
          order: sort.value.order,
          tilanimike: filter.value,
          tilanimikeTasmennys: filter.value,
          tilatunnus: filter.value
        };
        return methods.formatKey(request);
      } else {
        return undefined;
      }
    });
    const {
      data
    } = useSWRV(() => (vastuuyksikot === null || vastuuyksikot === void 0 ? void 0 : vastuuyksikot.value.length) == 0 ? undefined : key.value, fetcher);
    const tilat = computed(() => {
      var _a;
      return (_a = data.value) === null || _a === void 0 ? void 0 : _a.data;
    });
    const loading = computed(() => {
      if ((vastuuyksikotSelected === null || vastuuyksikotSelected === void 0 ? void 0 : vastuuyksikotSelected.value.length) != 0 && data.value == undefined) {
        return true;
      } else {
        return false;
      }
    });
    const getSum = params => {
      const {
        columns,
        data
      } = params;
      const sums = [];
      const pageSum = data.flatMap(t => {
        var _a;
        return (_a = t.pintaAla) !== null && _a !== void 0 ? _a : 0;
      }).reduce((a, c) => a + c, 0);
      columns.forEach((v, i) => {
        if (i === 0) {
          sums[i] = `Pinta-ala:`;
        } else if (i === 1) {
          sums[i] = `Sivu:\n${pageSum.toFixed(2)} m2`;
        } else if (i === 2) {
          sums[i] = `Valinnat yhteensä:\n${pintaAlaTotal.value.toFixed(2)} m2`;
        } else {
          sums[i] = "";
        }
      });
      return sums;
    };
    const vastuuyksikko = id => {
      var _a;
      return (_a = vastuuyksikot.value.find(v => v.id === id)) === null || _a === void 0 ? void 0 : _a.nimi;
    };
    const sortChanged = val => {
      if (queryParams) {
        if (!(queryParams === null || queryParams === void 0 ? void 0 : queryParams.value)) {
          queryParams.value = {};
        }
        const sort = {};
        sort.prop = val.prop;
        sort.order = val.order;
        queryParams.value.sort = sort;
      }
      methods.sortChanged(val.prop, val.order);
    };
    watch(data, n => {
      if (n === null || n === void 0 ? void 0 : n.headers) {
        pageTotal.value = parseInt(n.headers['page-total-count']);
        pintaAlaTotal.value = Number(n.headers['tila-pintaala-total']);
      } else {
        pageTotal.value = 0;
      }
    });
    watch(filter, n => {
      paging.value.currentPage = 1;
      methods.setAllFilters(n);
    });
    watchEffect(() => {
      var _a;
      if ((vastuuyksikotSelected === null || vastuuyksikotSelected === void 0 ? void 0 : vastuuyksikotSelected.value.length) === 0) {
        data.value = undefined;
      }
      const page = state.page;
      methods.setHankeId(hankeId.value);
      methods.setVastuuyksikot((_a = selectedVastuuyksikkoIds.value) !== null && _a !== void 0 ? _a : []);
      methods.setPage(page);
    });
    return {
      methods,
      tilat,
      pageTotal,
      filter,
      loading,
      paging,
      sort,
      sortChanged,
      vastuuyksikko,
      getSum
    };
  }
});