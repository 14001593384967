import { HuonekorttiId } from "@/api/models/huonekortti-id";
import { MallihuonekorttiResponse } from "@/api/models/mallihuonekortti-response"
import http from "@/http-common";
import { errorHandler } from "./baseApi";
import { MallihuonekorttiRequestPut } from "@/api/models/mallihuonekortti-request-put";

class MallihuonekorttiApi {
    getAll(url : string) : Promise<MallihuonekorttiResponse[] | undefined> {
        return http.get(url)
            .then(response => response.data)
            .catch(error => errorHandler(error))
    }

    add(sairaala : number, huonekortti : HuonekorttiId) : Promise<MallihuonekorttiResponse | undefined> {
        return http.post(`/sairaalat/${sairaala}/mallihuonekortit`, huonekortti)
            .then(response => response.data)
            .catch(error => errorHandler(error))
    }

    delete(id : number) : Promise<boolean> {
        return http.delete(`/sairaalat/mallihuonekortit/${id}`)
            .then(() => true)
            .catch((error) => {
                errorHandler(error) 
                
                return false
            })
    }

    update(id : number, request : MallihuonekorttiRequestPut) : Promise<MallihuonekorttiResponse | undefined> {
        return http.put(`/sairaalat/mallihuonekortit/${id}`, request)
            .then(response => response.data)
            .catch(error => errorHandler(error))
    }
}

export default new MallihuonekorttiApi