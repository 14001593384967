import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "card-margin-bottom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_ComSelect = _resolveComponent("ComSelect");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_el_space = _resolveComponent("el-space");
  const _component_el_form = _resolveComponent("el-form");
  const _component_ComHuonekorttiRaportti = _resolveComponent("ComHuonekorttiRaportti");
  const _component_ComTilaRaportti = _resolveComponent("ComTilaRaportti");
  const _component_ComMuutoslogiRaportti = _resolveComponent("ComMuutoslogiRaportti");
  const _component_ComNimikeRaportti = _resolveComponent("ComNimikeRaportti");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_form, {
    inline: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Raportin tyyppi"
    }, {
      default: _withCtx(() => [_createVNode(_component_ComSelect, {
        modelValue: _ctx.valittu,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.valittu = $event)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tyyppi, (t, index) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: index,
            value: t,
            label: t
          }, null, 8, ["value", "label"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_space, null, {
        default: _withCtx(() => [_ctx.valittu != 'Nimike' ? _withDirectives((_openBlock(), _createBlock(_component_ComSuccessButton, {
          key: 0,
          onClick: _cache[1] || (_cache[1] = $event => _ctx.tulosta(1)),
          type: "success"
        }, {
          default: _withCtx(() => [_createTextVNode("Pdf")]),
          _: 1
        })), [[_directive_loading, _ctx.loadingRaportti, void 0, {
          fullscreen: true,
          lock: true
        }]]) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createBlock(_component_ComSuccessButton, {
          onClick: _cache[2] || (_cache[2] = $event => _ctx.tulosta(2)),
          type: "success"
        }, {
          default: _withCtx(() => [_createTextVNode("Excel")]),
          _: 1
        })), [[_directive_loading, _ctx.loadingRaportti, void 0, {
          fullscreen: true,
          lock: true
        }]])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]), _ctx.valittu == 'Huonekortti' ? (_openBlock(), _createBlock(_component_ComHuonekorttiRaportti, {
    key: 0
  })) : _createCommentVNode("", true), _ctx.valittu == 'Tila' ? (_openBlock(), _createBlock(_component_ComTilaRaportti, {
    key: 1,
    onHuonekorteittain: _ctx.huonekorteittainChange
  }, null, 8, ["onHuonekorteittain"])) : _createCommentVNode("", true), _ctx.valittu == 'Historia' ? (_openBlock(), _createBlock(_component_ComMuutoslogiRaportti, {
    key: 2,
    onRange: _ctx.rangeChange
  }, null, 8, ["onRange"])) : _createCommentVNode("", true), _ctx.valittu == 'Nimike' ? (_openBlock(), _createBlock(_component_ComNimikeRaportti, {
    key: 3,
    onHuonekorteittain: _ctx.huonekorteittainChange,
    onNimikkeet: _ctx.nimikkeetChange
  }, null, 8, ["onHuonekorteittain", "onNimikkeet"])) : _createCommentVNode("", true)], 64);
}