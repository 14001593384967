import http from "@/http-common";
import { errorHandler }  from "./baseApi";
import {AxiosPromise, AxiosResponseHeaders } from "axios";
import { HankeResponse } from "@/api/models/hanke-response";
import { HankeVaatimuskategoriaResponse } from "@/api/models/hanke-vaatimuskategoria-response";
import { HankeVaatimusRequest } from "@/api/models/hanke-vaatimus-request";
import { HankeVaatimusResponse } from "@/api/models/hanke-vaatimus-response";
import { HankeVaatimusRequestPut } from "@/api/models/hanke-vaatimus-request-put";

class HankeAPI {

    getHankeById(id : number) : Promise<{ data: HankeResponse; headers: AxiosResponseHeaders; } | void> {
        return http.get(`hankkeet/${id}`).then((response) => {
            return {
                data: response.data, 
                headers: response.headers as AxiosResponseHeaders
            }
        }).catch(error => {
            errorHandler(error)
        })
    }

    getHankeByUrl(url : string) : Promise<{ data: HankeResponse; headers: AxiosResponseHeaders; } | void> {
        return http.get(url).then((response) => {
            return {
                data: response.data, 
                headers: response.headers as AxiosResponseHeaders
            }
        }).catch(error => {
            errorHandler(error)
        })
    }

    getHankkeet(url : string) : Promise<HankeResponse[]> {
        return http.get(url).then((response) => { return response.data })
    }    

    getHankeByVastuuyksikkoId(url : string) : Promise<{ data: HankeResponse; headers: AxiosResponseHeaders; } | void> {
        return http.get(url).then((response) => {
            return {
                data: response.data, 
                headers: response.headers as AxiosResponseHeaders
            }
        }).catch(error => {
            errorHandler(error)
        })
    }
    
    getHankeVaatimuskategoriat() : Promise<HankeVaatimuskategoriaResponse[]> {
        return http.get("/hankkeet/vaatimukset/kategoriat").then((response) => {
            return response.data
        })
    }

    addHankeVaatimus(request : HankeVaatimusRequest) : Promise<HankeVaatimusResponse | undefined> {
        return http.post("/hankkeet/vaatimukset", request).then((response) => {
            return response.data
        }).catch(error => {
            errorHandler(error)
        })
    }

    updateHankeVaatimus(id : number, request : HankeVaatimusRequestPut) : Promise<HankeVaatimusResponse | undefined> {
        return http.put(`/hankkeet/vaatimukset/${id}`, request).then(response => {
            return response.data
        }).catch(error => {
            errorHandler(error)
        })
    }
}

export default new HankeAPI