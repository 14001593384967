import liiteApi from "@/apis/liiteApi";
import { LiiteRequest } from "@/api/models/liite-request"
import { ElMessage, UploadUserFile } from "element-plus";
import { computed } from "vue";
import useSWRV from "swrv";

const methods = {
    async getLiite(id : number) {
        const response = await liiteApi.getLiite(id)

        if(response) {
            window.open(response.url, "_blank")
        }
        else {
            ElMessage.error("Virhe tiedostoa ladatessa!")
        }
    },
    getLiitteet(viite : number, tyyppi : number) {
        const key = computed(() => "/liitteet/?" + new URLSearchParams({viite : viite.toString(), tyyppi : tyyppi.toString()}) )
        const fetcher = (key : string, viite : number, tyyppi : number) => {
            return liiteApi.getLiitteet(viite, tyyppi)
        }

        const { data, error } = useSWRV([key, viite, tyyppi], fetcher)

        if(error.value) {
            ElMessage.error("Virhe liitteiden latauksessa!")
        }
            
        return { data, error }
    },

    async addLiite(file : UploadUserFile | null | undefined, request : LiiteRequest) {
        if(!file || !file.raw) {
            ElMessage.error("Liitetiedosto tulee valita!")
        }
        else if(file.raw.size > 5000000) {
            ElMessage.error("Liian iso liitetiedosto!")
        } 
        else {
            const LiiteId = await liiteApi.addLiite(request)

            if(LiiteId) {
                const liiteResponse = await liiteApi.addLiiteFile(LiiteId.id!, file.raw)

                if(liiteResponse) {
                    return liiteResponse
                }
                else {
                    ElMessage.error("Liitetiedoston lataus ei onnistunut!")
                }
            }
            else {
                ElMessage.error("Liitteen lisäys ei onnistunut!")
            }
        }
    },

    async deleteLiite(id : number) {
        const response = await liiteApi.deleteLiite(id)

        if(response) {
            return response
        }
        else {
            ElMessage.error("Virhe liitteen poistossa!")
        }
    }
}

export default {
    methods
}