import { defineComponent, ref, watch } from 'vue';
import { useLocalStorage } from '@vueuse/core';
export default defineComponent({
  props: {
    total: {
      type: Number,
      required: true
    },
    paging: {
      type: Object,
      required: false
    }
  },
  emits: ['page-change'],
  setup(props, {
    emit
  }) {
    const defaulted = {
      pageSize: 30,
      currentPage: 1
    };
    const page = useLocalStorage('page', defaulted);
    const pageSizes = ref([10, 30, 60, 100]);
    watch(() => props.paging, () => {
      if (props.paging) {
        page.value = props.paging;
      }
    }, {
      deep: true
    });
    return {
      page,
      pageSizes,
      emit
    };
  }
});