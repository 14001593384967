import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComLoading = _resolveComponent("ComLoading");
  const _component_ComEmpty = _resolveComponent("ComEmpty");
  const _component_ComRoskakoriListing = _resolveComponent("ComRoskakoriListing");
  return !_ctx.huonekortit ? (_openBlock(), _createBlock(_component_ComLoading, {
    key: 0
  })) : _ctx.huonekortit.length == 0 ? (_openBlock(), _createBlock(_component_ComEmpty, {
    key: 1
  })) : (_openBlock(), _createBlock(_component_ComRoskakoriListing, {
    key: 2,
    huonekortit: _ctx.huonekortit,
    count: _ctx.count,
    onPalautaHuonekortti: _ctx.palautaButtonClicked,
    onPageChange: _ctx.pageChangeClicked
  }, null, 8, ["huonekortit", "count", "onPalautaHuonekortti", "onPageChange"]));
}