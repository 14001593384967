import { computed, defineComponent, inject, ref } from 'vue';
import ComHuonekorttiNimikeHistoriaItem from './ComHuonekorttiNimikeHistoriaItem.vue';
import { provideHuonekortti } from '@/types/Symbols';
import huonekorttiHistoriaService from '@/methods/huonekorttiHistoriaService';
import ComInfoButton from '@/components/common/buttons/ComInfoButton.vue';
import useSWRV from 'swrv';
export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  components: {
    ComHuonekorttiNimikeHistoriaItem,
    ComInfoButton
  },
  setup(props) {
    const getHuonekorttiHistoria = huonekorttiHistoriaService.methods.getHuonekorttiHistoria;
    const historiaKey = computed(() => (huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value) ? getHuonekorttiHistoria.key(huonekortti.value.id) : undefined);
    const huonekortti = inject(provideHuonekortti);
    const visible = ref(false);
    const active = ref(0);
    const {
      data: historia
    } = useSWRV(() => historiaKey.value, getHuonekorttiHistoria.fetcher);
    const historiaNimikkeet = computed(() => {
      var _a, _b, _c;
      if (visible.value) {
        const hn = (_a = historia.value) === null || _a === void 0 ? void 0 : _a.nimikkeet;
        const nimike = (_c = (_b = huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value) === null || _b === void 0 ? void 0 : _b.nimikkeet) === null || _c === void 0 ? void 0 : _c.find(n => n.id == props.id);
        if (hn) {
          return huonekorttiHistoriaService.methods.diffAllHistoriaNimike(hn, props.id, nimike);
        }
      }
      return [];
    });
    const buttonColor = huonekorttiHistoriaService.methods.tyyppiColor;
    return {
      historiaNimikkeet,
      visible,
      active,
      buttonColor
    };
  }
});