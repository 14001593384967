import http from "@/http-common";

class RaportointiApi {

    getHuonekorttiRaportti(tyyppi : number, huonekortit : number[]) : Promise<Blob> {
        return http.get("/raportointi/huonekortit", {
            responseType: "blob", 
            params: {
                tyyppi: tyyppi,
                huonekortit: huonekortit.join(",")
            }
        })
        .then((response) => {
            return response.data
        })
    }

    getHistoriaRaportti(tyyppi : number, huonekortit : number[], alku : string, loppu : string) : Promise<Blob> {
        return http.get("/raportointi/historia", {
            responseType: "blob",
            params: {
                tyyppi: tyyppi,
                huonekortit: huonekortit.join(","),
                alku: alku,
                loppu: loppu
            }
        })
        .then((response) => {
            return response.data
        })
    }
    
    getTilaRaportti(tyyppi : number, vastuuyksikot? : number[], huonekortit? : number[]) : Promise<Blob> {
        return http.get("/raportointi/tilat", {
            responseType: "blob", 
            params: {
                tyyppi: tyyppi,
                ...(vastuuyksikot ? { vastuuyksikot: vastuuyksikot.join(",") } : {}),
                ...(huonekortit ? { huonekortit: huonekortit.join(",") } : {})
            }
        })
        .then((response) => {
            return response.data
        })
    }

    getNimikeRaportti(nimikkeet : number[], vastuuyksikot? : number[], huonekortit? : number[]) : Promise<Blob> {
        return http.get("/raportointi/nimikkeet", {
            responseType: "blob", 
            params: {
                nimikkeet: nimikkeet.join(","),
                ...(vastuuyksikot ? { vastuuyksikot: vastuuyksikot.join(",") } : {}),
                ...(huonekortit ? { huonekortit: huonekortit.join(",") } : {})
            }
        })
        .then((response) => {
            return response.data
        })
    }    
}

export default new RaportointiApi