import ComLoading from '@/components/common/ComLoading.vue';
import huonekorttiHistoriaService from '@/methods/huonekorttiHistoriaService';
import { computed, defineComponent, inject } from 'vue';
import ComHuonekorttiNimikeHistoria from './suunnittuelutiedot/ComHuonekorttiNimikeHistoria.vue';
import ComHuonekorttiHistoria from './toimintatiedot/ComHuonekorttiHistoria.vue';
import ComEmpty from '@/components/common/ComEmpty.vue';
import useSWRV from 'swrv';
import ComTilaHistoria from './tilat/ComTilaHistoria.vue';
export default defineComponent({
  components: {
    ComHuonekorttiNimikeHistoria,
    ComLoading,
    ComHuonekorttiHistoria,
    ComEmpty,
    ComTilaHistoria
  },
  setup() {
    const getHuonekorttiHistoria = huonekorttiHistoriaService.methods.getHuonekorttiHistoria;
    const historiaKey = computed(() => getHuonekorttiHistoria.key(huonekorttiId.value));
    const huonekorttiId = inject("huonekorttiId");
    const {
      data
    } = useSWRV(() => historiaKey.value, getHuonekorttiHistoria.fetcher, getHuonekorttiHistoria.options);
    return {
      data
    };
  }
});