import { reactive } from "vue";

const state = reactive({
    huonekortitSelected: [] as number[]
})

const methods = {
    setSelected(val: number[]) {
        state.huonekortitSelected = val
    }
}

const getters = {
    getSelected() : number[] {
        return state.huonekortitSelected
    }
}

export default {
    methods,
    getters
}
