import { HuonekorttiHistoriaTyyppi } from '@/api/models/huonekortti-historia-tyyppi';
import ComCard from '@/components/common/ComCard.vue';
import huonekorttiHistoriaService from '@/methods/huonekorttiHistoriaService';
import { computed, defineComponent } from 'vue';
export default defineComponent({
  props: {
    data: {
      type: Object,
      required: false
    },
    previousData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const diffAndSanitize = huonekorttiHistoriaService.methods.diffAndSanitize;
    const tyyppiColor = huonekorttiHistoriaService.methods.tyyppiColor;
    const tyyppi = computed(() => props.previousData.tyyppi);
    const timelineColor = computed(() => tyyppiColor(tyyppi.value));
    const tilatunnus = computed(() => {
      var _a, _b;
      const a = (_a = props.previousData.tilatunnus) !== null && _a !== void 0 ? _a : "";
      if (tyyppi.value == HuonekorttiHistoriaTyyppi.Muutos && props.data) {
        const b = (_b = props.data.tilatunnus) !== null && _b !== void 0 ? _b : "";
        return diffAndSanitize(a, b);
      } else {
        return a;
      }
    });
    const pintaAla = computed(() => {
      const a = props.previousData.pintaAla ? props.previousData.pintaAla.toString() : "";
      if (tyyppi.value == HuonekorttiHistoriaTyyppi.Muutos && props.data) {
        const b = props.data.pintaAla ? props.data.pintaAla.toString() : "";
        return diffAndSanitize(a, b);
      } else {
        return a;
      }
    });
    const otsikko = computed(() => {
      if (tyyppi.value == HuonekorttiHistoriaTyyppi.Lisays) {
        return "Tila lisätty";
      }
      if (tyyppi.value == HuonekorttiHistoriaTyyppi.Muutos) {
        return "Tilaa muokattu";
      }
      if (tyyppi.value == HuonekorttiHistoriaTyyppi.Poisto) {
        return "Tila poistettu";
      } else {
        throw new Error("Virheellinen tyyppi!");
      }
    });
    return {
      tilatunnus,
      pintaAla,
      otsikko,
      timelineColor
    };
  },
  components: {
    ComCard
  }
});