import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi";
import { HuonekorttiNimikeHistoriaResponse } from "@/api/models/huonekortti-nimike-historia-response";
import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response";
import { OminaisuusResponse } from "@/api/models/ominaisuus-response";
import huonekorttiAPI from "@/apis/huonekorttiAPI";
import HistoriaNimike from "@/types/HistoriaNimike";
import { Diff, diff_match_patch, DIFF_INSERT, DIFF_DELETE, DIFF_EQUAL } from "diff-match-patch";
import sanitize from "sanitize-html";
import useSWRV from "swrv";

const options = {
    dedupingInterval: 0,
    revalidateOnFocus: true,
}

const url = (id : number) => {
    return `/huonekortit/${id}/historia`
} 
const fetcher = (url : string) => {
    return huonekorttiAPI.getHistoria(url)
}

const diff_prettyHtml = (diffs : Diff[]) => {
    const html = [];
    const pattern_amp = /&/g;
    const pattern_lt = /</g;
    const pattern_gt = />/g;
    const pattern_para = /\n/g;
    for (let x = 0; x < diffs.length; x++) {
      const op = diffs[x][0];    // Operation (insert, delete, equal)
      const data = diffs[x][1];  // Text of change.
      const text = data.replace(pattern_amp, '&amp;').replace(pattern_lt, '&lt;')
          .replace(pattern_gt, '&gt;').replace(pattern_para, '<br>');
      switch (op) {
        case DIFF_INSERT:
          html[x] = '<ins style="background:#e6ffe6;">' + text + '</ins>';
          break;
        case DIFF_DELETE:
          html[x] = '<del style="background:#ffe6e6;">' + text + '</del>';
          break;
        case DIFF_EQUAL:
          html[x] = '<span>' + text + '</span>';
          break;
      }
    }
    return html.join('');
}

const methods = {
    getHuonekorttiHistoria: {
        key (id : number | undefined) {
            if(id) {
                return `/huonekortit/${id}/historia`
            }
            else {
                return undefined
            }
        },
        fetcher (url : string) {
            return huonekorttiAPI.getHistoria(url)
        },
        options: options
    },
    diffAndSanitize(a : string, b : string) {
        const dmp = new diff_match_patch()
        const temp = dmp.diff_main(a, b);

        dmp.diff_cleanupSemantic(temp)

        return sanitize(diff_prettyHtml(temp),{
            allowedTags:[ 'del', 'ins','br' ]
        })
    },
    tyyppiIsLisays(item : HuonekorttiNimikeResponse | HistoriaNimike){
        if('tyyppi' in item && item.tyyppi == HuonekorttiHistoriaTyyppi.Lisays) {
            return true
        }
            return false
    },
    tyyppiIsPoisto(item : HuonekorttiNimikeResponse | HistoriaNimike){
        if('tyyppi' in item && item.tyyppi == HuonekorttiHistoriaTyyppi.Poisto) {
            return true
        }
            return false
    },
    tyyppiColor(type : HuonekorttiHistoriaTyyppi | undefined) {
        if(type == HuonekorttiHistoriaTyyppi.Lisays) {
            return "success"
        }
        if(type == HuonekorttiHistoriaTyyppi.Poisto) {
            return "danger"
        }
        else {
            return "primary"
        }
    },
    diffHistoriaNimike(historia : HuonekorttiNimikeHistoriaResponse, nimike? : HuonekorttiNimikeResponse | HuonekorttiNimikeHistoriaResponse) : HistoriaNimike {
        const hs = {
            id: historia.id,
            maara : historia.maara?.toString(),
            tasmennys: historia.tasmennys,
            ominaisuudet: historia.ominaisuudet,
            huonekorttiNimikeId: historia.huonekorttiNimikeId,
            tyyppi: historia.tyyppi,
            nimike: historia.nimike,
            aika: historia.aika,
            kayttaja: historia.kayttaja
        } as HistoriaNimike    
        
        if(historia?.tyyppi == HuonekorttiHistoriaTyyppi.Muutos && nimike) {
            hs.maara = methods.diffAndSanitize(historia.maara?.toString() ?? '', nimike.maara?.toString() ?? '')
            hs.tasmennys = methods.diffAndSanitize(historia.tasmennys ?? '', nimike.tasmennys ?? '')
            
            const ominaisuudet = [] as OminaisuusResponse[]

            nimike.ominaisuudet?.forEach(o => {
                const ominaisuus = { ...o }

                if(!historia.ominaisuudet?.some(ho => ho.id == o.id)) {
                    ominaisuus.nimi = methods.diffAndSanitize('', o.nimi ?? '')
                }

                ominaisuudet.push(ominaisuus)
            })

            historia.ominaisuudet?.forEach(o => {
                const ominaisuus = { ...o }

                if(!ominaisuudet.some(no => no.id == o.id)) {
                    ominaisuus.nimi = methods.diffAndSanitize(o.nimi ?? '', '')

                    ominaisuudet.push(ominaisuus)
                }
            })

            hs.ominaisuudet = ominaisuudet
        }

        return hs
    },
    diffAllHistoriaNimike(nimikeHistoria : HuonekorttiNimikeHistoriaResponse[], id : number, nimike? : HuonekorttiNimikeResponse) : HistoriaNimike[] {
        const nh = nimikeHistoria.filter(n => n.huonekorttiNimikeId == id)
        const historiaNimikkeet : HistoriaNimike[] = []

        nh.forEach( n => {
            if(this.tyyppiIsLisays(n) || this.tyyppiIsPoisto(n)) {
                historiaNimikkeet.push(this.diffHistoriaNimike(n))
            }
            else {
                const i = nh.findIndex(i => i.id == n.id)

                if(i != -1 && nh[i-1]) {
                    historiaNimikkeet.push(this.diffHistoriaNimike(n, nh[i-1]))
                } 
                else {
                    historiaNimikkeet.push(this.diffHistoriaNimike(n, nimike))
                }
            }

        })

        return historiaNimikkeet
    },
}
const getters = {

}

export default {
    methods,
    getters
}
