import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_el_empty = _resolveComponent("el-empty");
  const _component_el_card = _resolveComponent("el-card");
  return _openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [_createVNode(_component_el_empty, {
      description: "Sivua ei löytynyt"
    })]),
    _: 1
  });
}