import { defineComponent, ref } from 'vue';
import { useYllapitoHanke } from './yllapitoHankeComposables';
import ComDeleteButton from '../common/buttons/ComDeleteButton.vue';
import { ElMessage } from 'element-plus';
import ComSelect from '../common/ComSelect.vue';
import ComSuccessButton from '../common/buttons/ComSuccessButton.vue';
export default defineComponent({
  setup() {
    const {
      paakayttajat: data,
      deletePaakayttaja,
      addPaakayttaja,
      searchKayttajat
    } = useYllapitoHanke();
    const selectedKayttaja = ref();
    const kayttajat = ref([]);
    const loading = ref(false);
    const fetchKayttajat = val => {
      loading.value = true;
      searchKayttajat(val).then(respose => {
        if (respose) {
          kayttajat.value = respose;
        }
        loading.value = false;
      });
    };
    const addPaakayttajaClick = () => {
      if (selectedKayttaja.value) {
        addPaakayttaja(selectedKayttaja.value.id);
        selectedKayttaja.value = undefined;
      } else {
        ElMessage.warning('Valitse käyttäjä');
      }
    };
    return {
      data,
      selectedKayttaja,
      kayttajat,
      loading,
      deletePaakayttaja,
      addPaakayttajaClick,
      fetchKayttajat
    };
  },
  components: {
    ComDeleteButton,
    ComSelect,
    ComSuccessButton
  }
});