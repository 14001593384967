import { useVModel } from '@vueuse/core';
import { defineComponent, ref } from 'vue';
import ComDeleteButton from '../common/buttons/ComDeleteButton.vue';
import ComSuccessButton from '../common/buttons/ComSuccessButton.vue';
import ComEditButton from '../common/buttons/ComEditButton.vue';
import ComCancelButton from '../common/buttons/ComCancelButton.vue';
import { useYllapitoHanke } from './yllapitoHankeComposables';
export default defineComponent({
  props: {
    vastuuyksikko: {
      type: Object,
      required: true
    }
  },
  components: {
    ComSuccessButton,
    ComDeleteButton,
    ComEditButton,
    ComCancelButton
  },
  setup(props, {
    emit
  }) {
    const {
      updateVastuuyksikko,
      deleteVastuuyksikko
    } = useYllapitoHanke();
    const data = useVModel(props, "vastuuyksikko", emit);
    const edit = ref(false);
    const saveClick = async () => {
      const response = await updateVastuuyksikko(data.value.id, data.value);
      if (response) {
        edit.value = false;
      }
    };
    return {
      data,
      edit,
      saveClick,
      deleteVastuuyksikko
    };
  }
});