import huonekorttiListingService from '@/methods/huonekorttiListingService';
import huonekorttiService from '@/methods/huonekorttiService';
import { ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    huonekortti: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const deleteHuonekortti = huonekorttiService.methods.deleteHuonekortti;
    const mutate = huonekorttiListingService.methods.setMutate;
    const deleteHuonekorttiClick = () => {
      var _a;
      ElMessageBox.confirm("Haluatko varmasti poistaa huonekortin: " + ((_a = props.huonekortti.tilanimike) === null || _a === void 0 ? void 0 : _a.nimi)).then(() => {
        if (props.huonekortti.id) {
          deleteHuonekortti(props.huonekortti.id).then(result => {
            if (result) {
              mutate(true);
              ElMessage.success("Huonekortti poistettu");
            } else {
              ElMessage.error("Virhe huonekortin poistamisessa!");
            }
          });
        }
      });
    };
    return {
      deleteHuonekorttiClick
    };
  }
});