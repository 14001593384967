import kayttajaService from "@/methods/kayttajaService";
import { isAuthenticated, isLoading } from "./auth0-plugin";
import { watch } from "vue";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const authenticationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {

  const guardAction = async () => {
    if (isAuthenticated.value) {
      const { kayttoehdot } =  await kayttajaService.kayttajaState()

      if(!kayttoehdot.value) {
        return next({name: "Kayttoehdot"})
      }
      else {
        return next();
      }
    }
    else {
      const maintenance = process.env.VUE_APP_MAINTENANCE_MODE === "true"

      if(maintenance) {
        return next({name: "Huolto"})
      }

      window.localStorage.setItem("targetUrl", to.fullPath)
      window.location.href = "https://web.vahtihuonekortti.fi"

      return next()
    }
  };

  if (!isLoading.value) {
    return guardAction();
  }

  watch(isLoading, (currentValue) => {
    if (!currentValue) {
      return guardAction();
    }
  });
};

export const loginAuthenticationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void => {
  const guardAction = () => {
    if (!isAuthenticated.value) {
      return next();
    }
    else {
      return next({name: "Etusivu"})
    }
  };

  if (!isLoading.value) {
    return guardAction();
  }

  watch(isLoading, (currentValue) => {
    if (!currentValue) {
      return guardAction();
    }
  });
};

export const KayttoehdotAuthenticationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const guardAction = async () => {
    if(!isAuthenticated.value) {
      return next({name: "Kirjaudu"})
    }

    const { kayttoehdot } =  await kayttajaService.kayttajaState()

    if (!kayttoehdot.value) {
      return next();
    }
    else {
      return next({name: "Etusivu"})
    }
  };

  guardAction()

};