import http from "@/http-common";
import { KayttajaResponse } from "@/api/models/kayttaja-response";
import { PaakayttajaAndOikeusResponse } from "@/api/models/paakayttaja-and-oikeus-response";
import { KayttajaRequest } from "@/api/models/kayttaja-request";
import { errorHandler } from "./baseApi";

class KayttajaAPi {
    
    getKirjautunutKayttaja() : Promise<KayttajaResponse> {
        return http.get("kayttajat/kirjautunut").then((response) => response.data)
    }

    getKayttajat(url: string) : Promise<KayttajaResponse[]> {
        return http.get(url).then(response => response.data)
    }

    getKayttajaById(id : number) : Promise<KayttajaResponse> {
        return http.get(`kayttajat/${id}`).then((response) => response.data)
    }

    getKayttajaOikeudet() : Promise<PaakayttajaAndOikeusResponse> {
        return http.get("/kayttajat/oikeudet").then((response) => response.data)
    }

    addKayttaja(kayttaja : KayttajaRequest) : Promise<KayttajaResponse> {
        return http.post("kayttajat/", kayttaja).then(response => response.data)
    }

    updateKayttaja(id : number, kayttaja : KayttajaRequest) : Promise<KayttajaResponse> {
        return http.put(`kayttajat/${id}`,kayttaja).then(response => response.data).catch(error => errorHandler(error))
    } 

    resetPassword() : Promise<boolean> {
        return http.post("/kayttajat/vaihdaSalasana").then(() => {
            return true
        }).catch(error => {
            errorHandler(error)

            return false
        }) 
    }

    consestGDPR() : Promise<boolean> {
        return http.post("/kayttajat/hyvaksyEhdot").then(() => {
            return true
        }).catch(error => {
            errorHandler(error)

            return false
        })
    }
}

export default new KayttajaAPi