import huonekorttiAPI from '@/apis/huonekorttiAPI';
import huonekorttiListingService from '@/methods/huonekorttiListingService';
import huonekorttiTuontiService from '@/methods/huonekorttiTuontiService';
import { ElMessage } from 'element-plus';
import { computed, defineComponent, inject } from 'vue';
export default defineComponent({
  emits: ["peruuta"],
  setup(props, context) {
    const vastuuyksikot = inject("vastuuyksikot");
    const maara = computed(() => {
      return huonekorttiTuontiService.state.huonekortit.length;
    });
    const vastuuyksikko = computed(() => {
      var _a;
      return (_a = vastuuyksikot.value.find(v => v.id === huonekorttiTuontiService.state.vastuuyksikko)) === null || _a === void 0 ? void 0 : _a.nimi;
    });
    const peruuta = () => {
      context.emit("peruuta", false);
    };
    const hyvaksy = () => {
      const huonekortit = huonekorttiTuontiService.state.huonekortit.map(h => {
        return {
          id: h
        };
      });
      huonekorttiAPI.importHuonekortit(huonekorttiTuontiService.state.vastuuyksikko, huonekortit).then(() => {
        ElMessage({
          showClose: true,
          message: "Huonekortit tuotu!",
          center: true,
          type: 'success'
        });
        huonekorttiListingService.methods.setMutate(true);
      }).catch(() => {
        ElMessage({
          showClose: true,
          message: "Huonekorttien tuonnissa ongelmia!",
          center: true,
          type: 'error'
        });
      });
      context.emit("peruuta", false);
    };
    return {
      maara,
      vastuuyksikko,
      vastuuyksikot,
      peruuta,
      hyvaksy
    };
  }
});