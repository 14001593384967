import { defineComponent, ref } from 'vue';
import ComHuonekorttiTableWithFilterAndPagination from '@/components/hanke/huonekortit/table/ComHuonekorttiTableWithFilterAndPagination.vue';
import ComLoading from '@/components/common/ComLoading.vue';
export default defineComponent({
  components: {
    ComHuonekorttiTableWithFilterAndPagination,
    ComLoading
  },
  setup() {
    const loading = ref();
    const loadingChanged = val => {
      loading.value = val;
    };
    return {
      loading,
      loadingChanged
    };
  }
});