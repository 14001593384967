import { KayttajaOikeusResponse } from "@/api/models/kayttaja-oikeus-response";
import { OikeusProperties } from "@/api/models/oikeus-properties";
import { OikeusResponse } from "@/api/models/oikeus-response";
import { OikeusRequest } from "@/api/models/oikeus-request"
import { PaakayttajaResponse } from "@/api/models/paakayttaja-response"
import http from "@/http-common";
import { KayttajaResponse } from "@/api/models/kayttaja-response";
import { HankeRequest } from "@/api/models/hanke-request";
import { HankeResponse } from "@/api/models/hanke-response";
import { errorHandler } from "./baseApi";
import { SairaalaResponse } from "@/api/models/sairaala-response";

class adminApi {

    getVastuuyksikkoOikeudet(vastuuyksikot : number[]) : Promise<KayttajaOikeusResponse[]> {
        return http.get("/oikeudet", {
            params: {
                vastuuyksikot: vastuuyksikot.join(",")
            }
        })
        .then(response => {
            return response.data
        })
    }

    updateOikeus(id : number, properties : OikeusProperties) : Promise<OikeusResponse> {
        return http.put(`/oikeudet/${id}`, properties).then(response => {
            return response.data
        })
    }

    addOikeus(request : OikeusRequest) : Promise<OikeusResponse> {
        return http.post("/oikeudet", request).then(response => {
            return response.data
        })
    }

    addOikeudet(requests : OikeusRequest[]) : Promise<OikeusResponse[]> {
        return Promise.all(
            requests.map(r => {
                return this.addOikeus(r)
            })
        ).then(response => response)
    } 

    deleteOikeus(id : number) : Promise<void> {
        return http.delete(`/oikeudet/${id}`)
    }

    deleteAllOikeudet(ids : number[]) {
        return Promise.all(
            ids.map(id => {
                return this.deleteOikeus(id)
            })
        )
    }

    getSairaalaKayttajatByHankeId(id : number) : Promise<KayttajaResponse[]> {
        return http.get(`/kayttajat/hankeId/${id}`).then(response => {
            return response.data
        })
    }

    getHankePaakayttajat(id : number) : Promise<PaakayttajaResponse[]> {
        return http.get(`/oikeudet/paakayttajat/hankeId/${id}`).then(response => {
            return response.data
        })
    }
    
    addHankePaakayttaja(hankeId : number, kayttajaId : number) : Promise<PaakayttajaResponse> {
        return http.post(`/oikeudet/paakayttajat/hankeId/${hankeId}`, null, {
            params: {
                kayttajaId: kayttajaId
            } 
        })
        .then(response => {
            return response.data
        })
    }

    addHankePaakayttajat(hankeId : number, kayttajaIds : number[]) : Promise<PaakayttajaResponse[]> {
        return Promise.all(
            kayttajaIds.map(k => {
                return this.addHankePaakayttaja(hankeId, k)
            })
        )
    }

    deleteHankePaakayttaja(id : number) : Promise<void> {
        return http.delete(`/oikeudet/paakayttajat/${id}`)
    }

    updateHanke(id : number, request : HankeRequest) : Promise<HankeResponse | undefined> {
        return http.put(`/hankkeet/${id}`, request).then(response => {
            return response.data
        }).catch((error) => {
            errorHandler(error)
        })
    }

    addHanke(request : HankeRequest) : Promise<HankeResponse | undefined> {
        return http.post("/hankkeet", request).then(response => {
            return response.data
        }).catch((error) => {
            errorHandler(error)
        })
    }

    getSairaalat() : Promise<SairaalaResponse[]> {
        return http.get("/sairaalat").then(response => {
            return response.data
        }).catch((error) => {
            errorHandler(error)
        })
    }
}

export default new adminApi