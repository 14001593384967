import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComLisaaHuonekortti = _resolveComponent("ComLisaaHuonekortti");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_ComLoading = _resolveComponent("ComLoading");
  const _component_ComFilterInput = _resolveComponent("ComFilterInput");
  const _component_el_col = _resolveComponent("el-col");
  const _component_ComPagination = _resolveComponent("ComPagination");
  const _component_el_row = _resolveComponent("el-row");
  const _component_ComHuonekorttiTableEditable = _resolveComponent("ComHuonekorttiTableEditable");
  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.writableVastuuyksikot && _ctx.writableVastuuyksikot.length > 0 ? (_openBlock(), _createBlock(_component_ComLisaaHuonekortti, {
    key: 0
  })) : _createCommentVNode("", true), _createVNode(_component_el_divider), _ctx.loading ? (_openBlock(), _createBlock(_component_ComLoading, {
    key: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 6
    }, {
      default: _withCtx(() => [_createVNode(_component_ComFilterInput, {
        modelValue: _ctx.filter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filter = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 18
    }, {
      default: _withCtx(() => [_createVNode(_component_ComPagination, {
        total: _ctx.totalItems,
        paging: _ctx.paging,
        onPageChange: _cache[1] || (_cache[1] = val => _ctx.paging = val),
        style: {
          "float": "right"
        }
      }, null, 8, ["total", "paging"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_ComHuonekorttiTableEditable, {
    huonekortit: _ctx.huonekortit,
    onSortChanged: _ctx.sortChanged,
    onCancelEdit: _ctx.cancelEdit
  }, null, 8, ["huonekortit", "onSortChanged", "onCancelEdit"])]))], 64);
}