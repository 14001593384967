import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_col = _resolveComponent("el-col");
  const _component_ComDeleteButton = _resolveComponent("ComDeleteButton");
  const _component_ComEditButton = _resolveComponent("ComEditButton");
  const _component_ComCancelButton = _resolveComponent("ComCancelButton");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_el_space = _resolveComponent("el-space");
  const _component_el_row = _resolveComponent("el-row");
  return _ctx.data ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 10
    }, {
      default: _withCtx(() => [_ctx.edit ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 0
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.data.nimi,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.data.nimi = $event)
        }, null, 8, ["modelValue"])]),
        _: 1
      })) : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.data.nimi), 1))]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 12,
      offset: 2
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [_createVNode(_component_el_space, null, {
          default: _withCtx(() => [!_ctx.edit ? (_openBlock(), _createBlock(_component_ComDeleteButton, {
            key: 0,
            onClick: _cache[1] || (_cache[1] = $event => _ctx.deleteVastuuyksikko(_ctx.data.id))
          }, {
            default: _withCtx(() => [_createTextVNode("Poista")]),
            _: 1
          })) : _createCommentVNode("", true), !_ctx.edit ? (_openBlock(), _createBlock(_component_ComEditButton, {
            key: 1,
            onClick: _cache[2] || (_cache[2] = $event => _ctx.edit = true)
          }, {
            default: _withCtx(() => [_createTextVNode("Muokkaa")]),
            _: 1
          })) : _createCommentVNode("", true), _ctx.edit ? (_openBlock(), _createBlock(_component_ComCancelButton, {
            key: 2,
            onClick: _cache[3] || (_cache[3] = $event => _ctx.edit = false)
          }, {
            default: _withCtx(() => [_createTextVNode("Peruuta")]),
            _: 1
          })) : _createCommentVNode("", true), _ctx.edit ? (_openBlock(), _createBlock(_component_ComSuccessButton, {
            key: 3,
            onClick: _ctx.saveClick
          }, {
            default: _withCtx(() => [_createTextVNode("Tallenna")]),
            _: 1
          }, 8, ["onClick"])) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true);
}