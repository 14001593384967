import { defineComponent } from 'vue';
export default defineComponent({
  emits: ["oikeusChange"],
  props: {
    oikeudet: {
      type: Object,
      default: val => {
        val.luku = true;
        val.kirjoitus = false;
        val.raportointi = false;
      }
    }
  },
  setup(props, {
    emit
  }) {
    const oikeusClick = type => {
      const oikeudet = props.oikeudet;
      if (type == "kirjoitus") {
        if (!oikeudet.kirjoitus) {
          oikeudet.kirjoitus = true;
          oikeudet.raportointi = true;
        } else {
          oikeudet.kirjoitus = false;
        }
      }
      if (type == "raportointi") {
        oikeudet.raportointi = !oikeudet.raportointi;
      }
      emit("oikeusChange", oikeudet);
    };
    return {
      oikeusClick
    };
  }
});