import "core-js/modules/es.array.push.js";
import { defineComponent, ref } from 'vue';
import ComLogoutButton from '@/components/common/buttons/ComLogoutButton.vue';
import ComAvatarButton from '@/components/common/buttons/ComAvatarButton.vue';
import { SwitchButton } from '@element-plus/icons-vue';
import router from '@/router';
import kayttajaOikeusService from '@/methods/kayttajaOikeusService';
export default defineComponent({
  components: {
    ComLogoutButton,
    ComAvatarButton,
    SwitchButton
  },
  setup() {
    const isSuperUser = ref(false);
    const etusivuClick = () => {
      router.push({
        name: "Etusivu"
      });
    };
    const ohjeClick = () => {
      window.open("https://scribehow.com/page/Vahti_ohje__wfWdtIrpRCyPAv802UyY0w", '_blank');
    };
    const yllapitoClick = () => {
      router.push({
        name: "Yllapito"
      });
    };
    const loadSuperUser = async () => {
      isSuperUser.value = await kayttajaOikeusService.methods.hasSuperUserRights();
    };
    loadSuperUser();
    return {
      isSuperUser,
      etusivuClick,
      ohjeClick,
      yllapitoClick
    };
  }
});