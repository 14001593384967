import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_com_loading = _resolveComponent("com-loading");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_ComYllapitoHankeLisaaVastuuyksikko = _resolveComponent("ComYllapitoHankeLisaaVastuuyksikko");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createElementBlock("div", null, [!_ctx.sairaalat ? (_openBlock(), _createBlock(_component_com_loading, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_el_form, {
    key: 1,
    ref: "ruleFormRef",
    model: _ctx.hanke,
    rules: _ctx.rules
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Nimi",
      prop: "nimi"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.hanke.nimi,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.hanke.nimi = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Vaatimukset",
      prop: "vaatimukset"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.hanke.vaatimukset,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.hanke.vaatimukset = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Hankenumero",
      prop: "hankenumero"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.hanke.hankenumero,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.hanke.hankenumero = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Työnumero",
      prop: "tyonumero"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.hanke.tyonumero,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.hanke.tyonumero = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Sairaala",
      prop: "sairaala"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        modelValue: _ctx.hanke.sairaala,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.hanke.sairaala = $event),
        "value-key": "id"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sairaalat, sairaala => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: sairaala.id,
            label: sairaala.nimi,
            value: sairaala
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_ComYllapitoHankeLisaaVastuuyksikko), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        onClick: _ctx.addHankeClick
      }, {
        default: _withCtx(() => [_createTextVNode("Lisää")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"]))]);
}