import { defineComponent, ref } from 'vue';
import ComHuonekorttiValinta from './ComHuonekorttiValinta.vue';
import ComVastuuyksikkoValinta from './ComVastuuyksikkoValinta.vue';
import ComVarmistus from './ComVarmistus.vue';
import huonekorttiTuontiService from '@/methods/huonekorttiTuontiService';
import { ElMessage } from 'element-plus';
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue';
import ComImportButton from '@/components/common/buttons/ComImportButton.vue';
export default defineComponent({
  components: {
    ComHuonekorttiValinta,
    ComVastuuyksikkoValinta,
    ComVarmistus,
    ArrowRight,
    ArrowLeft,
    ComImportButton
  },
  setup() {
    const active = ref(0);
    const header = ["Valitse tuotavat huonekortit", "Valitse vastuuyksikkö mihin huonekortit tuodaan", "Hyväksy tuonti"];
    const visible = ref(false);
    const seuraava = () => {
      if (active.value === 0) {
        if (huonekorttiTuontiService.state.huonekortit.length === 0) {
          ElMessage.error('Valitse ainakin yksi huonekortti.');
          return;
        }
      }
      if (active.value === 1) {
        if (!huonekorttiTuontiService.state.vastuuyksikko) {
          ElMessage.error('Valitse vastuuyksikkö.');
          return;
        }
      }
      active.value++;
    };
    const peruuta = () => {
      active.value = 0;
      huonekorttiTuontiService.methods.setVastuuyksikko(NaN);
      huonekorttiTuontiService.methods.clearHuonekortit();
      visible.value = false;
    };
    return {
      active,
      closed,
      header,
      visible,
      seuraava,
      peruuta
    };
  }
});