import { HankeRequest } from "@/api/models/hanke-request";
import { HankeResponse } from "@/api/models/hanke-response";
import { KayttajaResponse } from "@/api/models/kayttaja-response";
import { VastuuyksikkoRequest } from "@/api/models/vastuuyksikko-request";
import adminService from "@/methods/adminService";
import hankeListingService from "@/methods/hankeListingService";
import hankeService from "@/methods/hankeService";
import sairaalaService from "@/methods/sairaalaService";
import vastuuyksikkoService from "@/methods/vastuuyksikkoService";
import { ElMessageBox } from "element-plus";
import { computed, ref, watch } from "vue";

const selectedHanke = ref<HankeResponse>()

export function useYllapitoHanke() {
    const setArkistoidut = hankeListingService.methods.setArkistoidut
    const hankeId = computed(() => selectedHanke.value?.id)
    const searchKayttajat = adminService.methods.getKayttajat
    const { data : hankkeet, mutate : reloadHankkeet } = hankeListingService.useHankeListing()
    const { data: sairaalat } = sairaalaService.methods.getSairaalat()
    const { data: paakayttajat, del, add, mutate : reloadPaakayttajat } = adminService.usePaakayttajat(hankeId.value)
    const kayttajatSearched = ref<KayttajaResponse[]>([])
    const showArchived = ref(false)

    const updateHanke = async (hanke : HankeResponse) => {
        const confirm = await ElMessageBox.confirm('Haluatko varmasti tallentaa muutokset?', 'Tallenna muutokset', {
            confirmButtonText: 'Tallenna',
            cancelButtonText: 'Peruuta',
            type: 'warning'
        })

        if(!confirm) {
            return
        }
        else {
            const request = hanke as HankeRequest

            const response = await hankeService.methods.updateHanke(hanke.id, request)
    
            if(response) {
                reloadHankkeet()
            }
        }
    }

    const updateVastuuyksikko = async (id: number, vastuuyksikko : VastuuyksikkoRequest) => {
        const response = await vastuuyksikkoService.methods.update(id, vastuuyksikko)

        if(response) {
            reloadHankkeet()

            return response
        }
    }

    const deleteVastuuyksikko = async (id: number) => {
        const confirm = await ElMessageBox.confirm('Haluatko varmasti poistaa vastuuyksikön?', 'Poista vastuuyksikkö', {
            confirmButtonText: 'Poista',
            cancelButtonText: 'Peruuta',
            type: 'warning'
        })

        if(!confirm) {
            return
        }
        else {
            const response = await vastuuyksikkoService.methods.delete(id)

            if(response) {
                reloadHankkeet()
            }
        }
    }

    const addVaastuuyksikko = async (vastuuyksikko : VastuuyksikkoRequest) => {
        vastuuyksikko.hanke = selectedHanke.value

        const response = await vastuuyksikkoService.methods.add(vastuuyksikko)

        if(response) {
            reloadHankkeet()
        }
    }

    const setSelectedHanke = (id : number) => {
        selectedHanke.value = hankkeet.value?.find(h => h.id == id)
    }

    const deletePaakayttaja = async (id : number) => {
        const confirm = await ElMessageBox.confirm('Haluatko varmasti poistaa pääkäyttäjän?', 'warning', {
            confirmButtonText: 'Kyllä',
            cancelButtonText: 'Peruuta',
            type: 'warning'
        })

        if(confirm) {   
            const response = await del(id)

            if(response) {
                reloadPaakayttajat()
            }
        }
    }

    const addPaakayttaja = async (id : number) => {
        
        if(selectedHanke.value) {
            const response = await add(selectedHanke.value.id, id)

            if(response) {
                reloadPaakayttajat()
            }
        }
    }

    watch(showArchived, () => {
        setArkistoidut(showArchived.value)
    })

    watch(hankkeet, () => {
        if(selectedHanke.value) {
            selectedHanke.value = hankkeet.value?.find(h => h.id == selectedHanke.value?.id)
        }
    })

    return {
        hankkeet,
        showArchived,
        selectedHanke,
        sairaalat,
        paakayttajat,
        kayttajatSearched,
        updateHanke,
        reloadHankkeet,
        updateVastuuyksikko,
        deleteVastuuyksikko,
        addVaastuuyksikko,
        setSelectedHanke,
        addPaakayttaja,
        deletePaakayttaja,
        searchKayttajat
    }
}

const hanke = ref<HankeRequest>({
    nimi: '',
    vaatimukset: '',
    tyonumero: '',
    hankenumero: '',
    sairaala: {id: 0},
    vastuuyksikot: [{nimi: '', vaatimukset: ''}] 
})

export function useAddHanke() {
    const { addHanke } = hankeService.methods
    const { data : sairaalat } = sairaalaService.methods.getSairaalat()
    const { reloadHankkeet } = useYllapitoHanke()

    const formatHanke = () => {
        hanke.value.nimi = ""
        hanke.value.vaatimukset = ""
        hanke.value.tyonumero = ""
        hanke.value.hankenumero = ""
        hanke.value.sairaala = {id: 0}
        hanke.value.vastuuyksikot = [{nimi: '', vaatimukset: ''}]
    }

    const addVaastuuyksikko = () => {
        hanke.value.vastuuyksikot.push({nimi: '', vaatimukset: ''}) 
    }

    const deleteVaastuuyksikko = (index : number) => {
        if(hanke.value.vastuuyksikot.length > 1) {
            hanke.value.vastuuyksikot.splice(index, 1)
        }
    }
    const add = async () => {
        const response = await addHanke(hanke.value)

        if(response) {
            reloadHankkeet()
            formatHanke()
        }
    }

    return {
        sairaalat,
        hanke,
        add,
        addVaastuuyksikko,
        deleteVaastuuyksikko
    }
}