import "core-js/modules/es.array.push.js";
import { computed, defineComponent, onBeforeUnmount, provide, ref, watch, watchEffect } from 'vue';
import ComPageHeader from '../components/layout/ComPageHeader.vue';
import ComToimintatiedot from '@/components/huonekortti/ComToimintatiedot.vue';
import ComSuunnittelutiedot from '@/components/huonekortti/ComSuunnittelutiedot.vue';
import ComHanketiedot from '@/components/huonekortti/hanketiedot/ComHanketiedot.vue';
import ComHistoria from '@/components/huonekortti/historia/ComHistoria.vue';
import { provideHanke, provideHuonekortti, provideHuonekorttiIsEdited, provideNimikkeet, provideSelectedVastuuyksikot } from '../types/Symbols';
import ComLoading from '@/components/common/ComLoading.vue';
import kayttajaOikeusService from '@/methods/kayttajaOikeusService';
import oikeudet from '@/types/OikeudetEnum';
import hankeService from '@/methods/hankeService';
import ComHuonekorttiInfoDropDownButton from '@/components/huonekortti/ComHuonekorttiInfoDropDownButton.vue';
import useSWRV from 'swrv';
import ComKommentointi from '@/components/kommentointi/ComKommentointi.vue';
import ComLiitteet from '@/components/liitteet/ComLiitteet.vue';
import ComCard from '@/components/common/ComCard.vue';
import huonekorttiService from '@/methods/huonekorttiService';
import nimikeKategoriaService from '@/methods/nimikeKategoriaService';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
export default defineComponent({
  components: {
    ComPageHeader,
    ComToimintatiedot,
    ComSuunnittelutiedot,
    ComLoading,
    ComHanketiedot,
    ComHistoria,
    ComHuonekorttiInfoDropDownButton,
    ComKommentointi,
    ComLiitteet,
    ComCard
  },
  props: {
    huonekorttiId: {
      required: true,
      type: Number
    }
  },
  name: 'huonekorttiView',
  setup(props) {
    onBeforeRouteLeave((to, from, next) => {
      if (pageChangeConfirm()) {
        next();
      } else {
        next(false);
      }
    });
    onBeforeRouteUpdate((to, from, next) => {
      if (pageChangeConfirm()) {
        next();
      } else {
        next(false);
      }
    });
    onBeforeUnmount(() => {
      if (isEdited.value) {
        resetEdited();
      }
    });
    const getHuonekortti = huonekorttiService.methods.getHuonekortti;
    const getHanke = hankeService.methods.getHankeByVastuuyksikkoId;
    const getNimikeKategoriat = nimikeKategoriaService.methods.getNimikeKategoriat;
    const {
      data: kategoriaNimikeData
    } = getNimikeKategoriat(true);
    const huonekortti = ref();
    const nimikkeet = ref([]);
    const dataLoaded = ref(false);
    const showHanketiedot = ref(false);
    const showMuutokset = ref(false);
    const tilat = ref("");
    const headerTitle = ref("");
    const headerSubtitle = ref("");
    const hanke = ref();
    const vastuuyksikot = ref([]);
    const edited = ref({
      toimintatiedot: false,
      suunnittelutiedot: false,
      nimikeLisays: false
    });
    const liitteetCount = computed(() => {
      var _a, _b, _c;
      return (_c = (_b = (_a = huonekortti.value) === null || _a === void 0 ? void 0 : _a.liitteet) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0;
    });
    const kommentitCount = computed(() => {
      var _a, _b, _c;
      return (_c = (_b = (_a = huonekortti.value) === null || _a === void 0 ? void 0 : _a.kommentit) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0;
    });
    const hankeKey = computed(() => {
      if (huonekortti.value) {
        return getHanke.key(huonekortti.value.vastuuyksikko.id);
      } else {
        return undefined;
      }
    });
    const {
      data: hankeData
    } = useSWRV(() => hankeKey.value, getHanke.fetcher, getHanke.options);
    const huonekorttiId = computed(() => props.huonekorttiId);
    const writingRights = computed(() => {
      var _a;
      if ((_a = hanke.value) === null || _a === void 0 ? void 0 : _a.arkistoitu) {
        return false;
      }
      if (huonekortti.value && huonekortti.value.vastuuyksikko != undefined) {
        return kayttajaOikeusService.methods.hasVastuuyksikkoRights(huonekortti.value.vastuuyksikko.id, oikeudet.Kirjoitus);
      } else {
        return false;
      }
    });
    const paakayttaja = computed(() => {
      if (huonekortti.value && huonekortti.value.vastuuyksikko != undefined) {
        return kayttajaOikeusService.methods.hasVastuuyksikkoPaakayttajaRights(huonekortti.value.vastuuyksikko.id);
      } else {
        return false;
      }
    });
    const isEdited = computed(() => {
      return edited.value.nimikeLisays || edited.value.suunnittelutiedot || edited.value.toimintatiedot;
    });
    const resetEdited = () => {
      edited.value = {
        nimikeLisays: false,
        suunnittelutiedot: false,
        toimintatiedot: false
      };
      window.removeEventListener("beforeunload", beforeWindowUnload);
    };
    const loadPage = async id => {
      var _a;
      dataLoaded.value = false;
      if (id !== 0 && !isNaN(id) && id !== undefined) {
        const response = await getHuonekortti(id);
        if (response) {
          setupHuonekorttiData(response);
          setupTiedot(response);
          nimikkeet.value = (_a = response.nimikkeet) !== null && _a !== void 0 ? _a : [];
          dataLoaded.value = true;
        }
      }
    };
    const setupHuonekorttiData = h => {
      if (h) {
        if (h.henkilomaaraMax == null) {
          h.henkilomaaraMax = NaN;
        }
        if (h.henkilomaaraNorm == null) {
          h.henkilomaaraNorm = NaN;
        }
        if (h.toimintaAika == null) {
          h.toimintaAika = "";
        }
        if (h.toiminnanKuvaus == null) {
          h.toiminnanKuvaus = "";
        }
        if (h.toiminnanVaatimukset == null) {
          h.toiminnanVaatimukset = "";
        }
      }
      huonekortti.value = h;
    };
    const setupTiedot = h => {
      if (h) {
        const t = [];
        if (h.tilat) h.tilat.map(tila => {
          if (tila.tilatunnus) {
            let tilaString = tila.tilatunnus;
            tilaString += tila.pintaAla ? ` (${tila.pintaAla} m2)` : "";
            t.push(tilaString);
          }
        });
        tilat.value = t.join(", ");
        if (h.tilanimike) {
          headerTitle.value = `${h.tilanimike.nimi}, ${h.toiminta}`;
        }
        headerSubtitle.value = tilat.value;
      }
    };
    const loadHanketiedot = () => {
      showHanketiedot.value = !showHanketiedot.value;
    };
    const closeHanketiedot = () => {
      showHanketiedot.value = false;
    };
    const liiteChangeClick = val => {
      if (huonekortti.value) {
        huonekortti.value.liitteet = val;
      }
    };
    const kommenttiChangeClick = val => {
      if (huonekortti.value) {
        huonekortti.value.kommentit = val;
      }
    };
    const pageChangeConfirm = () => {
      if (!isEdited.value) {
        return true;
      } else {
        const confirm = window.confirm("Sinulla on tallentamattomia muutoksia, jotka katoavat jos siirryt sivulta pois. Haluatko jatkaa?");
        if (confirm) {
          resetEdited();
        }
        return confirm;
      }
    };
    const beforeWindowUnload = e => {
      e.preventDefault();
      e.returnValue = "";
      return "";
    };
    watchEffect(() => {
      loadPage(huonekorttiId.value);
    });
    watchEffect(() => {
      var _a, _b;
      if ((_a = huonekortti.value) === null || _a === void 0 ? void 0 : _a.vastuuyksikko) {
        vastuuyksikot.value = [(_b = huonekortti.value) === null || _b === void 0 ? void 0 : _b.vastuuyksikko];
      } else {
        vastuuyksikot.value = [];
      }
    });
    watchEffect(() => {
      var _a;
      hanke.value = (_a = hankeData.value) === null || _a === void 0 ? void 0 : _a.data;
    });
    watch(isEdited, () => {
      if (isEdited.value) {
        window.addEventListener("beforeunload", beforeWindowUnload);
      } else {
        window.removeEventListener("beforeunload", beforeWindowUnload);
      }
    });
    provide("huonekorttiId", huonekorttiId);
    provide(provideNimikkeet, nimikkeet);
    provide(provideHuonekortti, huonekortti);
    provide("provideTilat", tilat);
    provide("writingRights", writingRights);
    provide("huonekorttiPaakayttaja", paakayttaja);
    provide(provideHanke, hanke);
    provide(provideSelectedVastuuyksikot, vastuuyksikot);
    provide(provideHuonekorttiIsEdited, edited);
    return {
      kategoriaNimikeData,
      huonekortti,
      headerTitle,
      headerSubtitle,
      dataLoaded,
      showHanketiedot,
      showMuutokset,
      hanke,
      writingRights,
      liitteetCount,
      kommentitCount,
      closeHanketiedot,
      loadHanketiedot,
      liiteChangeClick,
      kommenttiChangeClick
    };
  }
});