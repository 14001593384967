import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useHuonekorttiTable } from './huonekorttiTable';
import huonekorttiTableStore from './huonekorttiTableStore';
import { defineComponent, ref } from 'vue';
import ComPlusButton from '@/components/common/buttons/ComPlusButton.vue';
import ComMinusButton from '@/components/common/buttons/ComMinusButton.vue';
import ComStatusTag from '@/components/common/ComStatusTag.vue';
export default defineComponent({
  components: {
    ComPlusButton,
    ComMinusButton,
    ComStatusTag
  },
  props: {
    huonekortit: {
      type: Object
    },
    selection: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const {
      formatTilat,
      huonekorttiIdsSelected
    } = useHuonekorttiTable();
    const setSelected = huonekorttiTableStore.methods.setSelected;
    const getSelected = huonekorttiTableStore.getters.getSelected;
    const multipleTableRef = ref();
    const getAllIdsFromPage = () => {
      var _a;
      return (_a = multipleTableRef.value) === null || _a === void 0 ? void 0 : _a.data.map(d => parseInt(d.id));
    };
    const selectAll = () => {
      const pIds = getAllIdsFromPage();
      const oIds = getSelected();
      if (pIds) {
        const nIds = oIds.concat(pIds);
        const set = new Set(nIds);
        setSelected([...set]);
      }
    };
    const unselectAll = () => {
      const pIds = getAllIdsFromPage();
      const oIds = getSelected();
      if (pIds) {
        setSelected(oIds.filter(i => pIds.indexOf(i) != -1 ? false : true));
      }
    };
    return {
      huonekorttiIdsSelected,
      multipleTableRef,
      selectAll,
      formatTilat,
      unselectAll
    };
  }
});