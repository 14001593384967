import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "el-input el-input--small"
};
const _hoisted_2 = {
  class: "el-input__wrapper"
};
const _hoisted_3 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, null, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Suodata"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("input", {
        class: "el-input__inner",
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] =
        //@ts-ignore
        (...args) => _ctx.filterChange && _ctx.filterChange(...args))
      }, null, 40, _hoisted_3)])])]),
      _: 1
    })]),
    _: 1
  });
}