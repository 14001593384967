import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComEmpty = _resolveComponent("ComEmpty");
  const _component_el_card = _resolveComponent("el-card");
  const _component_ComLoading = _resolveComponent("ComLoading");
  return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives(_createVNode(_component_el_card, null, _createSlots({
    default: _withCtx(() => [!_ctx.empty ? _renderSlot(_ctx.$slots, "default", {
      key: 0
    }) : (_openBlock(), _createBlock(_component_ComEmpty, {
      key: 1
    }))]),
    _: 2
  }, [_ctx.header && !_ctx.empty ? {
    name: "header",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "header")]),
    key: "0"
  } : undefined]), 1536), [[_vShow, !_ctx.loading]]), _withDirectives(_createVNode(_component_ComLoading, null, null, 512), [[_vShow, _ctx.loading]])], 64);
}