import kayttajaService from '@/methods/kayttajaService';
import kommenttiService from '@/methods/kommenttiService';
import { provideHasPaakayttajaRights } from '@/types/Symbols';
import { computed, defineComponent, inject, ref } from 'vue';
import ComDeleteButton from '../common/buttons/ComDeleteButton.vue';
export default defineComponent({
  props: {
    kommentti: {
      type: Object,
      required: true
    }
  },
  components: {
    ComDeleteButton
  },
  setup(props) {
    const getKayttaja = kayttajaService.getters.getKayttaja;
    const deleteKommentti = kommenttiService.methods.deleteKommentti;
    const hankePaakayttaja = inject(provideHasPaakayttajaRights, ref(false));
    const huonekorttiPaakayttaja = inject("huonekorttiPaakayttaja", ref(false));
    const kommentit = inject("kommentit");
    const {
      data: kayttaja
    } = getKayttaja();
    const hasRight = computed(() => {
      var _a;
      if ((hankePaakayttaja === null || hankePaakayttaja === void 0 ? void 0 : hankePaakayttaja.value) || huonekorttiPaakayttaja.value) {
        return true;
      }
      if (kayttaja.value && kayttaja.value.id == ((_a = props.kommentti.kayttaja) === null || _a === void 0 ? void 0 : _a.id)) {
        return true;
      } else {
        return false;
      }
    });
    const deleteKommenttiClick = async () => {
      const response = await deleteKommentti(props.kommentti.id);
      if (response) {
        const index = kommentit.value.findIndex(k => k.id == props.kommentti.id);
        kommentit.value.splice(index, 1);
      }
    };
    return {
      hasRight,
      deleteKommenttiClick
    };
  }
});