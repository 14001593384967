import { computed, inject, Ref, ref, watch, watchEffect } from 'vue'
import { HuonekorttiResponse } from '@/api/models/huonekortti-response';
import { TilanimikeResponse } from '@/api/models/tilanimike-response';
import { VastuuyksikkoResponse } from '@/api/models/vastuuyksikko-response';
import huonekorttiListingService from '@/methods/huonekorttiListingService';
import huonekorttiTableStore from './table/huonekorttiTableStore';
import useSWRV from 'swrv';
import Order from '@/types/OrderEnum';
import Paging from '@/types/Paging';
import HuonekorttiListingParams from '@/types/HuonekorttiListingParams';
import { HuonekorttiStatusResponse } from '@/api/models/huonekortti-status-response';
import HuonekorttiSort from '@/types/HuonekorttiSort';
import HuonekorttiSortEnum from '@/types/HuonekorttiSortEnum';
import { provideHankeHuonekorttiListingParams, provideSelectedVastuuyksikot, provideWritableVastuuyksikot } from '@/types/Symbols';
import HankeHuonekorttiListingQueryParams from '@/types/HankeHuonekorttiListingQueryParams';
import { useLocalStorage } from '@vueuse/core';

export function useHuonekorttiListing() {
    const vastuuyksikot = inject(provideSelectedVastuuyksikot)
    const hankeVastuuyksikot = inject("vastuuyksikot") as Ref<VastuuyksikkoResponse[]>
    const tilanimikkeet = inject("tilanimikkeet") as Ref<TilanimikeResponse[]>
    const queryParams = inject(provideHankeHuonekorttiListingParams)
    const writableVastuuyksikot = inject(provideWritableVastuuyksikot)
    const edit = ref<number | undefined>()
    const status = ref<HuonekorttiStatusResponse[]>()
    const { methods, getters, fetcher, options } = huonekorttiListingService
    const totalItems = ref<number>(0)
    const paging = computed({
        get: () =>  {
            const defaulted = {
                pageSize: 30,
                currentPage: 1
            } as Paging

            const storagePage = useLocalStorage('page', defaulted)

            if(queryParams?.value?.page) 
                return queryParams?.value?.page
            else 
                return storagePage.value
        },
        set: (val : Paging) => {
            if(queryParams) {
                if(!queryParams.value) {
                    queryParams.value = {} as HankeHuonekorttiListingQueryParams
                }

                queryParams.value.page = val
            }
        }    
})
    const filter = computed({
        get: () => queryParams?.value?.filter ?? "",
        set: (val : string) => {
            if(queryParams) {
                if(!queryParams.value) {
                    queryParams.value = {} as HankeHuonekorttiListingQueryParams
                }

                queryParams.value.filter = val
            }
        }
    })
    const sortOrder = computed(() => queryParams?.value?.sort ?? {
        order: Order.asc,
        prop: HuonekorttiSortEnum.id
    })
    const mutation = computed(() => getters.getMutate())
    const key = computed(() => {
        if(getters.getVastuuyksikot().length != 0) {
            const request : HuonekorttiListingParams = {
                vastuuyksikot: getters.getVastuuyksikot(),
                page: paging.value?.currentPage ?? 1,
                perPage: getters.getPerPage(),
                tilanimike: filter.value,
                tilanimikeTasmennys: filter.value,
                tilatunnus: filter.value,
                status: status.value,
                order: sortOrder.value.order,
                sort: sortOrder.value.prop
            }
    
            return methods.loadKey(request) 
        } 
        else {
            return undefined
        }
    })
    const { data, mutate } = useSWRV(() => key.value , fetcher, options)
    const loading = computed(() => {
        if(vastuuyksikot?.value?.length != 0 && !data.value) {
            return true
        }
        else {
            return false
        }
    })
    const huonekortit = computed(() => {
        if(data.value !== undefined) {
            return data.value.data
        }
        return undefined
    })

    const vastuuyksikkoIds = computed(() => {
        if(!vastuuyksikot) {
            return []
        }
        else {
            return vastuuyksikot?.value.map(v => v.id)
        }
    })

    const sortChanged = (val : any) => {
        if(queryParams) {
            if(!queryParams?.value) {
                queryParams.value = {} as HankeHuonekorttiListingQueryParams
            }

            const sort = {} as HuonekorttiSort

            sort.prop = val.prop
            sort.order = val.order

            queryParams.value.sort = sort
        }
    }

    const cancelEdit = (oldItem : HuonekorttiResponse, index : number) => {
        Object.assign(huonekortit.value![index],oldItem)
    }

    watch(data,(n) => {
        if(n?.headers) {
            totalItems.value = parseInt(n?.headers["page-total-count"])
        }
        else {
            totalItems.value = 0
        }
    })

    watch(mutation,() => {
        if(mutation.value) {
            mutate()
            methods.setMutate(false)
        }
    })

    watch(filter,(n) => {
        methods.setCurrentPage(1)
        methods.setAllFilters(n)
    })

    watchEffect(() => {
        if(vastuuyksikot?.value.length === 0) {
            data.value = undefined
        }

        huonekorttiTableStore.methods.setSelected([])
        methods.setVastuuyksikot(vastuuyksikkoIds.value)
    })

    watchEffect(() => {
        if(paging.value) {
            methods.setPerPage(paging.value.pageSize)
        }
    })

    return { 
        huonekortit,
        edit,
        tilanimikkeet,
        hankeVastuuyksikot,
        totalItems,
        filter,
        writableVastuuyksikot,
        loading,
        status,
        paging,
        cancelEdit,
        sortChanged,
    }
}