import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24
    }, {
      default: _withCtx(() => [_createTextVNode(" Olet tuomassa " + _toDisplayString(_ctx.maara) + " huonekorttia vastuuyksikköön " + _toDisplayString(_ctx.vastuuyksikko), 1)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    justify: "end"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      push: 18
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        size: "small",
        type: "danger",
        class: "button",
        onClick: _ctx.peruuta
      }, {
        default: _withCtx(() => [_createTextVNode("Peruuta")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        size: "small",
        type: "success",
        class: "button",
        onClick: _ctx.hyvaksy
      }, {
        default: _withCtx(() => [_createTextVNode("Tuo huonekortit")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  })]);
}