import hankeService from '@/methods/hankeService';
import { ElMessage } from 'element-plus';
import { defineComponent } from 'vue';
import ComHanketiedotVaatimuksetField from './ComHanketiedotVaatimuksetField.vue';
export default defineComponent({
  components: {
    ComHanketiedotVaatimuksetField
  },
  props: {
    hankeVaatimusKategoria: {
      type: Object,
      required: true
    },
    hankeVaatimus: {
      type: Object,
      required: false
    },
    hankeId: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  emits: ["add", "update"],
  setup(props, {
    emit
  }) {
    const addHankeVaatimus = hankeService.methods.addHankeVaatimus;
    const updateHankeVaatimus = hankeService.methods.updateHankeVaatimus;
    const saveVaatimusClick = async val => {
      if (!props.hankeVaatimus) {
        const request = {};
        request.vaatimus = val;
        request.hanke = {
          id: props.hankeId
        };
        request.kategoria = {
          id: props.hankeVaatimusKategoria.id
        };
        const response = await addHankeVaatimus(request);
        if (response) {
          ElMessage.success("Vaatimus tallennettu!");
          emit("add", response);
        }
      } else {
        const request = {};
        request.vaatimus = val;
        const response = await updateHankeVaatimus(props.hankeVaatimus.id, request);
        if (response) {
          ElMessage.success("Vaatimus tallennettu!");
          emit("update", response);
        }
      }
    };
    return {
      saveVaatimusClick
    };
  }
});