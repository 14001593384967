import { defineComponent, ref } from 'vue';
import ComAttatchmenButton from '../common/buttons/ComAttatchmentButton.vue';
import ComLiiteList from './ComLiiteList.vue';
export default defineComponent({
  components: {
    ComAttatchmenButton,
    ComLiiteList
  },
  props: {
    viite: {
      type: Number,
      required: true
    },
    tyyppi: {
      type: Number,
      required: true
    }
  },
  emit: ["liiteChange"],
  setup(props, {
    emit
  }) {
    const show = ref(false);
    const liiteChangeClick = val => {
      emit("liiteChange", val);
    };
    return {
      show,
      liiteChangeClick
    };
  }
});