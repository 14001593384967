import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_com_side_menu = _resolveComponent("com-side-menu");
  const _component_el_aside = _resolveComponent("el-aside");
  const _component_com_header = _resolveComponent("com-header");
  const _component_el_header = _resolveComponent("el-header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [_ctx.isAuthenticated ? (_openBlock(), _createBlock(_component_el_aside, {
      key: 0,
      class: "sidebar"
    }, {
      default: _withCtx(() => [_createVNode(_component_com_side_menu)]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_container, null, {
      default: _withCtx(() => [_ctx.isAuthenticated ? (_openBlock(), _createBlock(_component_el_header, {
        key: 0
      }, {
        default: _withCtx(() => [_createVNode(_component_com_header)]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_el_main, {
        class: "main"
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}