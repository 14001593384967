import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComPageHeader = _resolveComponent("ComPageHeader");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  const _component_ComHuonekortit = _resolveComponent("ComHuonekortit");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_ComTilat = _resolveComponent("ComTilat");
  const _component_ComHanketiedot = _resolveComponent("ComHanketiedot");
  const _component_ComRaportointi = _resolveComponent("ComRaportointi");
  const _component_ComOikeudet = _resolveComponent("ComOikeudet");
  const _component_com_mallihuonekortit = _resolveComponent("com-mallihuonekortit");
  const _component_ComTuoHuonekortteja = _resolveComponent("ComTuoHuonekortteja");
  const _component_ComRoskakori = _resolveComponent("ComRoskakori");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_el_card = _resolveComponent("el-card");
  const _component_ComLoading = _resolveComponent("ComLoading");
  const _component_ComCard = _resolveComponent("ComCard");
  return _openBlock(), _createElementBlock("div", null, [_ctx.hanke !== undefined ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_ComPageHeader, {
    contentTitle: _ctx.hanke.nimi
  }, null, 8, ["contentTitle"]), _createVNode(_component_el_card, null, {
    default: _withCtx(() => [_createVNode(_component_el_form, null, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "Vastuuyksikkö"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_select, {
          modelValue: _ctx.vastuuyksikkoIds,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.vastuuyksikkoIds = $event),
          multiple: "",
          style: {
            "width": "100%"
          },
          placeholder: "Valitse"
        }, {
          header: _withCtx(() => [_createVNode(_component_el_button, {
            size: "small",
            onClick: _ctx.kaikkiVastuuyksikotClick
          }, {
            default: _withCtx(() => [_createTextVNode("Valitse kaikki")]),
            _: 1
          }, 8, ["onClick"])]),
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hankeVastuuyksikot, vastuuyksikko => {
            return _openBlock(), _createBlock(_component_el_option, {
              key: vastuuyksikko.id,
              label: vastuuyksikko.nimi,
              value: vastuuyksikko.id
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_tabs, {
      class: "hanke_tab_menu",
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.activeTab = $event)
    }, {
      default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
        label: "Huonekortit",
        name: 0,
        lazy: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_ComHuonekortit)]),
        _: 1
      }), _createVNode(_component_el_tab_pane, {
        label: "Tilat",
        name: 1,
        lazy: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_ComTilat)]),
        _: 1
      }), _createVNode(_component_el_tab_pane, {
        label: "Tiedot",
        name: 2,
        lazy: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_ComHanketiedot, {
          huonekortti: false,
          editable: _ctx.hankePaakayttaja
        }, null, 8, ["editable"])]),
        _: 1
      }), _createVNode(_component_el_tab_pane, {
        label: "Raportointi",
        name: 3,
        lazy: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_ComRaportointi, {
          vastuuyksikot: _ctx.vastuuyksikkoIds
        }, null, 8, ["vastuuyksikot"])]),
        _: 1
      }), _ctx.hankePaakayttaja ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 0,
        label: "Käyttäjät",
        name: 4,
        lazy: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_ComOikeudet)]),
        _: 1
      })) : _createCommentVNode("", true), _ctx.hankePaakayttaja ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 1,
        label: "Mallihuonekortit",
        name: 5,
        lazy: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_com_mallihuonekortit)]),
        _: 1
      })) : _createCommentVNode("", true), _ctx.hankePaakayttaja && _ctx.activeTab == 0 ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 2,
        disabled: "",
        name: "tuo"
      }, {
        label: _withCtx(() => [_createVNode(_component_ComTuoHuonekortteja)]),
        _: 1
      })) : _createCommentVNode("", true), _ctx.hankePaakayttaja && _ctx.activeTab == 0 ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 3,
        disabled: "",
        name: "roskakori"
      }, {
        label: _withCtx(() => [_createVNode(_component_ComRoskakori)]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["modelValue"])]),
    _: 1
  })], 64)) : (_openBlock(), _createBlock(_component_ComCard, {
    key: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_ComLoading)]),
    _: 1
  }))]);
}