import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createBlock(_component_el_table, {
    data: _ctx.vastuuyksikot
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      label: "Vastuuyksikkö",
      prop: "nimi"
    })]),
    _: 1
  }, 8, ["data"]);
}