import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  style: {
    "margin-right": "5px"
  }
};
const _hoisted_2 = {
  style: {
    "float": "right"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComLoading = _resolveComponent("ComLoading");
  const _component_ComEmpty = _resolveComponent("ComEmpty");
  const _component_ComDeleteKommentti = _resolveComponent("ComDeleteKommentti");
  const _component_ComCard = _resolveComponent("ComCard");
  const _component_ComAddKommentti = _resolveComponent("ComAddKommentti");
  return _openBlock(), _createElementBlock(_Fragment, null, [!_ctx.kommentit ? (_openBlock(), _createBlock(_component_ComLoading, {
    key: 0
  })) : _ctx.kommentit.length == 0 ? (_openBlock(), _createBlock(_component_ComEmpty, {
    key: 1
  })) : (_openBlock(true), _createElementBlock(_Fragment, {
    key: 2
  }, _renderList(_ctx.kommentit, (kommentti, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "card-margin-bottom"
    }, [_createVNode(_component_ComCard, {
      header: ""
    }, {
      header: _withCtx(() => {
        var _a, _b;
        return [_createElementVNode("span", _hoisted_1, _toDisplayString(kommentti.aika), 1), _createElementVNode("label", null, _toDisplayString(((_a = kommentti.kayttaja) === null || _a === void 0 ? void 0 : _a.etunimi) + " " + ((_b = kommentti.kayttaja) === null || _b === void 0 ? void 0 : _b.sukunimi)), 1), _createElementVNode("span", _hoisted_2, [_createVNode(_component_ComDeleteKommentti, {
          kommentti: kommentti
        }, null, 8, ["kommentti"])])];
      }),
      default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(kommentti.kommentti), 1)]),
      _: 2
    }, 1024)]);
  }), 128)), _ctx.kommentit ? (_openBlock(), _createBlock(_component_ComAddKommentti, {
    key: 3,
    tyyppi: _ctx.tyyppi,
    viite: _ctx.viite
  }, null, 8, ["tyyppi", "viite"])) : _createCommentVNode("", true)], 64);
}