import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tag = _resolveComponent("el-tag");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_tag, {
    class: "oikeus-tag",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.changeOikeus(_ctx.oikeus, _ctx.oikeudet.Luku)),
    size: "small",
    type: _ctx.oikeus && _ctx.oikeus.luku ? 'success' : 'danger',
    style: {
      "margin-right": "3px"
    }
  }, {
    default: _withCtx(() => [_createTextVNode("L")]),
    _: 1
  }, 8, ["type"]), _createVNode(_component_el_tag, {
    class: "oikeus-tag",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.changeOikeus(_ctx.oikeus, _ctx.oikeudet.Kirjoitus)),
    size: "small",
    type: _ctx.oikeus && _ctx.oikeus.kirjoitus ? 'success' : 'danger',
    style: {
      "margin-right": "3px"
    }
  }, {
    default: _withCtx(() => [_createTextVNode("K")]),
    _: 1
  }, 8, ["type"]), _createVNode(_component_el_tag, {
    class: "oikeus-tag",
    onClick: _cache[2] || (_cache[2] = $event => _ctx.changeOikeus(_ctx.oikeus, _ctx.oikeudet.Raportointi)),
    size: "small",
    type: _ctx.oikeus && _ctx.oikeus.raportointi ? 'success' : 'danger'
  }, {
    default: _withCtx(() => [_createTextVNode("R")]),
    _: 1
  }, 8, ["type"])], 64);
}