import "core-js/modules/es.array.push.js";
import { defineComponent, inject, ref, reactive, watch, computed, watchEffect } from 'vue';
import ComPlusButton from '../../common/buttons/ComPlusButton.vue';
import ComMinusButton from '../../common/buttons/ComMinusButton.vue';
import huonekorttiAPI from '@/apis/huonekorttiAPI';
import { ElMessage } from 'element-plus';
import huonekorttiListing from '@/methods/huonekorttiListingService';
import { provideHanke, provideWritableVastuuyksikot } from '@/types/Symbols';
import hankeService from '@/methods/hankeService';
import ComMallihuonekortitSelect from '@/components/mallihuonekortit/ComMallihuonekortitSelect.vue';
const {
  methods
} = huonekorttiListing;
export default defineComponent({
  components: {
    ComPlusButton,
    ComMinusButton,
    ComMallihuonekortitSelect
  },
  setup() {
    const getTilanumero = hankeService.getters.getTilanumero;
    const increaseTilanumero = hankeService.methods.increaseTilanumero;
    const vastuuyksikot = inject("vastuuyksikot");
    const tilanimikkeet = inject("tilanimikkeet");
    const hanke = inject(provideHanke);
    const writableVastuuyksikot = inject(provideWritableVastuuyksikot);
    const hankeId = inject("hankeId");
    const tilanumero = computed(() => getTilanumero(hankeId.value));
    const tilanumeroTemp = ref(0);
    const addHuonekorttiRef = ref();
    const tilat = ref([]);
    const mallihuonekortti = ref();
    const huonekortti = reactive({
      toiminta: "",
      vastuuyksikko: {
        id: undefined
      },
      tilanimike: {
        id: undefined
      },
      tilat: tilat.value
    });
    const rules = ref({
      tilanimike: [{
        required: true,
        trigger: "change",
        type: "object",
        fields: {
          id: {
            type: "number",
            required: true,
            message: "Valitse tilanimike!"
          }
        }
      }],
      toiminta: [{
        required: false,
        max: 50
      }],
      vastuuyksikko: [{
        required: true,
        trigger: "change",
        type: "object",
        fields: {
          id: {
            type: "number",
            required: true,
            message: "Valitse vastuuyksikkö!"
          }
        }
      }]
    });
    const visible = ref(false);
    const addTila = () => {
      tilanumeroTemp.value != undefined ? tilanumeroTemp.value++ : tilanumeroTemp.value = 1;
      const a = tilanumeroTemp.value;
      const n = a.toString();
      tilat.value.push({
        tilatunnus: n,
        pintaAla: 0,
        vastuuyksikko: {
          id: huonekortti.vastuuyksikko.id
        }
      });
    };
    const addHuonekortti = async () => {
      if (addHuonekorttiRef.value != undefined) {
        addHuonekorttiRef.value.validate(async valid => {
          var _a;
          if (!valid) {
            return false;
          } else {
            huonekortti.tilat = tilat.value;
            let response = undefined;
            if (!mallihuonekortti.value) {
              response = await huonekorttiAPI.addHuonekortti(huonekortti);
            } else {
              response = await huonekorttiAPI.addHuonekorttiFromMallihuonekortti(huonekortti.vastuuyksikko.id, mallihuonekortti.value.id, tilat.value);
            }
            if (response) {
              ElMessage.success("Huonekortti lisätty!");
              resetFields();
              methods.setMutate(true);
              (_a = huonekortti.tilat) === null || _a === void 0 ? void 0 : _a.forEach(() => increaseTilanumero(hankeId.value));
            } else {
              ElMessage.error("Huonekortin lisäys ei onnistunut!");
            }
          }
        });
      }
    };
    const removeTila = index => {
      tilat.value.splice(index, 1);
    };
    const resetFields = () => {
      if (addHuonekorttiRef.value) {
        addHuonekorttiRef.value.resetFields();
        huonekortti.toiminta = "";
        huonekortti.vastuuyksikko = {
          id: undefined
        };
        huonekortti.tilanimike = {
          id: undefined
        };
        mallihuonekortti.value = undefined;
        tilat.value = [];
        addTila();
      }
    };
    const mallihuonekorttiSelected = val => {
      mallihuonekortti.value = val;
      huonekortti.toiminta = val ? val.toiminta : "";
    };
    watchEffect(() => {
      var _a;
      tilanumeroTemp.value = (_a = tilanumero.value) !== null && _a !== void 0 ? _a : 0;
    });
    watch(() => huonekortti.vastuuyksikko.id, n => {
      if (tilat.value) {
        tilat.value.forEach(t => {
          if (t.vastuuyksikko) {
            t.vastuuyksikko.id = n;
          }
        });
      }
    });
    watch(hankeId, () => {
      var _a;
      tilanumeroTemp.value = (_a = tilanumero.value) !== null && _a !== void 0 ? _a : 0;
      resetFields();
    });
    addTila();
    return {
      tilat,
      huonekortti,
      mallihuonekortti,
      vastuuyksikot,
      tilanimikkeet,
      rules,
      addHuonekorttiRef,
      writableVastuuyksikot,
      visible,
      hanke,
      addTila,
      removeTila,
      addHuonekortti,
      mallihuonekorttiSelected
    };
  }
});