/* tslint:disable */
/* eslint-disable */
/**
 * Vahti API
 * Vahti vaatimustenhallintajärjestelmän API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jani.saine@tietosund.fi
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Muutoslogi tapahtuman tyyppi
 * @export
 * @enum {string}
 */

export enum HuonekorttiHistoriaTyyppi {
    Lisays = 'Lisays',
    Poisto = 'Poisto',
    Muutos = 'Muutos'
}


