import { useAuth0 } from '@/auth/auth0-plugin';
import kayttajaService from '@/methods/kayttajaService';
import { ElMessage } from 'element-plus';
import { defineComponent, ref } from 'vue';
import ComCancelButton from '../common/buttons/ComCancelButton.vue';
import ComSuccessButton from '../common/buttons/ComSuccessButton.vue';
import ComCard from '../common/ComCard.vue';
export default defineComponent({
  components: {
    ComCard,
    ComSuccessButton,
    ComCancelButton
  },
  setup() {
    const {
      consestGDPR
    } = kayttajaService.methods;
    const auth0 = useAuth0();
    const hyvaksy = ref(false);
    const logoutUrl = process.env.VUE_APP_AUTH0_LOGOUT_URL;
    const hyvaksyClick = async () => {
      if (!hyvaksy.value) {
        ElMessage.warning("Sinun tulee hyväksyä ehdot jatkaaksesi!");
      } else {
        if (await consestGDPR()) {
          window.location.reload();
        }
      }
    };
    const hylkaaClick = () => {
      if (!auth0) {
        return;
      }
      auth0.logout({
        logoutParams: {
          returnTo: logoutUrl
        }
      });
    };
    return {
      hyvaksyClick,
      hylkaaClick,
      hyvaksy
    };
  }
});