import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "float": "right"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  const _component_ComLiitteet = _resolveComponent("ComLiitteet");
  const _component_el_badge = _resolveComponent("el-badge");
  const _component_ComKommentointi = _resolveComponent("ComKommentointi");
  const _component_ComHuonekorttiInfoDropDownButton = _resolveComponent("ComHuonekorttiInfoDropDownButton");
  const _component_el_space = _resolveComponent("el-space");
  const _component_ComPageHeader = _resolveComponent("ComPageHeader");
  const _component_ComToimintatiedot = _resolveComponent("ComToimintatiedot");
  const _component_ComSuunnittelutiedot = _resolveComponent("ComSuunnittelutiedot");
  const _component_ComHanketiedot = _resolveComponent("ComHanketiedot");
  const _component_el_drawer = _resolveComponent("el-drawer");
  const _component_ComHistoria = _resolveComponent("ComHistoria");
  const _component_ComLoading = _resolveComponent("ComLoading");
  const _component_ComCard = _resolveComponent("ComCard");
  return _ctx.dataLoaded ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_ComPageHeader, {
    contentTitle: _ctx.headerTitle,
    contentSubtitle: _ctx.headerSubtitle
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_ctx.huonekortti ? (_openBlock(), _createBlock(_component_el_space, {
      key: 0
    }, {
      default: _withCtx(() => [_createVNode(_component_el_badge, {
        value: _ctx.liitteetCount,
        hidden: !_ctx.liitteetCount
      }, {
        default: _withCtx(() => [_createVNode(_component_ComLiitteet, {
          viite: _ctx.huonekortti.id,
          tyyppi: 1,
          onLiiteChange: _ctx.liiteChangeClick
        }, null, 8, ["viite", "onLiiteChange"])]),
        _: 1
      }, 8, ["value", "hidden"]), _createVNode(_component_el_badge, {
        value: _ctx.kommentitCount,
        hidden: !_ctx.kommentitCount
      }, {
        default: _withCtx(() => [_createVNode(_component_ComKommentointi, {
          viite: _ctx.huonekortti.id,
          tyyppi: 1,
          onKommenttiChange: _ctx.kommenttiChangeClick
        }, null, 8, ["viite", "onKommenttiChange"])]),
        _: 1
      }, 8, ["value", "hidden"]), _createVNode(_component_ComHuonekorttiInfoDropDownButton, {
        onShowMuutokset: _cache[0] || (_cache[0] = $event => _ctx.showMuutokset = !_ctx.showMuutokset),
        onShowTiedot: _cache[1] || (_cache[1] = $event => _ctx.showHanketiedot = !_ctx.showHanketiedot)
      })]),
      _: 1
    })) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["contentTitle", "contentSubtitle"]), _createVNode(_component_ComToimintatiedot, {
    data: _ctx.huonekortti,
    class: "card-margin-bottom"
  }, null, 8, ["data"]), _createVNode(_component_ComSuunnittelutiedot, {
    kategoriat: (_a = _ctx.kategoriaNimikeData) !== null && _a !== void 0 ? _a : []
  }, null, 8, ["kategoriat"]), _ctx.showHanketiedot ? (_openBlock(), _createBlock(_component_el_drawer, {
    key: 0,
    modelValue: _ctx.showHanketiedot,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showHanketiedot = $event),
    class: "hanketiedotDrawer",
    "append-to-body": "",
    size: "50%",
    title: "Tiedot",
    "destroy-on-close": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_ComHanketiedot, {
      huonekortti: true,
      editable: false
    })]),
    _: 1
  }, 8, ["modelValue"])) : _createCommentVNode("", true), _ctx.showMuutokset ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_ctx.huonekortti && _ctx.huonekortti.status == 'Hyvaksytty' ? (_openBlock(), _createBlock(_component_el_drawer, {
    key: 0,
    modelValue: _ctx.showMuutokset,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showMuutokset = $event),
    size: "50%",
    title: "Historia",
    "destroy-on-close": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_ComHistoria, {
      huonekortti: _ctx.huonekortti
    }, null, 8, ["huonekortti"])]),
    _: 1
  }, 8, ["modelValue"])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createBlock(_component_ComCard, {
    key: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_ComLoading)]),
    _: 1
  }));
}