import { reactive, readonly, computed} from "vue";
import hankeApi from "@/apis/hankeApi";
import { HankeResponse } from "@/api/models/hanke-response";
import useSWRV from "swrv";

const state = reactive ({
    vastuuyksikot: [] as number[],
    arkistoidut: undefined as boolean | undefined,
    mutate: false
})

const url = "hankkeet"
const key = computed(() => {
    return getters.getKey()
})
const options = {
    dedupingInterval: 900000,
    revalidateOnFocus: true,
}

const methods = {
    setMutate(val : boolean) : void {
        state.mutate = val
    },
    setArkistoidut(val : boolean) : void {
        state.arkistoidut = val
    },
    loadHankkeet() : Promise<HankeResponse[]> {
        return hankeApi.getHankkeet("hankkeet").then(response => response)
    }
}

const getters = {
    getUrl() : string {
        return url
    },
    getArkistoitu() : boolean | undefined {
        return state.arkistoidut
    },
    getKey(arkistoitu? : boolean) : string {
        const params = new URLSearchParams()

        if(arkistoitu) {
            params.set("arkistoidut", arkistoitu.toString())
        }
        else if(state.arkistoidut != undefined) {
            params.set("arkistoidut", state.arkistoidut.toString())
        }

        const urlString = url + "?" + params.toString()
        
        return urlString
    },
    getMutate() : boolean {
        return state.mutate
    }
}

const fetcher = (url : string) => {
    return hankeApi.getHankkeet(url)
}

const useHankeListing = () => {
    const { data, error, isValidating, mutate} = useSWRV(() => key.value, fetcher, options)

    return {
        data,
        error,
        isValidating,
        mutate
    }
}

export default {
    state : readonly(state),
    methods,
    getters,
    fetcher,
    useHankeListing,
    key,
    options
}