import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6da41a22"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "node_title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComSideMenuVastuuyksikko = _resolveComponent("ComSideMenuVastuuyksikko");
  const _component_ComTooltip = _resolveComponent("ComTooltip");
  const _component_el_tree = _resolveComponent("el-tree");
  return _openBlock(), _createBlock(_component_el_tree, {
    data: _ctx.hankkeet,
    "node-key": "id",
    class: "side-menu-tree",
    "highlight-current": false
  }, {
    default: _withCtx(({
      node,
      data
    }) => [node.level === 2 ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      class: _normalizeClass({
        'arkistoitu-text': node.parent.data.arkistoitu
      }),
      style: {
        "overflow": "hidden"
      }
    }, [_createVNode(_component_ComSideMenuVastuuyksikko, {
      data: data,
      checkbox: true
    }, null, 8, ["data"])], 2)) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass(["side-menu-hanke", {
        'arkistoitu-text': data.arkistoitu
      }])
    }, [_createVNode(_component_ComTooltip, {
      content: data.nimi
    }, {
      default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString(data.nimi), 1)]),
      _: 2
    }, 1032, ["content"])], 2))]),
    _: 1
  }, 8, ["data"]);
}