import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-35455b68"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComLoading = _resolveComponent("ComLoading");
  const _component_ComFilterInput = _resolveComponent("ComFilterInput");
  const _component_el_col = _resolveComponent("el-col");
  const _component_ComPagination = _resolveComponent("ComPagination");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_link = _resolveComponent("el-link");
  const _component_router_link = _resolveComponent("router-link");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  return _ctx.loading ? (_openBlock(), _createBlock(_component_ComLoading, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 6
    }, {
      default: _withCtx(() => [_createVNode(_component_ComFilterInput, {
        modelValue: _ctx.filter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filter = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 18
    }, {
      default: _withCtx(() => [_createVNode(_component_ComPagination, {
        total: _ctx.pageTotal,
        paging: _ctx.paging,
        style: {
          "float": "right"
        },
        onPageChange: _cache[1] || (_cache[1] = val => _ctx.paging = val)
      }, null, 8, ["total", "paging"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_table, {
    data: _ctx.tilat,
    "show-summary": "",
    onSortChange: _ctx.sortChanged,
    "summary-method": _ctx.getSum,
    "default-sort": _ctx.sort
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      prop: "tilatunnus",
      label: "Tilatunnus",
      sortable: "custom",
      width: "120"
    }, {
      default: _withCtx(scope => [_createVNode(_component_router_link, {
        to: {
          name: 'Huonekortti',
          params: {
            huonekorttiId: scope.row.huonekortti.id
          }
        },
        custom: ""
      }, {
        default: _withCtx(({
          href,
          navigate
        }) => [_createVNode(_component_el_link, {
          type: "primary",
          href: href,
          onClick: navigate
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.tilatunnus), 1)]),
          _: 2
        }, 1032, ["href", "onClick"])]),
        _: 2
      }, 1032, ["to"])]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      prop: "pintaAla",
      label: "Pinta-ala",
      sortable: "custom",
      width: "120"
    }), _createVNode(_component_el_table_column, {
      prop: "huonekortti.tilanimike.nimi",
      label: "Tilanimike",
      sortable: "custom",
      width: "200"
    }), _createVNode(_component_el_table_column, {
      prop: "vastuuyksikko.nimi",
      label: "Vastuuyksikkö",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }, {
      default: _withCtx(scope => [_createTextVNode(_toDisplayString(_ctx.vastuuyksikko(scope.row.vastuuyksikko.id)), 1)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      prop: "huonekortti.toiminta",
      label: "Täsmennys",
      sortable: "custom",
      "show-overflow-tooltip": ""
    })]),
    _: 1
  }, 8, ["data", "onSortChange", "summary-method", "default-sort"])]));
}