import { computed, defineComponent, ref, watch } from 'vue';
import huonekorttiListing from '@/methods/huonekorttiListingService';
import huonekortti from '@/methods/huonekorttiService';
import useSWRV from 'swrv';
import ComEmpty from '@/components/common/ComEmpty.vue';
import { Document } from '@element-plus/icons-vue';
import ComTooltip from '@/components/common/ComTooltip.vue';
import sideMenuCheckboxService from '@/methods/sideMenuCheckboxService';
export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    filter: {
      type: String,
      required: false,
      default: ""
    },
    checkbox: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['totalCount'],
  components: {
    ComEmpty,
    Document,
    ComTooltip
  },
  setup(props, context) {
    const {
      fetcher,
      methods,
      options
    } = huonekorttiListing;
    const {
      methods: huonekorttiMethods
    } = huonekortti;
    const perPage = 30;
    const huonekortit = ref([]);
    const loading = ref(true);
    const formatTilat = huonekorttiMethods.formatTilat;
    const checked = ref([]);
    const key = computed(() => {
      const request = {
        vastuuyksikot: [props.id],
        page: props.page,
        perPage: perPage,
        tilanimike: props.filter,
        tilanimikeTasmennys: props.filter,
        tilatunnus: props.filter
      };
      return methods.loadKey(request);
    });
    const {
      data
    } = useSWRV(() => key.value, true && fetcher, options);
    watch(data, n => {
      if (n) {
        huonekortit.value = n.data;
        const total = parseInt(n.headers["page-total-count"]);
        context.emit('totalCount', total, perPage);
      }
      loading.value = false;
    });
    watch(checked, (n, o) => {
      if (n.length > o.length) {
        sideMenuCheckboxService.methods.add(n.filter(n => !o.includes(n))[0]);
      } else if (o.length > n.length) {
        sideMenuCheckboxService.methods.remove(o.filter(o => !n.includes(o))[0]);
      }
    });
    const formatLink = val => {
      let tilat = "";
      if (val.tilat) {
        tilat = formatTilat(val.tilat);
      }
      if (val && val.tilanimike) {
        return `${val.tilanimike.nimi} (${tilat})`;
      } else {
        return '';
      }
    };
    return {
      huonekortit,
      loading,
      checked,
      formatLink
    };
  }
});