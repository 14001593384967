import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "oikeus-tag-wrapper"
};
const _hoisted_2 = {
  class: "oikeus-tag-wrapper"
};
const _hoisted_3 = {
  class: "oikeus-tag-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tag = _resolveComponent("el-tag");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_tag, {
    class: "oikeus-tag",
    type: 'success'
  }, {
    default: _withCtx(() => [_createTextVNode("Luku")]),
    _: 1
  })]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tag, {
    class: "oikeus-tag",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.oikeusClick('kirjoitus')),
    type: _ctx.oikeudet.kirjoitus ? 'success' : 'danger'
  }, {
    default: _withCtx(() => [_createTextVNode("Kirjoitus")]),
    _: 1
  }, 8, ["type"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_tag, {
    class: "oikeus-tag",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.oikeusClick('raportointi')),
    type: _ctx.oikeudet.raportointi ? 'success' : 'danger'
  }, {
    default: _withCtx(() => [_createTextVNode("Raportointi")]),
    _: 1
  }, 8, ["type"])])], 64);
}