
import huonekorttiAPI from "@/apis/huonekorttiAPI";
import useSWRV from "swrv";

const key = "tilanimikkeet"
const fetcher = () => {
    return huonekorttiAPI.getTilanimikkeet()
}
const options = {
    dedupingInterval: 36000000,
    revalidateOnFocus: false,
}

const methods = {
    getTilanimikkeet() {
        return useSWRV(key, fetcher, options)
    }    
}

export default {
    methods
}

