import { KommenttiRequest } from "@/api/models/kommentti-request";
import kommentitApi from "@/apis/kommentitApi";
import kommenttiTyyppi from "@/types/Kommentti";
import { ElMessage } from "element-plus";
import useSWRV from "swrv";
import { ref, computed } from "vue";

const methods = {
    getKommentit(viite : number, tyyppi : kommenttiTyyppi) {
        const key = computed(() => "/kommentit/?" + new URLSearchParams({viite : viite.toString(), tyyppi : tyyppi.toString()}) )
        const fetcher = (key : string, viite : number, tyyppi : kommenttiTyyppi) => {
            return kommentitApi.getKommentit(viite, tyyppi)
        }

        const { data, error } = useSWRV([key, viite, tyyppi], fetcher)

        if(error.value) {
            ElMessage.error("Virhe kommenttien latauksessa!")
        }
            
        return { data, error }
    },
    async addKommentti(request : KommenttiRequest) {
        if(request.kommentti && request.kommentti.trim() != "") {
            const response = await kommentitApi.addKommentti(request)
            
            if(response) {
                return response
            }
            else {
                ElMessage.error("Virhe kommentin lisäyksessä!")
            }
        }
        else {
            ElMessage.error("Kirjoita kommentti!")
        }
    },
    async deleteKommentti(id : number) {
        const response = ref(await kommentitApi.deleteKommentti(id))

        if(response.value) {
            return response
        }
        else {
            ElMessage.error("Virhe kommentin poistossa!")
        }
    }
}

export default {
    methods
}