import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComFilterInput = _resolveComponent("ComFilterInput");
  const _component_el_col = _resolveComponent("el-col");
  const _component_ComPagination = _resolveComponent("ComPagination");
  const _component_el_row = _resolveComponent("el-row");
  const _component_ComHuonekorttiTable = _resolveComponent("ComHuonekorttiTable");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 6
    }, {
      default: _withCtx(() => [_createVNode(_component_ComFilterInput, {
        modelValue: _ctx.filter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filter = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 18
    }, {
      default: _withCtx(() => [_createVNode(_component_ComPagination, {
        total: _ctx.totalItems,
        onPageChange: _cache[1] || (_cache[1] = val => _ctx.paging = val),
        style: {
          "float": "right"
        },
        paging: _ctx.paging
      }, null, 8, ["total", "paging"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_ComHuonekorttiTable, {
    selection: true,
    huonekortit: _ctx.huonekortit
  }, null, 8, ["huonekortit"])], 64);
}