import ComLoginButton from '@/components/common/buttons/ComLoginButton.vue';
import { defineComponent, onMounted } from 'vue';
export default defineComponent({
  components: {
    ComLoginButton
  },
  name: 'KirjauduView',
  setup() {
    onMounted(() => {
      return;
    });
    return {};
  }
});