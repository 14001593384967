import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  "element-loading-background": "transparent",
  style: {
    "background-color": "transparent",
    "min-height": "50px"
  }
};
const _hoisted_2 = {
  style: {
    "display": "inline-block"
  }
};
const _hoisted_3 = {
  class: "side-menu-huonekortti-subtext"
};
const _hoisted_4 = {
  style: {
    "display": "inline-block"
  }
};
const _hoisted_5 = {
  class: "side-menu-huonekortti-subtext"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComEmpty = _resolveComponent("ComEmpty");
  const _component_ComTooltip = _resolveComponent("ComTooltip");
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
  const _component_Document = _resolveComponent("Document");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_link = _resolveComponent("el-link");
  const _component_router_link = _resolveComponent("router-link");
  const _directive_loading = _resolveDirective("loading");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.huonekortit.length === 0 && !_ctx.loading ? (_openBlock(), _createBlock(_component_ComEmpty, {
    key: 0
  })) : _ctx.checkbox ? (_openBlock(), _createBlock(_component_el_checkbox_group, {
    key: 1,
    modelValue: _ctx.checked,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.checked = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.huonekortit, huonekortti => {
      return _openBlock(), _createElementBlock("div", {
        key: huonekortti.id,
        class: "side-menu-huonekortti"
      }, [_createVNode(_component_el_checkbox, {
        label: huonekortti.id
      }, {
        default: _withCtx(() => [_createVNode(_component_ComTooltip, {
          content: _ctx.formatLink(huonekortti)
        }, {
          default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx.formatLink(huonekortti)), 1)]),
          _: 2
        }, 1032, ["content"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(huonekortti.toiminta), 1)])]),
        _: 2
      }, 1032, ["label"])]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"])) : (_openBlock(true), _createElementBlock(_Fragment, {
    key: 2
  }, _renderList(_ctx.huonekortit, huonekortti => {
    return _openBlock(), _createElementBlock("div", {
      key: huonekortti.id,
      class: "side-menu-huonekortti"
    }, [_createVNode(_component_el_icon, {
      size: "36px",
      class: "side-menu-huonekortti-icon"
    }, {
      default: _withCtx(() => [_createVNode(_component_Document)]),
      _: 1
    }), _createElementVNode("div", null, [_createVNode(_component_router_link, {
      to: {
        name: 'Huonekortti',
        params: {
          huonekorttiId: huonekortti.id
        }
      },
      custom: ""
    }, {
      default: _withCtx(({
        href,
        navigate
      }) => [_createVNode(_component_el_link, {
        type: "primary",
        href: href,
        onClick: navigate,
        underline: false
      }, {
        default: _withCtx(() => [_createVNode(_component_ComTooltip, {
          content: _ctx.formatLink(huonekortti)
        }, {
          default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx.formatLink(huonekortti)), 1)]),
          _: 2
        }, 1032, ["content"])]),
        _: 2
      }, 1032, ["href", "onClick"])]),
      _: 2
    }, 1032, ["to"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(huonekortti.toiminta), 1)])])]);
  }), 128))])), [[_directive_loading, _ctx.loading]]);
}