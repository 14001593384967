import Order from "@/types/OrderEnum";
import TilaSort from "@/types/TilaSortEnum";
import TilaListingParams from "@/types/TilaListingParams"
import { reactive, computed } from "vue"
import tilaApi from "@/apis/tilaApi";
import Paging from "@/types/Paging";
import TilaFilters from "@/types/TilaFilters";

const state = reactive({
    page: {
        currentPage: 1,
        pageSize: 30
    } as Paging,
    sort: TilaSort.id as TilaSort,
    order: Order.asc as Order,
    vastuuyksikot: [] as number[],
    hankeId: NaN as number,
    filters: {
        tilanimike: "",
        tilanimikeTasmennys: "",
        tilatunnus: ""
    } as TilaFilters
})


const url = "tilat/hankeId/"
const key = computed(() => {
    if(state.vastuuyksikot.length !== 0 && state.hankeId)
        return getters.getKey()
    else {
        return null
    }
})

const fetcher = (url : string) => {
    return tilaApi.getTilat(url)
}

const methods = {
    formatKey(request : TilaListingParams) : string {
        const params = new URLSearchParams()
    
        if(request && request.vastuuyksikot) {
            params.set('vastuuyksikot',request.vastuuyksikot + '')
        }
    
        if(request && request.page) {
            params.set('page', request.page.toString())
        }
    
        if(request && request.perPage) {
            params.set('per_page', request.perPage.toString())
        }
    
        if(request && request.sort) {
            params.set('sort', request.sort.toString())
        }
    
        if(request && request.order) {
            const order = Order[request.order]

            params.set('order', order)
        }
    
        if(request && request.tilanimike) {
            params.set('tilanimike', request.tilanimike)
        }
    
        if(request && request.tilanimikeTasmennys) {
            params.set('tilanimike_tasmennys', request.tilanimikeTasmennys)
        }
    
        if(request && request.tilatunnus) {
            params.set('tilatunnus', request.tilatunnus)
        }
    
        const urlString = url + state.hankeId + "?" + params.toString()
        
        return urlString
    },
    sortChanged(sort : TilaSort, order : Order) {
        state.sort =  sort
        state.order = order
    },
    setPage(page : Paging) {
        state.page = page
    },
    setSort(sort : TilaSort) {
        state.sort = sort
    },
    setOrder(order : Order) {
        state.order = order
    },
    setVastuuyksikot(vastuuyksikot : number[]) {
        state.vastuuyksikot = vastuuyksikot
    },
    setHankeId(hankeId : number) {
        state.hankeId =  hankeId
    },
    setFilters(filters : TilaFilters) {
        state.filters = filters
    },
    setAllFilters(val : string) {
        state.filters.tilanimike = val
        state.filters.tilanimikeTasmennys = val
        state.filters.tilatunnus = val
    }
}
const getters = {
    getKey() : string {
        const key : TilaListingParams = {
            vastuuyksikot: state.vastuuyksikot,
            page: state.page.currentPage,
            perPage: state.page.pageSize,
            sort: state.sort,
            order: state.order,
            tilanimike: state.filters.tilanimike,
            tilanimikeTasmennys: state.filters.tilanimikeTasmennys,
            tilatunnus: state.filters.tilatunnus
        } 
    
        return methods.formatKey(key);
    },
}

export default {
    getters,
    methods,
    state,
    key,
    fetcher
}
