import { defineComponent } from 'vue';
import ComEmpty from './ComEmpty.vue';
import ComLoading from './ComLoading.vue';
export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    empty: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ComEmpty,
    ComLoading
  }
});