import ComHuonekorttiTableWithFilterAndPagination from '@/components/hanke/huonekortit/table/ComHuonekorttiTableWithFilterAndPagination.vue';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  components: {
    ComHuonekorttiTableWithFilterAndPagination
  },
  emits: ["huonekorteittain"],
  setup(prop, {
    emit
  }) {
    const huonekorteittain = ref(false);
    watch(huonekorteittain, () => {
      emit("huonekorteittain", huonekorteittain.value);
    });
    return {
      huonekorteittain
    };
  }
});