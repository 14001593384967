import { defineComponent, ref } from 'vue';
import { useYllapitoHanke } from './yllapitoHankeComposables';
import ComSuccessButton from '../common/buttons/ComSuccessButton.vue';
export default defineComponent({
  components: {
    ComSuccessButton
  },
  setup() {
    const {
      addVaastuuyksikko
    } = useYllapitoHanke();
    const vastuuyksikko = ref({});
    const form = ref();
    const rules = {
      nimi: [{
        required: true,
        message: 'Vastuuyksikkö on pakollinen',
        trigger: 'blur'
      }]
    };
    const addVastuuyksikkoClick = async () => {
      var _a;
      const valid = await ((_a = form.value) === null || _a === void 0 ? void 0 : _a.validate());
      if (valid) {
        addVaastuuyksikko(vastuuyksikko.value);
      } else {
        return false;
      }
    };
    return {
      vastuuyksikko,
      rules,
      form,
      addVastuuyksikkoClick
    };
  }
});