import { defineComponent } from 'vue';
import { useAddHanke } from './yllapitoHankeComposables';
import ComDeleteButton from '../common/buttons/ComDeleteButton.vue';
import ComPlusButton from '../common/buttons/ComPlusButton.vue';
export default defineComponent({
  components: {
    ComDeleteButton,
    ComPlusButton
  },
  setup() {
    const {
      hanke,
      addVaastuuyksikko,
      deleteVaastuuyksikko
    } = useAddHanke();
    return {
      hanke,
      addVaastuuyksikko,
      deleteVaastuuyksikko
    };
  }
});