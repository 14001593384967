import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ChatRound = _resolveComponent("ChatRound");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_ComTooltip = _resolveComponent("ComTooltip");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_button, {
    type: "info",
    size: "small",
    circle: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_ComTooltip, {
      content: "Kommentoi"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_ChatRound)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}