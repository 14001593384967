import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_ComDeleteButton = _resolveComponent("ComDeleteButton");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_option = _resolveComponent("el-option");
  const _component_ComSelect = _resolveComponent("ComSelect");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_el_space = _resolveComponent("el-space");
  return _ctx.data ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, paakayttaja => {
    return _openBlock(), _createBlock(_component_el_row, {
      key: paakayttaja.id
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 10
      }, {
        default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(paakayttaja.kayttaja.etunimi) + " " + _toDisplayString(paakayttaja.kayttaja.sukunimi), 1)]),
        _: 2
      }, 1024), _createVNode(_component_el_col, {
        span: 12,
        offset: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_ComDeleteButton, {
            onClick: $event => _ctx.deletePaakayttaja(paakayttaja.id)
          }, {
            default: _withCtx(() => [_createTextVNode("Poista")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024)]),
      _: 2
    }, 1024);
  }), 128)), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_space, null, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "Lisää pääkäyttäjä"
      }, {
        default: _withCtx(() => [_createVNode(_component_ComSelect, {
          modelValue: _ctx.selectedKayttaja,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.selectedKayttaja = $event),
          filterable: "",
          remote: "",
          "reserve-keyword": "",
          "value-key": "email",
          placeholder: "Kirjoita ja valitse",
          loading: _ctx.loading,
          "remote-method": _ctx.fetchKayttajat,
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kayttajat, kayttaja => {
            return _openBlock(), _createBlock(_component_el_option, {
              key: kayttaja,
              label: kayttaja.email,
              value: kayttaja
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue", "loading", "remote-method"])]),
        _: 1
      }), _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [_createVNode(_component_ComSuccessButton, {
          onClick: _ctx.addPaakayttajaClick
        }, {
          default: _withCtx(() => [_createTextVNode("Lisää")]),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })], 64)) : _createCommentVNode("", true);
}