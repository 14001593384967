import { reactive, readonly } from "vue"

const state = reactive({
    valinnat: [] as number[],
})

const methods = {
    add(val : number) {
        if(!state.valinnat.find(h => h === val)) {
            state.valinnat.push(val)
        }
    },
    remove(val : number) {
        const f = state.valinnat.indexOf(val)

        if(f !== -1) {
            state.valinnat.splice(f, 1)
        }
    },
    clear() {
        state.valinnat = []
    }
}

const getters = {
    getHuonekortit() {
        return state.valinnat
    }
}

export default {
    state: readonly(state),
    methods,
    getters
}