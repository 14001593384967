import ComYllapitoHankeTable from '@/components/yllapito/ComYllapitoHankeTable.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: "YllapitoView",
  components: {
    ComYllapitoHankeTable
  },
  setup() {
    return {};
  }
});