import { defineComponent, ref } from 'vue';
import Orientation from '../../types/OrientationEnum';
import { ArrowDown, ArrowRight, ArrowLeft, ArrowUp } from '@element-plus/icons-vue';
export default defineComponent({
  components: {
    ArrowDown,
    ArrowRight,
    ArrowLeft,
    ArrowUp
  },
  props: {
    show: {
      required: false,
      type: Boolean,
      default: false
    },
    title: {
      required: true,
      type: String
    },
    way: {
      required: false,
      type: String,
      default: Orientation.vertical
    }
  },
  emits: ['expand'],
  setup(props) {
    const s = ref(props.show);
    const tiedotHideBody = ref('tiedot-hide-body');
    const expand = () => {
      s.value = !s.value;
    };
    return {
      s,
      expand,
      tiedotHideBody
    };
  }
});