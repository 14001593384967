import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_el_space = _resolveComponent("el-space");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    model: _ctx.vastuuyksikko,
    rules: _ctx.rules,
    ref: "form"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Vastuuyksikkö",
      prop: "nimi"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_space, null, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.vastuuyksikko.nimi,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.vastuuyksikko.nimi = $event)
        }, null, 8, ["modelValue"]), _createVNode(_component_ComSuccessButton, {
          onClick: _ctx.addVastuuyksikkoClick
        }, {
          default: _withCtx(() => [_createTextVNode("Lisää")]),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"]);
}