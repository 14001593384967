import { defineComponent } from 'vue';
import { useAuth0 } from "@/auth/auth0-plugin";
import ComTooltip from '../ComTooltip.vue';
export default defineComponent({
  name: "LogoutButton",
  components: {
    ComTooltip
  },
  setup() {
    const logoutUrl = process.env.VUE_APP_AUTH0_LOGOUT_URL;
    const auth0 = useAuth0();
    const logout = () => {
      if (!auth0) {
        return;
      }
      auth0.logout({
        logoutParams: {
          returnTo: logoutUrl
        }
      });
    };
    return {
      logout
    };
  }
});