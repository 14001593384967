import ComEmpty from '@/components/common/ComEmpty.vue';
import ComLoading from '@/components/common/ComLoading.vue';
import huonekorttiListingService from '@/methods/huonekorttiListingService';
import huonekorttiService from '@/methods/huonekorttiService';
import { provideSelectedVastuuyksikot } from '@/types/Symbols';
import useSWRV from 'swrv';
import { computed, defineComponent, inject, ref } from 'vue';
import ComRoskakoriListing from './ComRoskakoriListing.vue';
export default defineComponent({
  components: {
    ComRoskakoriListing,
    ComLoading,
    ComEmpty
  },
  setup() {
    const unDeleteHuonekortti = huonekorttiService.methods.unDeleteHuonekortti;
    const mutate = huonekorttiListingService.methods.setMutate;
    const vastuuyksikot = inject(provideSelectedVastuuyksikot);
    const selectedVastuuyksikkoIds = computed(() => vastuuyksikot === null || vastuuyksikot === void 0 ? void 0 : vastuuyksikot.value.map(v => v.id));
    const page = ref(1);
    const perPage = ref(30);
    const params = computed(() => {
      return {
        vastuuyksikot: selectedVastuuyksikkoIds.value,
        perPage: perPage.value,
        page: page.value,
        poistettu: true
      };
    });
    const key = computed(() => huonekorttiListingService.methods.loadKey(params.value));
    const fetcher = huonekorttiListingService.fetcher;
    const {
      data
    } = useSWRV(() => key.value, fetcher);
    const huonekortit = computed(() => {
      var _a;
      return (_a = data.value) === null || _a === void 0 ? void 0 : _a.data;
    });
    const count = computed(() => {
      var _a, _b;
      return ((_a = data.value) === null || _a === void 0 ? void 0 : _a.headers["page-total-count"]) ? parseInt((_b = data.value) === null || _b === void 0 ? void 0 : _b.headers["page-total-count"]) : 1;
    });
    const palautaButtonClicked = async id => {
      const result = await unDeleteHuonekortti(id);
      if (result && huonekortit.value) {
        const index = huonekortit.value.findIndex(h => h.id == id);
        huonekortit.value.splice(index, 1);
        mutate(true);
      }
    };
    const pageChangeClicked = val => {
      page.value = val.currentPage;
      perPage.value = val.pageSize;
    };
    return {
      huonekortit,
      vastuuyksikot,
      count,
      palautaButtonClicked,
      pageChangeClicked
    };
  }
});