import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComHuonekorttiHistoriaItem = _resolveComponent("ComHuonekorttiHistoriaItem");
  const _component_el_timeline = _resolveComponent("el-timeline");
  const _component_ComEmpty = _resolveComponent("ComEmpty");
  return _ctx.show ? (_openBlock(), _createBlock(_component_el_timeline, {
    key: 0
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historia, muutos => {
      return _openBlock(), _createBlock(_component_ComHuonekorttiHistoriaItem, {
        key: muutos.id,
        data: _ctx.currentData(muutos.id),
        previousData: muutos
      }, null, 8, ["data", "previousData"]);
    }), 128))]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_ComEmpty, {
    key: 1
  }));
}