import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  return _openBlock(), _createBlock(_component_el_date_picker, {
    type: "datetimerange",
    "start-placeholder": "Mistä",
    "end-placeholder": "Mihin",
    format: "DD.MM.YYYY HH:mm:ss",
    "value-format": "DD.MM.YYYY HH:mm:ss"
  });
}