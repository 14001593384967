import { reactive, readonly } from "vue";
import { OikeusResponse } from "@/api/models/oikeus-response";
import kayttajaApi from "@/apis/kayttajaApi";
import oikeudet from "@/types/OikeudetEnum";
import { HankeResponse } from "@/api/models/hanke-response";
import { PaakayttajaAndOikeusResponse } from "@/api/models/paakayttaja-and-oikeus-response";
import { auth0Client } from "@/auth/auth0-plugin";


const state = reactive ({
    oikeudet: undefined as PaakayttajaAndOikeusResponse | undefined,
    vastuuyksikkoOikeudet: undefined as OikeusResponse[] | undefined,
    paakayttajaOikeudet: [] as HankeResponse[],
    superUser: undefined as boolean | undefined
})

const methods = {
    async loadOikeudet() {
        state.oikeudet = await kayttajaApi.getKayttajaOikeudet() 
        state.superUser = await methods.hasSuperUserRights();

        if(state.oikeudet) {
            state.vastuuyksikkoOikeudet = state.oikeudet.vastuuyksikkoOikeudet
            state.paakayttajaOikeudet = state.oikeudet.hankePaakayttaja!
        }
    },
    hasVastuuyksikkoRights(id : number, oikeus : oikeudet) {
        let i = false
        if(state.vastuuyksikkoOikeudet === undefined) {
            methods.loadOikeudet()
        }

        if(state.vastuuyksikkoOikeudet?.some(v => v[oikeus] === true && v.vastuuyksikko && v.vastuuyksikko.id === id)) {
            i = true
        }
        else if(methods.hasVastuuyksikkoPaakayttajaRights(id)) {
            i = true
        }

        return i
    },
    hasPaakayttajaRights(id : number) {

        if(state.paakayttajaOikeudet === undefined || state.superUser === undefined) {
            methods.loadOikeudet()
        }

        if(state.superUser) {
            return true
        }

        return state.paakayttajaOikeudet.some(p => p.id === id)
    },
    hasVastuuyksikkoPaakayttajaRights(id : number) {
        let i = false;

        if(state.paakayttajaOikeudet === undefined || state.superUser === undefined) {
            methods.loadOikeudet()
        }

        if(state.superUser) {
            return true
        }

        if(state.paakayttajaOikeudet.some(h => {
            return h.vastuuyksikot?.some(v => v.id == id)
        })) {
            i = true
        }

        return i
    },
    async hasSuperUserRights() {
        const tokens = await auth0Client.value?.getIdTokenClaims()

        return (tokens?.["https://vahtihuonekortti.fi/roles"] as string[]).includes("Admin")
    }

}

export default {
    methods,
    state : readonly(state)
}
