import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_alert = _resolveComponent("el-alert");
  const _component_ComNotifyKommentti = _resolveComponent("ComNotifyKommentti");
  const _component_el_space = _resolveComponent("el-space");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    "label-width": "100px"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Kommentti"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        type: "textarea",
        modelValue: _ctx.kommentti.kommentti,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.kommentti.kommentti = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_space, {
      direction: "vertical",
      fill: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_el_alert, {
        type: "info",
        "show-icon": "",
        closable: false
      }, {
        default: _withCtx(() => [_createTextVNode(" Alla olevasta kentästä voit lähettää valituille käyttäjille sähköpostiviestin kommentista. Hae ja valitse haluamasi käyttäjät sähköpostin perusteella. ")]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "Käyttäjät"
      }, {
        default: _withCtx(() => [_createVNode(_component_ComNotifyKommentti, {
          onValitutKayttajat: _ctx.valitutKayttajatChange
        }, null, 8, ["onValitutKayttajat"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_ComSuccessButton, {
        onClick: _ctx.addCommentClick
      }, {
        default: _withCtx(() => [_createTextVNode("Kommentoi")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  });
}