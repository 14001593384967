import "core-js/modules/es.array.push.js";
import { computed, defineComponent, provide, ref, watch, watchEffect } from 'vue';
import ComPageHeader from '../components/layout/ComPageHeader.vue';
import ComHuonekortit from '@/components/hanke/huonekortit/ComHuonekortit.vue';
import ComHanketiedot from '@/components/huonekortti/hanketiedot/ComHanketiedot.vue';
import ComTilat from '@/components/hanke/tilat/ComTilat.vue';
import hankeService from '@/methods/hankeService';
import ComLoading from '@/components/common/ComLoading.vue';
import tilanimikeService from '@/methods/tilanimikeService';
import kayttajaOikeusService from '@/methods/kayttajaOikeusService';
import oikeudet from '@/types/OikeudetEnum';
import ComRaportointi from '@/components/hanke/raportointi/ComRaportointi.vue';
import ComOikeudet from '@/components/hanke/oikeudet/ComOikeudet.vue';
import useSWRV from 'swrv';
import ComTuoHuonekortteja from '@/components/hanke/tuonti/ComTuoHuonekortteja.vue';
import ComRoskakori from '@/components/hanke/roskakori/ComRoskakori.vue';
import { provideHanke, provideHankeHuonekorttiListingParams, provideHankeTilaListingParams, provideHasPaakayttajaRights, provideSelectedVastuuyksikot, provideWritableVastuuyksikot } from '@/types/Symbols';
import router from '@/router';
import { isEmpty, isEqual } from 'lodash';
import { onBeforeRouteUpdate } from 'vue-router';
import ComMallihuonekortit from '@/components/mallihuonekortit/ComMallihuonekortit.vue';
import ComCard from '@/components/common/ComCard.vue';
export default defineComponent({
  components: {
    ComHuonekortit,
    ComHanketiedot,
    ComTilat,
    ComLoading,
    ComPageHeader,
    ComRaportointi,
    ComOikeudet,
    ComTuoHuonekortteja,
    ComRoskakori,
    ComMallihuonekortit,
    ComCard
  },
  props: {
    hankeId: {
      required: true,
      type: Number
    },
    tab: {
      required: false,
      type: Number
    },
    vastuuyksikot: {
      required: false,
      type: Array
    },
    huonekorttiParams: {
      required: false,
      type: Object
    },
    tilaParams: {
      required: false,
      type: Object
    }
  },
  name: 'HankeView',
  setup(props) {
    var _a;
    const hasVastuuyksikkoRights = kayttajaOikeusService.methods.hasVastuuyksikkoRights;
    const hasPaakayttajaRights = kayttajaOikeusService.methods.hasPaakayttajaRights;
    const {
      data: tilanimikkeet
    } = tilanimikeService.methods.getTilanimikkeet();
    const getHankeById = hankeService.methods.getHankeById;
    const activeTab = ref((_a = props.tab) !== null && _a !== void 0 ? _a : 0);
    const hanke = ref();
    const vastuuyksikkoIds = ref(props.vastuuyksikot || []);
    const huonekorttiParams = ref(props.huonekorttiParams);
    const tilaParams = ref(props.tilaParams);
    const id = computed(() => props.hankeId);
    const hankeKey = computed(() => getHankeById.key(id.value));
    const {
      data: hankeData
    } = useSWRV(() => hankeKey.value, getHankeById.fetcher, getHankeById.options);
    const hankeVastuuyksikot = computed(() => {
      var _a, _b;
      return ((_a = hankeData.value) === null || _a === void 0 ? void 0 : _a.data.vastuuyksikot) ? (_b = hankeData.value) === null || _b === void 0 ? void 0 : _b.data.vastuuyksikot : [];
    });
    const writableVastuuyksikot = computed(() => {
      var _a, _b;
      if ((_a = hanke.value) === null || _a === void 0 ? void 0 : _a.arkistoitu) {
        return [];
      } else {
        return (_b = hankeVastuuyksikot.value) === null || _b === void 0 ? void 0 : _b.filter(v => hasVastuuyksikkoRights(v.id, oikeudet.Kirjoitus));
      }
    });
    const hankePaakayttaja = computed(() => hasPaakayttajaRights(id.value));
    const selectedVastuuyksikot = computed(() => {
      var _a, _b;
      const a = [];
      (_b = (_a = hanke.value) === null || _a === void 0 ? void 0 : _a.vastuuyksikot) === null || _b === void 0 ? void 0 : _b.map(v => {
        if (vastuuyksikkoIds.value.includes(v.id)) a.push(v);
      });
      return a;
    });
    const setQueryParams = () => {
      const vastuuyksikotParam = vastuuyksikkoIds.value.length ? {
        vastuuyksikot: vastuuyksikkoIds.value.join(",")
      } : null;
      const tabParam = activeTab.value ? {
        tab: activeTab.value.toString()
      } : null;
      const hParams = !isEmpty(huonekorttiParams.value) && activeTab.value == 0 ? {
        huonekorttiParams: JSON.stringify(huonekorttiParams.value)
      } : null;
      const tParams = !isEmpty(tilaParams.value) && activeTab.value == 1 ? {
        tilaParams: JSON.stringify(tilaParams.value)
      } : null;
      router.replace({
        name: "Hanke",
        params: {
          hankeId: props.hankeId
        },
        query: {
          ...vastuuyksikotParam,
          ...tabParam,
          ...hParams,
          ...tParams
        }
      });
    };
    const kaikkiVastuuyksikotClick = () => {
      vastuuyksikkoIds.value = hankeVastuuyksikot.value.map(v => v.id);
    };
    watch(() => huonekorttiParams, () => {
      setQueryParams();
    }, {
      deep: true
    });
    watch(() => tilaParams, () => {
      setQueryParams();
    }, {
      deep: true
    });
    watch(vastuuyksikkoIds, () => {
      if (!isEqual(vastuuyksikkoIds.value, props.vastuuyksikot)) {
        setQueryParams();
      }
    });
    watch(activeTab, () => {
      setQueryParams();
    });
    watch(id, (n, o) => {
      var _a, _b;
      activeTab.value = (_a = props.tab) !== null && _a !== void 0 ? _a : 0;
      vastuuyksikkoIds.value = (_b = props.vastuuyksikot) !== null && _b !== void 0 ? _b : [];
      huonekorttiParams.value = props.huonekorttiParams;
      tilaParams.value = props.tilaParams;
    });
    watchEffect(() => {
      var _a, _b;
      hanke.value = (_a = hankeData.value) === null || _a === void 0 ? void 0 : _a.data;
      if ((_b = hankeData.value) === null || _b === void 0 ? void 0 : _b.headers) {
        hankeService.methods.setTilanumero(hankeData.value.headers, id.value);
      }
    });
    onBeforeRouteUpdate((to, from) => {
      if (from.params.hankeId === to.params.hankeId && !to.query.vastuuyksikot && !to.query.tab && !to.query.huonekorttiParams && !to.query.tilaParams) {
        return false;
      } else {
        return true;
      }
    });
    provide(provideSelectedVastuuyksikot, selectedVastuuyksikot);
    provide("hankeId", id);
    provide("vastuuyksikot", hankeVastuuyksikot);
    provide(provideHanke, hanke);
    provide("tilanimikkeet", tilanimikkeet);
    provide(provideWritableVastuuyksikot, writableVastuuyksikot);
    provide(provideHasPaakayttajaRights, hankePaakayttaja);
    provide(provideHankeHuonekorttiListingParams, huonekorttiParams);
    provide(provideHankeTilaListingParams, tilaParams);
    return {
      hanke,
      vastuuyksikkoIds,
      selectedVastuuyksikot,
      activeTab,
      hankePaakayttaja,
      hankeVastuuyksikot,
      kaikkiVastuuyksikotClick
    };
  }
});