import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(props, {
    emit
  }) {
    let timetOut = NaN;
    const filterChange = event => {
      const target = event.target;
      clearTimeout(timetOut);
      timetOut = window.setTimeout(() => {
        emit('update:modelValue', target.value);
      }, 1000);
    };
    return {
      filterChange
    };
  }
});