import "core-js/modules/es.array.push.js";
import { defineComponent, ref } from 'vue';
import hankeListingService from '@/methods/hankeListingService';
import useSWRV from 'swrv';
import ComSideMenuVastuuyksikko from './ComSideMenuVastuuyksikko.vue';
import ComExpand from '@/components/common/ComExpand.vue';
import { DocumentCopy } from '@element-plus/icons-vue';
import router from '@/router';
import ComTooltip from '@/components/common/ComTooltip.vue';
export default defineComponent({
  components: {
    ComSideMenuVastuuyksikko,
    ComExpand,
    DocumentCopy,
    ComTooltip
  },
  setup() {
    const collapse = ref(false);
    const props = {
      children: "vastuuyksikot",
      label: "nimi"
    };
    const {
      key,
      fetcher,
      options
    } = hankeListingService;
    const {
      data
    } = useSWRV(() => key.value, fetcher, options);
    const expand = ref(false);
    const width = ref(280);
    const expandMenu = () => {
      expand.value = !expand.value;
      width.value = expand.value ? 480 : 280;
      console.log(width.value);
    };
    const navigate = id => {
      router.push({
        name: "Hanke",
        params: {
          hankeId: id
        }
      });
    };
    return {
      data,
      collapse,
      props,
      expand,
      width,
      expandMenu,
      navigate
    };
  }
});