import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    content: {
      required: true,
      type: String
    },
    placement: {
      required: false,
      type: String,
      default: "top"
    }
  }
});