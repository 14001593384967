import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComEmpty = _resolveComponent("ComEmpty");
  const _component_el_card = _resolveComponent("el-card");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_el_space = _resolveComponent("el-space");
  const _component_ComEditor = _resolveComponent("ComEditor");
  const _component_ComCancelButton = _resolveComponent("ComCancelButton");
  return !_ctx.edit ? (_openBlock(), _createBlock(_component_el_space, {
    key: 0,
    direction: "vertical",
    fill: "",
    style: {
      "display": "flex"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_card, {
      style: {
        "width": "100%"
      }
    }, {
      default: _withCtx(() => [!_ctx.vaatimus ? (_openBlock(), _createBlock(_component_ComEmpty, {
        key: 0
      })) : (_openBlock(), _createElementBlock("span", {
        key: 1,
        innerHTML: _ctx.sanitizeVaatimus(_ctx.vaatimus)
      }, null, 8, _hoisted_1))]),
      _: 1
    }), _ctx.editable ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_ComSuccessButton, {
      onClick: _ctx.editVaatimusClick
    }, {
      default: _withCtx(() => [_createTextVNode("Muokkaa")]),
      _: 1
    }, 8, ["onClick"])])) : _createCommentVNode("", true)]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_el_space, {
    key: 1,
    direction: "vertical",
    fill: "",
    style: {
      "display": "flex"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_ComEditor, {
      disabled: false,
      modelValue: _ctx.vaatimusEditable,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.vaatimusEditable = $event),
      initial: ""
    }, null, 8, ["modelValue"])]), _createElementVNode("div", null, [_createVNode(_component_el_space, null, {
      default: _withCtx(() => [_createVNode(_component_ComSuccessButton, {
        onClick: _ctx.saveVaatimusClick
      }, {
        default: _withCtx(() => [_createTextVNode("Tallenna")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_ComCancelButton, {
        onClick: _cache[1] || (_cache[1] = $event => _ctx.edit = false)
      }, {
        default: _withCtx(() => [_createTextVNode("Peruuta")]),
        _: 1
      })]),
      _: 1
    })])]),
    _: 1
  }));
}