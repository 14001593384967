import "core-js/modules/es.array.push.js";
import { computed, defineComponent, inject, ref, watchEffect, watch, provide } from 'vue';
import ComOikeusTableButtons from './ComOikeusTableButtons.vue';
import ComDeleteButton from '@/components/common/buttons/ComDeleteButton.vue';
import adminService from '@/methods/adminService';
import ComLisaaOikeus from './ComLisaaOikeus.vue';
import { ElMessage } from 'element-plus';
import ComFilterInput from '@/components/common/ComFilterInput.vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import ComLisaaKayttaja from './kayttaja/ComLisaaKayttaja.vue';
import { provideSelectedVastuuyksikot } from '@/types/Symbols';
export default defineComponent({
  components: {
    ComOikeusTableButtons,
    ComDeleteButton,
    ComLisaaOikeus,
    ComFilterInput,
    ComSuccessButton,
    ComLisaaKayttaja
  },
  setup() {
    const vastuuyksikotSelected = inject(provideSelectedVastuuyksikot);
    const vastuuyksikot = inject("vastuuyksikot");
    const hankeId = inject("hankeId");
    const deleteAllOikeudet = adminService.methods.deleteAllOikeudet;
    const getVastuuyksikkoOikeudet = adminService.methods.getVastuuyksikkoOikeudet;
    const getPaakayttajat = adminService.methods.getPaakayttajatByHankeId;
    const getSairaalaKayttajatByHankeId = adminService.methods.getSairaalaKayttajatByHankeId;
    const kaikkiOikeudet = ref([]);
    const kayttajat = ref([]);
    const paakayttajat = ref([]);
    const oikeudetEnum = {};
    const loading = ref(false);
    const lisaaKayttajaModal = ref(false);
    const editKayttaja = ref();
    const filter = ref("");
    const filteredOikeudet = computed(() => {
      if (filter.value) {
        const f = filter.value.toLowerCase();
        return kaikkiOikeudet.value.filter(k => {
          var _a, _b, _c, _d;
          return ((_a = k.email) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(f)) || ((_b = k.etunimi) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(f)) || ((_c = k.sukunimi) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(f)) || ((_d = k.etunimi) === null || _d === void 0 ? void 0 : _d.toLowerCase().concat(' ' + k.sukunimi.toLowerCase()).includes(f));
        });
      }
      return kaikkiOikeudet.value;
    });
    const selectedVastuuyksikkoIds = computed(() => vastuuyksikotSelected === null || vastuuyksikotSelected === void 0 ? void 0 : vastuuyksikotSelected.value.map(v => v.id));
    const vastuuyksikotListed = computed(() => {
      const v = vastuuyksikot.value.filter(v => {
        var _a;
        return (_a = selectedVastuuyksikkoIds.value) === null || _a === void 0 ? void 0 : _a.includes(v.id);
      });
      if (v) {
        return v;
      } else {
        return [];
      }
    });
    const loadOikeudet = () => {
      var _a;
      loading.value = true;
      getVastuuyksikkoOikeudet((_a = selectedVastuuyksikkoIds.value) !== null && _a !== void 0 ? _a : []).then(response => {
        if (response) {
          kaikkiOikeudet.value = response;
        } else {
          ElMessage.error("Oikeuksien haussa tapahtui virhe!");
        }
        loading.value = false;
      });
    };
    const loadPaakayttajat = () => {
      getPaakayttajat(hankeId.value).then(response => {
        if (response) {
          paakayttajat.value = response;
        } else {
          ElMessage.error("Pääkäyttäjien haussa tapahtui virhe!");
        }
      });
    };
    const loadKayttajat = () => {
      getSairaalaKayttajatByHankeId(hankeId.value).then(response => {
        if (response) {
          kayttajat.value = response;
        } else {
          ElMessage.error("Käyttäjien haku ei onnistunut!");
        }
      });
    };
    const getVastuuyksikkoOikeus = (oikeudet, vastuuyksikko) => {
      return oikeudet === null || oikeudet === void 0 ? void 0 : oikeudet.find(o => {
        var _a;
        return ((_a = o.vastuuyksikko) === null || _a === void 0 ? void 0 : _a.id) == vastuuyksikko;
      });
    };
    const updateOikeus = oikeus => {
      var _a, _b;
      const oikeudet = kaikkiOikeudet.value;
      if (oikeudet) {
        const i = oikeudet.findIndex(k => {
          var _a;
          return k.id == ((_a = oikeus.kayttaja) === null || _a === void 0 ? void 0 : _a.id);
        });
        if (i != -1) {
          oikeudet[i].oikeudet = (_a = oikeudet[i].oikeudet) === null || _a === void 0 ? void 0 : _a.filter(f => f.id != oikeus.id);
          (_b = oikeudet[i].oikeudet) === null || _b === void 0 ? void 0 : _b.push(oikeus);
          kaikkiOikeudet.value = oikeudet;
        }
      }
    };
    const addOikeus = oikeus => {
      var _a;
      const oikeudet = kaikkiOikeudet.value;
      if (oikeudet) {
        const i = oikeudet.findIndex(k => {
          var _a;
          return k.id == ((_a = oikeus.kayttaja) === null || _a === void 0 ? void 0 : _a.id);
        });
        if (i != -1) {
          (_a = oikeudet[i].oikeudet) === null || _a === void 0 ? void 0 : _a.push(oikeus);
        }
        kaikkiOikeudet.value = oikeudet;
      }
    };
    const addOikeudetClick = () => {
      loadOikeudet();
      ElMessage.success("Käyttäjäoikeudet lisätty!");
    };
    const deleteOikeus = oikeus => {
      var _a;
      const oikeudet = kaikkiOikeudet.value;
      if (oikeudet) {
        const i = oikeudet.findIndex(k => {
          var _a;
          return k.id == ((_a = oikeus.kayttaja) === null || _a === void 0 ? void 0 : _a.id);
        });
        if (i != -1) {
          oikeudet[i].oikeudet = (_a = oikeudet[i].oikeudet) === null || _a === void 0 ? void 0 : _a.filter(f => f.id != oikeus.id);
          kaikkiOikeudet.value = oikeudet;
        }
      }
    };
    const deleteOikeudet = kayttaja => {
      var _a;
      const ids = (_a = kayttaja.oikeudet) === null || _a === void 0 ? void 0 : _a.map(o => o.id);
      if (ids) {
        deleteAllOikeudet(ids).then(response => {
          var _a;
          if (response) {
            const i = kaikkiOikeudet.value.indexOf(kayttaja);
            if (i != -1) {
              (_a = kaikkiOikeudet.value) === null || _a === void 0 ? void 0 : _a.splice(i, 1);
            }
          }
        });
      }
    };
    const kayttajaAddClick = () => {
      loadKayttajat();
      loadOikeudet();
    };
    const kayttajaUpdateClick = () => {
      loadKayttajat();
      loadOikeudet();
      lisaaKayttajaModal.value = false;
    };
    const editKayttajaClick = val => {
      editKayttaja.value = val;
      lisaaKayttajaModal.value = true;
    };
    const kayttajaModalClose = () => {
      editKayttaja.value = undefined;
      lisaaKayttajaModal.value = false;
    };
    watchEffect(() => {
      if ((vastuuyksikotSelected === null || vastuuyksikotSelected === void 0 ? void 0 : vastuuyksikotSelected.value.length) != 0) {
        loadOikeudet();
      } else {
        kaikkiOikeudet.value = [];
        loading.value = false;
      }
    });
    watch(hankeId, () => {
      loadPaakayttajat();
      loadKayttajat();
    });
    loadPaakayttajat();
    loadKayttajat();
    provide("paakayttajat", paakayttajat);
    return {
      vastuuyksikotListed,
      filteredOikeudet,
      oikeudetEnum,
      loading,
      filter,
      lisaaKayttajaModal,
      kayttajat,
      editKayttaja,
      getVastuuyksikkoOikeus,
      updateOikeus,
      addOikeus,
      addOikeudetClick,
      editKayttajaClick,
      deleteOikeus,
      deleteOikeudet,
      kayttajaAddClick,
      kayttajaUpdateClick,
      kayttajaModalClose
    };
  }
});