import { defineComponent, onMounted, computed } from 'vue';
import ComHeader from './components/layout/ComHeader.vue';
import ComSideMenu from './components/layout/SideMenu/ComSideMenu.vue';
import { useAuth0 } from "@/auth/auth0-plugin";
import kayttajaOikeusService from './methods/kayttajaOikeusService';
export default defineComponent({
  components: {
    ComHeader,
    ComSideMenu
  },
  setup() {
    const auth0 = useAuth0();
    const loadOikeudet = kayttajaOikeusService.methods.loadOikeudet;
    onMounted(async () => {
      if (!auth0) {
        return;
      }
      await auth0.createClient();
      await auth0.handleCallback();
      await auth0.checkToken();
      if (auth0.isAuthenticated.value) {
        await loadOikeudet();
      }
    });
    const isAuthenticated = computed(() => {
      if (!auth0) {
        return null;
      }
      return auth0.isAuthenticated.value;
    });
    return {
      isAuthenticated
    };
  }
});