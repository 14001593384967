import "core-js/modules/es.array.push.js";
import adminService from '@/methods/adminService';
import { ElMessage, ElMessageBox } from 'element-plus';
import { computed, defineComponent, inject, ref } from 'vue';
import ComPaakayttajat from './ComPaakayttajat.vue';
import ComSuccessButton from '../../common/buttons/ComSuccessButton.vue';
import ComOikeusButtons from './ComOikeusButtons.vue';
import { provideSelectedVastuuyksikot } from '@/types/Symbols';
import ComEditButton from '@/components/common/buttons/ComEditButton.vue';
export default defineComponent({
  components: {
    ComPaakayttajat,
    ComSuccessButton,
    ComOikeusButtons,
    ComEditButton
  },
  emits: ["addOikeudet", "editKayttaja"],
  props: {
    filter: {
      type: String,
      required: false
    },
    kayttajat: {
      type: Object,
      required: true
    }
  },
  setup(prop, {
    emit
  }) {
    const hankeId = inject("hankeId");
    const vastuuyksikotSelected = inject(provideSelectedVastuuyksikot);
    const paakayttajat = inject("paakayttajat");
    const addOikeudet = adminService.methods.addOikeudet;
    const addPaakayttajat = adminService.methods.addPaakayttajat;
    const valittuKayttaja = ref();
    const lisaaKayttajaModal = ref(false);
    const selected = ref([]);
    const oikeudet = ref({
      luku: true,
      kirjoitus: false,
      raportointi: false
    });
    const props = {
      label: "etunimi",
      value: "id",
      multiple: true
    };
    const filteredKayttajat = computed(() => {
      if (prop.filter) {
        const f = prop.filter.toLowerCase();
        return prop.kayttajat.filter(k => {
          var _a, _b, _c, _d;
          return ((_a = k.email) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(f)) || ((_b = k.etunimi) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(f)) || ((_c = k.sukunimi) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(f)) || ((_d = k.etunimi) === null || _d === void 0 ? void 0 : _d.toLowerCase().concat(" " + k.sukunimi.toLowerCase()).includes(f));
        });
      }
      return prop.kayttajat;
    });
    const oikeusClick = oikeus => {
      oikeudet.value = oikeus;
    };
    const addOikeudetClick = () => {
      if ((vastuuyksikotSelected === null || vastuuyksikotSelected === void 0 ? void 0 : vastuuyksikotSelected.value.length) == 0) {
        ElMessage.warning("Valitse vähintään yksi vastuuyksikkö!");
      } else {
        if (selected.value.length == 0) {
          ElMessage.warning("Valitse vähintään yksi käyttäjä!");
        } else {
          const requests = [];
          vastuuyksikotSelected === null || vastuuyksikotSelected === void 0 ? void 0 : vastuuyksikotSelected.value.forEach(v => {
            selected.value.forEach(k => {
              const request = {
                luku: true,
                kirjoitus: oikeudet.value.kirjoitus,
                raportointi: oikeudet.value.raportointi,
                kayttaja: {
                  id: k[0]
                },
                vastuuyksikko: {
                  id: v.id
                }
              };
              requests.push(request);
            });
          });
          addOikeudet(requests).then(response => {
            if (response) {
              emit("addOikeudet");
              selected.value = [];
            } else {
              ElMessage.error("Käyttäjäoikeuksien lisääminen ei onnistunut!");
            }
          });
        }
      }
    };
    const addPaakayttajaClick = () => {
      if (selected.value.length == 0) {
        ElMessage.warning("Valitse vähintään yksi käyttäjä!");
      } else {
        ElMessageBox.confirm("Haluatko varmasti lisätä valitut käyttäjät hankkeen pääkäyttäjiksi?", "", {
          type: "warning"
        }).then(() => {
          const kayttajaIds = selected.value.map(s => s[0]);
          if (paakayttajat.value.some(p => kayttajaIds.includes(p.kayttaja.id))) {
            ElMessage.error("Käyttäjällä on jo pääkäyttäjäoikeudet!");
          } else {
            addPaakayttajat(hankeId.value, kayttajaIds).then(response => {
              if (response) {
                paakayttajat.value = [...paakayttajat.value, ...response];
                ElMessage.success("Pääkäyttäjät lisätty!");
                selected.value = [];
              } else {
                ElMessage.error("Pääkäyttäjän lisääminen ei onnistunut!");
              }
            });
          }
        });
      }
    };
    return {
      selected,
      props,
      oikeudet,
      filteredKayttajat,
      valittuKayttaja,
      lisaaKayttajaModal,
      oikeusClick,
      addOikeudetClick,
      addPaakayttajaClick
    };
  }
});