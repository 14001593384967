import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_ComDeleteButton = _resolveComponent("ComDeleteButton");
  const _component_ComPlusButton = _resolveComponent("ComPlusButton");
  const _component_el_space = _resolveComponent("el-space");
  const _component_el_form_item = _resolveComponent("el-form-item");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hanke.vastuuyksikot, (vastuuyksikko, index) => {
    return _openBlock(), _createBlock(_component_el_form_item, {
      key: index,
      prop: 'vastuuyksikko' + index,
      label: 'Vastuuyksikkö ' + (index + 1)
    }, {
      default: _withCtx(() => [_createVNode(_component_el_space, null, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          placeholder: "Nimi",
          modelValue: vastuuyksikko.nimi,
          "onUpdate:modelValue": $event => vastuuyksikko.nimi = $event
        }, null, 8, ["modelValue", "onUpdate:modelValue"]), _createVNode(_component_el_input, {
          placeholder: "Vaatimukset",
          modelValue: vastuuyksikko.vaatimukset,
          "onUpdate:modelValue": $event => vastuuyksikko.vaatimukset = $event
        }, null, 8, ["modelValue", "onUpdate:modelValue"]), _createVNode(_component_ComDeleteButton, {
          onClick: $event => _ctx.deleteVaastuuyksikko(index)
        }, null, 8, ["onClick"]), index + 1 == _ctx.hanke.vastuuyksikot.length ? (_openBlock(), _createBlock(_component_ComPlusButton, {
          key: 0,
          onClick: _ctx.addVaastuuyksikko
        }, null, 8, ["onClick"])) : _createCommentVNode("", true)]),
        _: 2
      }, 1024)]),
      _: 2
    }, 1032, ["prop", "label"]);
  }), 128);
}