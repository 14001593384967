import { HuonekorttiStatusResponse } from '@/api/models/huonekortti-status-response';
import ComDateTimePicker from '@/components/common/ComDateTimePicker.vue';
import { defineComponent, ref, watch } from 'vue';
import { useHuonekorttiListing } from '../huonekortit/huonekortit';
import ComEmpty from '@/components/common/ComEmpty.vue';
import ComLoading from '@/components/common/ComLoading.vue';
import ComHuonekorttiTableWithFilterAndPagination from '../huonekortit/table/ComHuonekorttiTableWithFilterAndPagination.vue';
export default defineComponent({
  components: {
    ComDateTimePicker,
    ComEmpty,
    ComLoading,
    ComHuonekorttiTableWithFilterAndPagination
  },
  emits: ["range"],
  setup(props, {
    emit
  }) {
    const {
      huonekortit,
      loading,
      filter,
      totalItems,
      status,
      paging
    } = useHuonekorttiListing();
    const range = ref();
    watch(range, () => {
      emit("range", range.value);
    });
    status.value = [HuonekorttiStatusResponse.Hyvaksytty];
    return {
      huonekortit,
      loading,
      filter,
      totalItems,
      range,
      paging
    };
  }
});