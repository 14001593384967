import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComMoreButton = _resolveComponent("ComMoreButton");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createBlock(_component_el_dropdown, {
    class: "huonekortti-info-dropdown"
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
      default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
        onClick: _ctx.tulostaClick
      }, {
        default: _withCtx(() => [_createTextVNode("Tulosta")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_dropdown_item, {
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('showTiedot'))
      }, {
        default: _withCtx(() => [_createTextVNode("Tiedot")]),
        _: 1
      }), _ctx.isHyvaksytty ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 0,
        onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('showMuutokset'))
      }, {
        default: _withCtx(() => [_createTextVNode("Historia")]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })]),
    default: _withCtx(() => [_createVNode(_component_ComMoreButton)]),
    _: 1
  });
}