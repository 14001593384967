import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_ComYllapitoHankeVastuuyksikko = _resolveComponent("ComYllapitoHankeVastuuyksikko");
  const _component_ComYllapitoHankeVastuuyksikkoAdd = _resolveComponent("ComYllapitoHankeVastuuyksikkoAdd");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_ComYllapitoHankePaakayttajat = _resolveComponent("ComYllapitoHankePaakayttajat");
  const _component_el_tabs = _resolveComponent("el-tabs");
  return _ctx.data ? (_openBlock(), _createBlock(_component_el_tabs, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
      label: "Hanke"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form, null, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "Hanke"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _ctx.data.nimi,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.data.nimi = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "Työnumero"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _ctx.data.tyonumero,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.data.tyonumero = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "Hankenumero"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _ctx.data.hankenumero,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.data.hankenumero = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "Arkistoitu"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_switch, {
            modelValue: _ctx.data.arkistoitu,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.data.arkistoitu = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _ctx.data.sairaala ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          label: "Sairaala"
        }, {
          default: _withCtx(() => [_ctx.sairaalat ? (_openBlock(), _createBlock(_component_el_select, {
            key: 0,
            modelValue: _ctx.data.sairaala.id,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.data.sairaala.id = $event),
            placeholder: "Valitse sairaala"
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sairaalat, item => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.nimi,
                value: item.id
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])) : _createCommentVNode("", true)]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_ComSuccessButton, {
            onClick: _cache[5] || (_cache[5] = $event => _ctx.data ? _ctx.updateHanke(_ctx.data) : '')
          }, {
            default: _withCtx(() => [_createTextVNode("Tallenna")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "Vastuuyksiköt"
    }, {
      default: _withCtx(() => [_ctx.data.vastuuyksikot ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList(_ctx.data.vastuuyksikot, vastuuyksikko => {
        return _openBlock(), _createBlock(_component_ComYllapitoHankeVastuuyksikko, {
          key: vastuuyksikko.id,
          vastuuyksikko: vastuuyksikko
        }, null, 8, ["vastuuyksikko"]);
      }), 128)) : _createCommentVNode("", true), _createVNode(_component_el_row, null, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_ComYllapitoHankeVastuuyksikkoAdd)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "Pääkäyttäjät"
    }, {
      default: _withCtx(() => [_createVNode(_component_ComYllapitoHankePaakayttajat)]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true);
}