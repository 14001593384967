import adminService from '@/methods/adminService';
import Oikeudet from '@/types/OikeudetEnum';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    oikeus: {
      type: Object
    },
    kayttaja: {
      type: Number,
      required: true
    },
    vastuuyksikko: {
      type: Number,
      required: true
    }
  },
  emits: ["changeOikeus", "addOikeus", "deleteOikeus"],
  setup(props, {
    emit
  }) {
    const oikeudet = Oikeudet;
    const changeOikeus = (oikeus, type) => {
      if (!oikeus) {
        const request = {
          kayttaja: {
            id: props.kayttaja
          },
          vastuuyksikko: {
            id: props.vastuuyksikko
          },
          luku: true,
          kirjoitus: type == Oikeudet.Kirjoitus ? true : false,
          raportointi: type == Oikeudet.Raportointi || Oikeudet.Kirjoitus ? true : false
        };
        adminService.methods.addOikeus(request).then(response => {
          if (response) {
            emit("addOikeus", response);
          }
        });
      } else {
        const oikeusUpdated = {
          ...oikeus
        };
        if (type == Oikeudet.Luku) {
          if (oikeus.luku) {
            adminService.methods.deleteOikeus(oikeus.id).then(response => {
              if (response) {
                emit("deleteOikeus", oikeus);
              }
            });
            return;
          }
        }
        if (type == Oikeudet.Kirjoitus) {
          oikeusUpdated.kirjoitus = !oikeus.kirjoitus;
          if (!oikeus.kirjoitus) {
            oikeusUpdated.raportointi = true;
          }
        }
        if (type == Oikeudet.Raportointi) {
          oikeusUpdated.raportointi = !oikeus.raportointi;
        }
        adminService.methods.updateOikeus(oikeus.id, adminService.methods.mapProperties(oikeusUpdated)).then(response => {
          if (response) {
            emit("changeOikeus", response);
          }
        });
      }
    };
    return {
      changeOikeus,
      oikeudet
    };
  }
});