/* tslint:disable */
/* eslint-disable */
/**
 * Vahti API
 * Vahti vaatimustenhallintajärjestelmän API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jani.saine@tietosund.fi
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Tilojen sähköluokka
 * @export
 * @enum {string}
 */

export enum HuonekorttiSahkoluokka {
    G0 = 'G0',
    G1 = 'G1',
    G2 = 'G2'
}


