import { defineComponent, inject, ref, watch } from 'vue';
import { HuonekorttiSahkoluokka } from '@/api/models/huonekortti-sahkoluokka';
import ToimintaAika from '@/types/ToimintaAikaEnum';
import ComCardExpand from '../common/ComCardExpand.vue';
import { provideHuonekortti, provideHuonekorttiIsEdited } from '@/types/Symbols';
import huonekorttiAPI from '@/apis/huonekorttiAPI';
import { ElMessage } from 'element-plus';
import ComEditor from '../common/ComEditor.vue';
import ComOhjeSahkoluokka from '../ohjeet/ComOhjeSahkoluokka.vue';
import { isEqual } from 'lodash';
export default defineComponent({
  components: {
    ComCardExpand,
    ComEditor,
    ComOhjeSahkoluokka
  },
  setup() {
    const sahkoluokka = HuonekorttiSahkoluokka;
    const toimintaAika = ToimintaAika;
    const show = ref(true);
    const huonekorttiRequest = {};
    const writingRights = inject("writingRights");
    const huonekorttiId = inject("huonekorttiId");
    const huonekortti = inject(provideHuonekortti);
    if (!huonekortti) {
      throw new Error(`Huonekorttia ei voitu ladata!`);
    }
    const isEdited = inject(provideHuonekorttiIsEdited);
    if (!isEdited) {
      throw new Error("Injektointi epäonnistui!");
    }
    const huonekorttiOriginal = ref(Object.assign({}, huonekortti.value));
    const editorInit = {
      plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'],
      toolbar: 'undo redo | formatselect | ' + 'bold italic backcolor | alignleft aligncenter ' + 'alignright alignjustify | bullist numlist outdent indent | ' + 'removeformat | help',
      menubar: false
    };
    const tallenna = () => {
      Object.assign(huonekorttiRequest, huonekortti.value);
      huonekorttiAPI.updateHuonekortti(huonekorttiId.value, huonekorttiRequest).then(result => {
        ElMessage({
          showClose: true,
          message: "Huonekortti tallennettu",
          center: true,
          type: 'success'
        });
        huonekorttiOriginal.value = Object.assign({}, result);
        isEdited.value.toimintatiedot = false;
      });
    };
    watch(() => huonekortti, () => {
      if (huonekortti.value) {
        if (!isEqual(huonekortti.value.henkilomaaraMax, huonekorttiOriginal.value.henkilomaaraMax) || !isEqual(huonekortti.value.henkilomaaraNorm, huonekorttiOriginal.value.henkilomaaraNorm) || !isEqual(huonekortti.value.toimintaAika, huonekorttiOriginal.value.toimintaAika) || !isEqual(huonekortti.value.sahkoluokka, huonekorttiOriginal.value.sahkoluokka) || !isEqual(huonekortti.value.toiminnanKuvaus, huonekorttiOriginal.value.toiminnanKuvaus) || !isEqual(huonekortti.value.toiminnanVaatimukset, huonekorttiOriginal.value.toiminnanVaatimukset)) {
          isEdited.value.toimintatiedot = true;
        } else {
          isEdited.value.toimintatiedot = false;
        }
      }
    }, {
      deep: true
    });
    return {
      sahkoluokka,
      toimintaAika,
      huonekortti,
      show,
      editorInit,
      writingRights,
      tallenna
    };
  }
});