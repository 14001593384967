import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_ComCancelButton = _resolveComponent("ComCancelButton");
  const _component_el_space = _resolveComponent("el-space");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.show = $event),
    close: "close",
    width: "30%",
    title: "Haluatko luoda huonekortista mallihuonekortin hyvinvointialueen hankkeisiin?"
  }, {
    footer: _withCtx(() => [_createVNode(_component_el_space, null, {
      default: _withCtx(() => [_createVNode(_component_ComSuccessButton, {
        onClick: _ctx.save
      }, {
        default: _withCtx(() => [_createTextVNode("Ok")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_ComCancelButton, {
        onClick: _ctx.close
      }, {
        default: _withCtx(() => [_createTextVNode("Peruuta")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Täsmennys"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.toiminta,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.toiminta = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]);
}