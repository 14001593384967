import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComHuonekorttiHistoria = _resolveComponent("ComHuonekorttiHistoria");
  const _component_ComEmpty = _resolveComponent("ComEmpty");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_ComHuonekorttiNimikeHistoria = _resolveComponent("ComHuonekorttiNimikeHistoria");
  const _component_ComTilaHistoria = _resolveComponent("ComTilaHistoria");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_com_loading = _resolveComponent("com-loading");
  return _ctx.data ? (_openBlock(), _createBlock(_component_el_tabs, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
      label: "Toimintatiedot"
    }, {
      default: _withCtx(() => [_ctx.data.huonekortti && _ctx.data.huonekortti.length > 0 ? (_openBlock(), _createBlock(_component_ComHuonekorttiHistoria, {
        key: 0,
        data: _ctx.data.huonekortti
      }, null, 8, ["data"])) : (_openBlock(), _createBlock(_component_ComEmpty, {
        key: 1
      }))]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "Suunnittelutiedot"
    }, {
      default: _withCtx(() => [_ctx.data.nimikkeet && _ctx.data.nimikkeet.length > 0 ? (_openBlock(), _createBlock(_component_ComHuonekorttiNimikeHistoria, {
        key: 0,
        data: _ctx.data.nimikkeet
      }, null, 8, ["data"])) : (_openBlock(), _createBlock(_component_ComEmpty, {
        key: 1
      }))]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "Tilat"
    }, {
      default: _withCtx(() => [_ctx.data.tilat && _ctx.data.tilat.length > 0 ? (_openBlock(), _createBlock(_component_ComTilaHistoria, {
        key: 0,
        data: _ctx.data.tilat
      }, null, 8, ["data"])) : (_openBlock(), _createBlock(_component_ComEmpty, {
        key: 1
      }))]),
      _: 1
    })]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_com_loading, {
    key: 1
  }));
}