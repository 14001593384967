import { defineComponent, ref } from 'vue';
import ComCommentButton from '../common/buttons/ComCommentButton.vue';
import ComKommentit from './ComKommentit.vue';
export default defineComponent({
  components: {
    ComCommentButton,
    ComKommentit
  },
  props: {
    viite: {
      type: Number,
      required: true
    },
    tyyppi: {
      type: Number,
      required: true
    }
  },
  emits: ["kommenttiChange"],
  setup(props, {
    emit
  }) {
    const showDrawer = ref(false);
    const kommenttiChange = val => {
      emit("kommenttiChange", val);
    };
    return {
      showDrawer,
      kommenttiChange
    };
  }
});