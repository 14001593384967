import "core-js/modules/es.array.push.js";
import { computed, defineComponent, watch } from 'vue';
import ComCardExpand from '@/components/common/ComCardExpand.vue';
import hankeListingService from '@/methods/hankeListingService';
import useSWRV from 'swrv';
import ComLoading from '../common/ComLoading.vue';
import { DocumentCopy } from '@element-plus/icons-vue';
import router from '@/router';
import ComCard from '../common/ComCard.vue';
export default defineComponent({
  name: "EtusivuHankkeet",
  components: {
    ComCardExpand,
    ComLoading,
    DocumentCopy,
    ComCard
  },
  setup() {
    const {
      key,
      fetcher,
      options,
      methods,
      getters
    } = hankeListingService;
    const {
      data
    } = useSWRV(() => key.value, fetcher, options);
    const props = {
      label: "nimi"
    };
    const arkistoidut = computed({
      get() {
        const arkistoitu = getters.getArkistoitu();
        if (arkistoitu === undefined) {
          return false;
        } else {
          return arkistoitu;
        }
      },
      set(val) {
        methods.setArkistoidut(val);
      }
    });
    const hankeLink = val => {
      router.push({
        name: "Hanke",
        params: {
          hankeId: val
        }
      });
    };
    watch(arkistoidut, n => {
      methods.setArkistoidut(n);
    });
    return {
      data,
      props,
      arkistoidut,
      hankeLink
    };
  }
});