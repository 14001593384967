import { computed, defineComponent, ref } from 'vue';
import ComLoading from '../common/ComLoading.vue';
import ComYllapitoHankeTableVastuuyksikot from './ComYllapitoHankeTableVastuuyksikot.vue';
import ComCard from '../common/ComCard.vue';
import ComYllapitoHankeEdit from './ComYllapitoHankeEdit.vue';
import { useYllapitoHanke } from './yllapitoHankeComposables';
import ComYllapitoHankeLisaa from './ComYllapitoHankeLisaa.vue';
import ComSuccessButton from '../common/buttons/ComSuccessButton.vue';
export default defineComponent({
  components: {
    ComLoading,
    ComYllapitoHankeTableVastuuyksikot,
    ComCard,
    ComYllapitoHankeEdit,
    ComYllapitoHankeLisaa,
    ComSuccessButton
  },
  setup() {
    const {
      hankkeet: data,
      showArchived,
      selectedHanke,
      setSelectedHanke
    } = useYllapitoHanke();
    const showEdit = ref(false);
    const showAdd = ref(false);
    const show = computed(() => showEdit.value || showAdd.value);
    const handleEdit = row => {
      setSelectedHanke(row.id);
      showEdit.value = true;
    };
    return {
      data,
      showEdit,
      showAdd,
      show,
      selectedHanke,
      showArchived,
      handleEdit
    };
  }
});