import { defineComponent, toRef, watch, inject, ref, onBeforeMount } from 'vue';
import { useVModel } from '@vueuse/core';
import hankeApi from '@/apis/hankeApi';
import { ElMessage } from 'element-plus';
import { useMallihuonekortit } from '@/components/mallihuonekortit/mallihuonekortitComposables';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';
export default defineComponent({
  components: {
    ComSuccessButton,
    ComCancelButton
  },
  props: {
    huonekortti: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:dialog'],
  setup(props, {
    emit
  }) {
    const toiminta = toRef(props.huonekortti.toiminta);
    const huonekorttiId = toRef(props.huonekortti.id);
    const show = useVModel(props, 'dialog', emit);
    const sairaalaId = ref();
    const mallihuonekortti = ref();
    const {
      add,
      update,
      mutate
    } = useMallihuonekortit(sairaalaId);
    const hankeId = inject("hankeId");
    const getHankeById = hankeApi.getHankeById;
    onBeforeMount(async () => {
      var _a, _b;
      const hanke = await getHankeById(hankeId.value);
      sairaalaId.value = (_b = (_a = hanke === null || hanke === void 0 ? void 0 : hanke.data) === null || _a === void 0 ? void 0 : _a.sairaala) === null || _b === void 0 ? void 0 : _b.id;
    });
    const save = async () => {
      await addMallihuonekortti(huonekorttiId.value);
      if (mallihuonekortti.value) {
        await updateMallihuonekortti();
        ElMessage.success("Mallihuonekortti lisätty!");
      }
      await mutate();
      close();
    };
    const updateMallihuonekortti = async () => {
      var _a, _b;
      const request = {
        ...mallihuonekortti.value,
        toiminta: toiminta.value
      };
      if ((_a = mallihuonekortti.value) === null || _a === void 0 ? void 0 : _a.id) {
        await update((_b = mallihuonekortti.value) === null || _b === void 0 ? void 0 : _b.id, request);
      }
    };
    const addMallihuonekortti = async id => mallihuonekortti.value = await add({
      id: id
    });
    const close = () => show.value = false;
    watch(() => props.huonekortti, () => {
      toiminta.value = props.huonekortti.toiminta;
      huonekorttiId.value = props.huonekortti.id;
    });
    return {
      toiminta,
      show,
      close,
      save
    };
  }
});