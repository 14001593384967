import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_ComSelect = _resolveComponent("ComSelect");
  return _openBlock(), _createBlock(_component_ComSelect, {
    modelValue: _ctx.valitutKayttajat,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.valitutKayttajat = $event),
    multiple: "",
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    "value-key": "email",
    placeholder: "Kirjoita ja valitse",
    loading: _ctx.loading,
    "remote-method": _ctx.loadKayttajat,
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kayttajat, kayttaja => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: kayttaja,
        label: kayttaja.email,
        value: kayttaja
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "loading", "remote-method"]);
}