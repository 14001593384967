import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_ComLisaaKayttajaOikeudet = _resolveComponent("ComLisaaKayttajaOikeudet");
  const _component_ComSuccessButton = _resolveComponent("ComSuccessButton");
  const _component_ComCancelButton = _resolveComponent("ComCancelButton");
  const _component_el_space = _resolveComponent("el-space");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "formRef",
    model: _ctx.kayttaja,
    rules: _ctx.formRules,
    "label-width": "120px",
    "inline-message": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "Sähköpostiosoite",
      prop: "email"
    }, {
      default: _withCtx(() => [!_ctx.kayttajaEdit ? (_openBlock(), _createBlock(_component_el_autocomplete, {
        key: 0,
        modelValue: _ctx.kayttaja.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.kayttaja.email = $event),
        "fetch-suggestions": _ctx.emailSuggestions,
        onSelect: _ctx.suggestionSelected,
        "value-key": "email",
        style: {
          "width": "100%"
        },
        size: "small"
      }, null, 8, ["modelValue", "fetch-suggestions", "onSelect"])) : (_openBlock(), _createBlock(_component_el_input, {
        key: 1,
        modelValue: _ctx.kayttaja.email,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.kayttaja.email = $event),
        style: {
          "width": "100%"
        },
        size: "small"
      }, null, 8, ["modelValue"]))]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Etunimi",
      prop: "etunimi"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        size: "small",
        modelValue: _ctx.kayttaja.etunimi,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.kayttaja.etunimi = $event),
        disabled: _ctx.selected != undefined
      }, null, 8, ["modelValue", "disabled"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "Sukunimi",
      prop: "sukunimi"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        size: "small",
        modelValue: _ctx.kayttaja.sukunimi,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.kayttaja.sukunimi = $event),
        disabled: _ctx.selected != undefined
      }, null, 8, ["modelValue", "disabled"])]),
      _: 1
    }), !_ctx.kayttajaEdit ? (_openBlock(), _createBlock(_component_el_form_item, {
      key: 0,
      label: "Lisää oikeudet"
    }, {
      default: _withCtx(() => [_createVNode(_component_ComLisaaKayttajaOikeudet, {
        onOikeudetChange: _cache[4] || (_cache[4] = val => _ctx.oikeudet = val)
      })]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_space, {
        wrap: ""
      }, {
        default: _withCtx(() => [!_ctx.selected && !_ctx.kayttajaEdit ? (_openBlock(), _createBlock(_component_ComSuccessButton, {
          key: 0,
          onClick: _ctx.addKayttajaClick
        }, {
          default: _withCtx(() => [_createTextVNode("Tallenna")]),
          _: 1
        }, 8, ["onClick"])) : (_openBlock(), _createBlock(_component_ComSuccessButton, {
          key: 1,
          onClick: _ctx.updateKayttajaClick
        }, {
          default: _withCtx(() => [_createTextVNode("Päivitä")]),
          _: 1
        }, 8, ["onClick"])), _createVNode(_component_ComCancelButton, {
          onClick: _ctx.closeModalClick
        }, {
          default: _withCtx(() => [_createTextVNode("Peruuta")]),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"]);
}