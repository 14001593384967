import adminApi from "@/apis/adminApi"
import useSWRV from "swrv"

const methods = {  
    getSairaalat() {
        const key = "/sairaalat"
        const fetcher = (key : string) => {
            return adminApi.getSairaalat()
        }

        const { data, error, mutate } = useSWRV(key, fetcher)

        return { data, error, mutate }
    }
}

export default {
    methods
}