import http from "@/http-common";
import { errorHandler }  from "./baseApi";
import {AxiosPromise, AxiosResponseHeaders } from "axios";
import { HuonekorttiResponse } from '../api/models/huonekortti-response'
import { NimikeResponse } from "@/api/models/nimike-response";
import { HuonekorttiNimikeRequest } from "@/api/models/huonekortti-nimike-request";
import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response";
import { KategoriaResponse } from "@/api/models/kategoria-response";
import { HuonekorttiRequestPut } from "@/api/models/huonekortti-request-put";
import { HuonekorttiNimikeRequestPut } from "@/api/models/huonekortti-nimike-request-put";
import { TilanimikeResponse } from "@/api/models/tilanimike-response";
import { HuonekorttiRequest } from "@/api/models/huonekortti-request";
import { HuonekorttiStatusResponse } from "../api/models/huonekortti-status-response";
import { HuonekorttiAndNimikeHistoriaResponse } from "@/api/models/huonekortti-and-nimike-historia-response";
import { HuonekorttiId } from "@/api/models/huonekortti-id";
import { TilaRequest } from "@/api/models/tila-request";

class HuonekorttiAPI {

    getHuonekortti(id : number) : Promise<HuonekorttiResponse> {
        return http.get(`huonekortit/${id}`).then((response) => {
            return response.data
        }).catch(error => {
            errorHandler(error)
        })
    }

    getHuonekortit(url : string) : Promise<{ data: HuonekorttiResponse[]; headers: AxiosResponseHeaders; }> {
        return http.get(url).then(response => {
            return {
                data: response.data, 
                headers: response.headers as AxiosResponseHeaders
            }
        })
    }

    addHuonekortti(huonekortti : HuonekorttiRequest) : Promise<HuonekorttiResponse> {
        return http.post(`huonekortit`, huonekortti)
            .then(response => response.data)
            .catch(error => errorHandler(error))
    }

    addHuonekorttiFromMallihuonekortti(vastuuyksikkoId : number, mallihuonekorttiId : number, tilat? : TilaRequest[]) : Promise <HuonekorttiResponse | undefined> {
        return http.post("huonekortit/mallihuonekortti", tilat, {
            params: {
                vastuuyksikkoId: vastuuyksikkoId,
                mallihuonekorttiId: mallihuonekorttiId
            }
        })
        .then(response => response.data)
        .catch(error => errorHandler(error))
    }

    updateHuonekortti(id : number, huonekortti : HuonekorttiRequestPut) : Promise<HuonekorttiResponse> {
        return http.put(`huonekortit/${id}`, huonekortti).then(response => { 
            return response.data 
        }).catch(error => { 
            errorHandler(error) 
        })
    }

    deleteHuonekortti(id : number) {
        return http.delete(`huonekortit/${id}`).then(() => { 
            return true 
        }).catch((error) =>  {
            errorHandler(error)

            return false
        })
    }

    unDeleteHuonekortti(id : number) : Promise<boolean> {
        return http.post(`huonekortit/${id}/palauta`).then(() => {
            return true
        }).catch((error) => {
            errorHandler(error)

            return false
        })
    }

    julkaiseHuonekortti(id : number) : Promise<HuonekorttiStatusResponse> {
        return http.post(`huonekortit/${id}/julkaise`).then((response) => {
            return response.data
        }).catch((error) => {
            errorHandler(error)
        })
    }

    hyvaksytaHuonekortti(id : number) : Promise<HuonekorttiStatusResponse> {
        return http.post(`huonekortit/${id}/hyvaksyta`).then((response) => {
            return response.data
        }).catch((error) => {
            errorHandler(error)
        })
    }

    hyvaksyHuonekortti(id : number) : Promise<HuonekorttiStatusResponse> {
        return http.post(`huonekortit/${id}/hyvaksy`).then((response) => {
            return response.data
        }).catch((error) => {
            errorHandler(error)
        })
    }
    
    hylkaaHuonekortti(id : number) : Promise<HuonekorttiStatusResponse> {
        return http.post(`huonekortit/${id}/hylkaa`).then((response) => {
            return response.data
        }).catch((error) => {
            errorHandler(error)
        })
    }

    getNimikkeet() : AxiosPromise<NimikeResponse> {
        return http.get(`nimikkeet`).then((response) => {
            return response
        })
    }

    getNimikkeetByKategoriaId(id : number) : AxiosPromise<NimikeResponse> {
        return http.get(`nimikkeet`,{params:{kategoria: id} }).then((response) => {
            return response
        })
    }

    getNimikeKategoriat(nimikkeet? : boolean) : Promise<KategoriaResponse[]> {
        return http.get("nimikkeet/kategoriat",
        {
            params:
            {
                nimikkeet : nimikkeet
            }
        }).then((response) => {
            return response.data
        }).catch((error) => {
            errorHandler(error)
        })
    }

    addNimike(nimike : HuonekorttiNimikeRequest, id : number) : AxiosPromise<HuonekorttiNimikeResponse> {
        return http.post(`huonekortit/${id}/nimikkeet`, nimike)
    }

    updateNimike(id: number, nimike : HuonekorttiNimikeRequestPut) : AxiosPromise<HuonekorttiNimikeResponse> {
    return http.put(`huonekortit/nimikkeet/${id}`, nimike)
    }

    deleteNimike(id : number) : AxiosPromise<void> {
        return http.delete(`huonekortit/nimikkeet/${id}`)
    }

    getTilanimikkeet() : Promise<TilanimikeResponse[]> {
        return http.get("tilanimikkeet").then((response) => { return response.data })
    }

    getHistoria(url : string) : Promise<HuonekorttiAndNimikeHistoriaResponse> {
        return http.get(url).then(response => response.data)
            .catch(error => errorHandler(error))
    }

    importHuonekortit(id : number, huonekortit : HuonekorttiId[]) : AxiosPromise<void> {
        return http.post(`vastuuyksikot/${id}/tuo`, huonekortit)
    }

}

export default new HuonekorttiAPI
