import { computed, defineComponent } from 'vue';
import hankeListingService from '@/methods/hankeListingService';
import useSWRV from 'swrv';
import ComSideMenuVastuuyksikko from '@/components/layout/SideMenu/ComSideMenuVastuuyksikko.vue';
import ComTooltip from '@/components/common/ComTooltip.vue';
export default defineComponent({
  components: {
    ComSideMenuVastuuyksikko,
    ComTooltip
  },
  setup() {
    const {
      fetcher,
      options,
      getters
    } = hankeListingService;
    const {
      data
    } = useSWRV(getters.getKey(true), fetcher, options);
    const hankkeet = computed(() => {
      var _a;
      return (_a = data.value) === null || _a === void 0 ? void 0 : _a.map(h => {
        var _a;
        return {
          id: h.id,
          nimi: h.nimi,
          children: (_a = h.vastuuyksikot) === null || _a === void 0 ? void 0 : _a.map(v => {
            return {
              id: v.id,
              nimi: v.nimi
            };
          })
        };
      });
    });
    return {
      hankkeet,
      data
    };
  }
});