import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import hankeService from '@/methods/hankeService';
import { provideHanke, provideHuonekortti, provideSelectedVastuuyksikot } from '@/types/Symbols';
import { ElMessage } from 'element-plus';
import { computed, defineComponent, inject, ref, watchEffect } from 'vue';
export default defineComponent({
  components: {
    ComSuccessButton,
    ComCancelButton
  },
  props: {
    editable: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const updateHanke = hankeService.methods.updateHanke;
    const mapRequest = hankeService.methods.mapResponseToRequest;
    const hanke = inject(provideHanke);
    const huonekortti = inject(provideHuonekortti, undefined);
    const vastuuyksikot = inject(provideSelectedVastuuyksikot);
    const hankeRequest = ref();
    const edit = ref(false);
    const vastuuyksikko = computed(() => vastuuyksikot === null || vastuuyksikot === void 0 ? void 0 : vastuuyksikot.value[0]);
    const tallennaHankeClick = async () => {
      if ((hanke === null || hanke === void 0 ? void 0 : hanke.value) && hankeRequest.value) {
        const response = await updateHanke(hanke === null || hanke === void 0 ? void 0 : hanke.value.id, hankeRequest.value);
        if (response) {
          ElMessage.success("Hanketiedot tallennettu!");
          hanke.value.nimi = response.nimi;
          hanke.value.hankenumero = response.hankenumero;
          hanke.value.tyonumero = response.tyonumero;
          edit.value = false;
        }
      }
    };
    watchEffect(() => {
      if (hanke === null || hanke === void 0 ? void 0 : hanke.value) {
        hankeRequest.value = mapRequest(hanke.value);
      }
    });
    return {
      hankeRequest,
      edit,
      hanke,
      huonekortti,
      vastuuyksikko,
      tallennaHankeClick
    };
  }
});