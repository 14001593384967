import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComTilaHistoriaItem = _resolveComponent("ComTilaHistoriaItem");
  const _component_el_timeline = _resolveComponent("el-timeline");
  return _openBlock(), _createBlock(_component_el_timeline, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historia, muutos => {
      return _openBlock(), _createBlock(_component_ComTilaHistoriaItem, {
        key: muutos.id,
        previousData: muutos,
        data: _ctx.getCurrentData(muutos)
      }, null, 8, ["previousData", "data"]);
    }), 128))]),
    _: 1
  });
}