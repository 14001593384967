import "core-js/modules/es.array.push.js";
import { computed, defineComponent, inject, reactive, ref, watch } from 'vue';
import huonekorttiAPI from '@/apis/huonekorttiAPI';
import nimikeKategoriaService from '@/methods/nimikeKategoriaService';
import { provideHuonekorttiIsEdited, provideNimikkeet } from '../../types/Symbols';
import { ElMessage } from 'element-plus';
export default defineComponent({
  components: {},
  props: {
    nimikkeet: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const isEdited = inject(provideHuonekorttiIsEdited);
    if (!isEdited) {
      throw new Error("Injektointi epäonnistui!");
    }
    const kategoriaOhje = ref([]);
    const ominaisuusOhje = ref([]);
    const ominaisuudet = ref([]);
    const ominaisuudetChecked = ref([]);
    const nimikeForm = ref();
    const nimikeKategoriaMethods = nimikeKategoriaService.methods;
    const huonekorttiId = inject("huonekorttiId");
    const nimikkeet = inject(provideNimikkeet);
    const nimike = reactive({
      nimike: {
        id: undefined
      }
    });
    const rules = ref({
      nimike: [{
        type: "object",
        required: true,
        trigger: "change",
        fields: {
          id: {
            type: "any",
            required: true,
            message: "Nimike tulee olla valittu."
          }
        }
      }],
      maara: [{
        type: "integer",
        trigger: "blur",
        required: true,
        min: 0,
        message: "Kappalemäärä tulee olla 0 tai enemmän."
      }]
    });
    const nodes = computed(() => {
      return nimikeKategoriaMethods.setNodes(props.nimikkeet);
    });
    const ohje = computed(() => {
      let message = "";
      if (kategoriaOhje.value.length !== 0 || ominaisuusOhje.value.length !== 0) {
        message = "<ul>";
        kategoriaOhje.value.forEach(k => {
          if (k !== null && k.trim() !== '') message = `${message} <li>${k}</li>`;
        });
        ominaisuusOhje.value.forEach(o => {
          if (o !== null && o.trim() !== '') message = `${message} <li>${o}</li>`;
        });
        message = message + "</ul>";
        return message;
      } else {
        return false;
      }
    });
    const filter = (node, keyword) => {
      var _a, _b, _c;
      const nimike = node.label.toLowerCase().includes(keyword.toLocaleLowerCase());
      const aliKategoria = (_a = node.parent) === null || _a === void 0 ? void 0 : _a.label.toLowerCase().includes(keyword.toLocaleLowerCase());
      const kategoria = (_c = (_b = node.parent) === null || _b === void 0 ? void 0 : _b.parent) === null || _c === void 0 ? void 0 : _c.label.toLowerCase().includes(keyword.toLocaleLowerCase());
      if (nimike || aliKategoria || kategoria) {
        return true;
      } else {
        return false;
      }
    };
    const nimikeKategoriaChange = value => {
      let o;
      nimikeKategoriaExpandChange();
      if (value) {
        if (props.nimikkeet) {
          o = props.nimikkeet.find(a => a.id === value[0]);
          if (o.ohje !== undefined && "ohje" in o && !!o.ohje) kategoriaOhje.value.push(o.ohje);
        }
        if (o !== undefined && "children" in o) {
          o = o.children.find(b => b.id === value[1]);
          if (o && o.ohje !== undefined && "ohje" in o && !!o.ohje) kategoriaOhje.value.push(o.ohje);
        }
        if (o !== undefined && "nimikkeet" in o) {
          o = o.nimikkeet.find(c => c.id === value[2]);
          if (o.ohje !== undefined && "ohje" in o && !!o.ohje) kategoriaOhje.value.push(o.ohje);
        }
        if (o !== undefined && "ominaisuudet" in o) ominaisuudet.value = o.ominaisuudet.filter(d => d.kaytossa === true);
      }
    };
    const nimikeKategoriaExpandChange = () => {
      ominaisuudet.value = [];
      ominaisuudetChecked.value = [];
      kategoriaOhje.value = [];
      ominaisuusOhje.value = [];
    };
    const ominaisuudetChange = value => {
      ominaisuusOhje.value = [];
      value.forEach(v => {
        if (ominaisuudet.value.length !== 0) {
          const o = ominaisuudet.value.find(o => {
            return o.id === v;
          });
          if (typeof o === 'object' && "ohje" in o && !!o.ohje) ominaisuusOhje.value.push(o.ohje);
        }
      });
    };
    const addNimike = () => {
      if (nimike.nimike.id !== undefined && nimike.nimike.id[2] !== undefined) {
        nimike.nimike.id = nimike.nimike.id[2];
      }
      nimikeForm.value.validate(valid => {
        if (!valid) return false;else {
          nimike.ominaisuudet = ominaisuudetChecked.value.map(o => {
            return {
              id: o
            };
          });
          huonekorttiAPI.addNimike(nimike, huonekorttiId.value).then(response => {
            if (nimikkeet != undefined) nimikkeet.value.push(response.data);
            resetFields();
            successMessage("Nimike lisätty");
          });
        }
      });
    };
    const resetFields = () => {
      nimike.maara = undefined;
      nimike.tasmennys = "";
      nimike.nimike = {
        id: undefined
      };
      nimike.ominaisuudet = [];
      nimikeKategoriaExpandChange();
    };
    const successMessage = message => {
      ElMessage({
        showClose: true,
        message: message,
        center: true,
        type: 'success'
      });
    };
    watch(() => nimike, () => {
      if (nimike.maara || nimike.nimike.id || nimike.tasmennys) {
        isEdited.value.nimikeLisays = true;
      } else {
        isEdited.value.nimikeLisays = false;
      }
    }, {
      deep: true
    });
    return {
      nodes,
      ominaisuudet,
      ominaisuudetChecked,
      ohje,
      nimike,
      rules,
      nimikeForm,
      nimikeKategoriaChange,
      nimikeKategoriaExpandChange,
      ominaisuudetChange,
      filter,
      addNimike
    };
  }
});