import { defineComponent } from 'vue';
import ComSuccessButton from '../common/buttons/ComSuccessButton.vue';
import ComYllapitoHankeVastuuyksikko from './ComYllapitoHankeVastuuyksikko.vue';
import { useYllapitoHanke } from './yllapitoHankeComposables';
import { useCloned } from '@vueuse/core';
import ComYllapitoHankeVastuuyksikkoAdd from './ComYllapitoHankeVastuuyksikkoAdd.vue';
import ComYllapitoHankePaakayttajat from './ComYllapitoHankePaakayttajat.vue';
export default defineComponent({
  setup() {
    const {
      updateHanke,
      selectedHanke,
      sairaalat
    } = useYllapitoHanke();
    const {
      cloned: data
    } = useCloned(selectedHanke);
    return {
      data,
      sairaalat,
      updateHanke
    };
  },
  components: {
    ComYllapitoHankeVastuuyksikko,
    ComSuccessButton,
    ComYllapitoHankeVastuuyksikkoAdd,
    ComYllapitoHankePaakayttajat
  }
});