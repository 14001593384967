import { defineComponent, ref } from 'vue';
import { ArrowDown, ArrowRight, ArrowLeft, ArrowUp } from '@element-plus/icons-vue';
export default defineComponent({
  components: {
    ArrowDown,
    ArrowRight,
    ArrowLeft,
    ArrowUp
  },
  props: {
    show: {
      required: false,
      type: Boolean,
      default: false
    },
    way: {
      required: true,
      type: String
    }
  },
  emits: ['expand'],
  setup(props, context) {
    const h = ref(props.show);
    const expand = () => {
      h.value = !h.value;
      context.emit('expand', h.value);
    };
    return {
      h,
      expand
    };
  }
});