import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComInfoButton = _resolveComponent("ComInfoButton");
  const _component_el_popover = _resolveComponent("el-popover");
  return _openBlock(), _createBlock(_component_el_popover, {
    title: _ctx.title,
    trigger: "click",
    width: "30%"
  }, {
    reference: _withCtx(() => [_createVNode(_component_ComInfoButton)]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["title"]);
}