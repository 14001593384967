import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComHeaderMenu = _resolveComponent("ComHeaderMenu");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_card = _resolveComponent("el-card");
  return _openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      align: "middle"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 24
      }, {
        default: _withCtx(() => [_createVNode(_component_ComHeaderMenu)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}