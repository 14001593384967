import "core-js/modules/es.array.push.js";
import { ElMessage } from 'element-plus';
import { computed, defineComponent, inject, ref, watch, watchEffect } from 'vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';
import adminService from '@/methods/adminService';
import ComLisaaKayttajaOikeudet from './ComLisaaKayttajaOikeudet.vue';
import { provideHanke } from '@/types/Symbols';
import kayttajaService from '@/methods/kayttajaService';
export default defineComponent({
  components: {
    ComSuccessButton,
    ComCancelButton,
    ComLisaaKayttajaOikeudet
  },
  props: {
    kayttajaEdit: {
      type: Object,
      required: false
    }
  },
  emits: ["addKayttaja", "closeModal", "updateKayttaja"],
  setup(props, {
    emit
  }) {
    const hanke = inject(provideHanke);
    const getKayttajat = adminService.methods.getKayttajat;
    const addKayttaja = adminService.methods.addKayttaja;
    const updateKayttaja = adminService.methods.updateKayttaja;
    const addOikeudet = adminService.methods.addOikeudet;
    const mapRequest = kayttajaService.methods.mapKayttajaResponseToRequestPut;
    const kayttaja = ref({});
    const oikeudet = ref();
    const suggestions = ref();
    const selected = ref();
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const formRef = ref();
    const formRules = ref({
      email: [{
        required: true,
        message: "Täytä Sähköpostiosoite kenttä!"
      }, {
        type: "email",
        pattern: emailRegex,
        message: "Epäkelpo sähköpostiosoite"
      }],
      etunimi: [{
        required: true,
        message: "Täytä etunimi kenttä!"
      }],
      sukunimi: [{
        required: true,
        message: "Täytä sukunimi kenttä!"
      }]
    });
    const sairaala = computed(() => {
      var _a;
      return (_a = hanke === null || hanke === void 0 ? void 0 : hanke.value) === null || _a === void 0 ? void 0 : _a.sairaala;
    });
    const emailSuggestions = (query, callback) => {
      selected.value = undefined;
      if (query.length > 2) {
        getKayttajat(query).then(response => {
          if (response) {
            const match = response.find(s => s.email == kayttaja.value.email);
            if (match) {
              suggestionSelected(match);
              callback([]);
            } else {
              suggestions.value = response;
              callback(response);
            }
          }
        });
      }
    };
    const suggestionSelected = val => {
      selected.value = val;
      kayttaja.value.etunimi = val.etunimi;
      kayttaja.value.sukunimi = val.sukunimi;
    };
    const addKayttajaClick = () => {
      var _a;
      (_a = formRef.value) === null || _a === void 0 ? void 0 : _a.validate().then(response => {
        var _a;
        if (response && sairaala) {
          kayttaja.value.sairaalat = [{
            sairaala: {
              id: (_a = sairaala.value) === null || _a === void 0 ? void 0 : _a.id
            },
            suunnittelija: false
          }];
          addKayttaja(kayttaja.value).then(response => {
            if (response) {
              setOikeudet(response.id);
              ElMessage.success("Käyttäjä lisätty!");
              resetFields();
            } else {
              ElMessage.error("Käyttäjän lisäys ei onnistunut!");
            }
          });
        }
      }).catch(() => {
        return;
      });
    };
    const updateKayttajaClick = async () => {
      var _a, _b;
      if (selected.value) {
        let request = {};
        if ((_a = selected.value.Sairaalat) === null || _a === void 0 ? void 0 : _a.some(s => {
          var _a, _b;
          return ((_a = s.sairaala) === null || _a === void 0 ? void 0 : _a.id) == ((_b = sairaala.value) === null || _b === void 0 ? void 0 : _b.id);
        })) {
          ElMessage.error("Käyttäjä on liitetty jo sairaalaan!");
        } else {
          if (sairaala.value) {
            request = mapRequest(selected.value);
            (_b = request.sairaalat) === null || _b === void 0 ? void 0 : _b.push({
              sairaala: {
                id: sairaala.value.id
              },
              suunnittelija: false
            });
            const response = await updateKayttaja(selected.value.id, request);
            if (response) {
              setOikeudet(response.id);
              resetFields();
            }
          }
        }
      } else if (props.kayttajaEdit) {
        const edit = JSON.parse(JSON.stringify(props.kayttajaEdit));
        edit.email = kayttaja.value.email;
        edit.etunimi = kayttaja.value.etunimi;
        edit.sukunimi = kayttaja.value.sukunimi;
        const request = mapRequest(edit);
        const response = await updateKayttaja(props.kayttajaEdit.id, request);
        if (response) {
          emit("updateKayttaja", response);
        }
      }
    };
    const setOikeudet = kayttajaId => {
      if (oikeudet.value) {
        oikeudet.value.map(o => o.kayttaja = {
          id: kayttajaId
        });
        addOikeudet(oikeudet.value).then(response => {
          if (response) {
            return;
          } else {
            ElMessage.error("Käyttäjäoikeuksien lisääminen ei onnistunut!");
          }
        }).finally(() => {
          emit("addKayttaja");
        });
      } else {
        emit("addKayttaja");
      }
    };
    const closeModalClick = () => {
      emit("closeModal");
    };
    const resetFields = () => {
      if (formRef.value) {
        formRef.value.resetFields();
      }
    };
    watch(() => kayttaja.value.email, n => {
      var _a;
      if (!props.kayttajaEdit) {
        (_a = formRef.value) === null || _a === void 0 ? void 0 : _a.validateField("email").then(response => {
          if (response && n) {
            const etunimi = n.substring(0, n.indexOf("."));
            const sukunimi = n.substring(n.indexOf(".") + 1, n.indexOf("@"));
            kayttaja.value.etunimi = etunimi.charAt(0).toUpperCase() + etunimi.slice(1);
            kayttaja.value.sukunimi = sukunimi.charAt(0).toUpperCase() + sukunimi.slice(1);
          }
        }).catch(() => {
          return;
        });
      }
    });
    watchEffect(() => {
      if (props.kayttajaEdit) {
        kayttaja.value = JSON.parse(JSON.stringify(props.kayttajaEdit));
      }
    });
    return {
      kayttaja,
      formRules,
      formRef,
      selected,
      oikeudet,
      emailSuggestions,
      suggestionSelected,
      addKayttajaClick,
      updateKayttajaClick,
      closeModalClick
    };
  }
});