import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ComTrashButton = _resolveComponent("ComTrashButton");
  const _component_ComRoskakoriContent = _resolveComponent("ComRoskakoriContent");
  const _component_el_drawer = _resolveComponent("el-drawer");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_ComTrashButton, {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.show = true)
  }), _ctx.show ? (_openBlock(), _createBlock(_component_el_drawer, {
    key: 0,
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.show = $event),
    "append-to-body": "",
    title: "Roskakori",
    size: "50%",
    "destroy-on-close": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_ComRoskakoriContent)]),
    _: 1
  }, 8, ["modelValue"])) : _createCommentVNode("", true)], 64);
}