import { HuonekorttiRequestPut } from "@/api/models/huonekortti-request-put"
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { TilaResponse } from "@/api/models/tila-response"
import huonekorttiAPI from "@/apis/huonekorttiAPI"
import { ElMessage } from "element-plus"

const methods = {
    async getHuonekortti(id : number) : Promise<HuonekorttiResponse | undefined> {
        const response = await huonekorttiAPI.getHuonekortti(id)

        if(response) {
            return response
        }
        else {
            ElMessage.error("Huonekortin lataaminen ei onnistunut!")
        }
    },

    async deleteHuonekortti(id : number) : Promise<boolean> {
        return await huonekorttiAPI.deleteHuonekortti(id).then(result => {
            return result
        })
        .catch((result) => {return result})
    },
    async unDeleteHuonekortti(id : number) {
        const result = await huonekorttiAPI.unDeleteHuonekortti(id)

        if(result) {
            return true
        }
        else {
            ElMessage.error("Huonekortin palauttaminen ei onnistunut!")
        }
    },
    async updateHuonekortti(id : number, request : HuonekorttiRequestPut) : Promise<HuonekorttiResponse | undefined> {
        const response = await huonekorttiAPI.updateHuonekortti(id, request).then(result => {return result})

        if(response) {
            return response
        }
        else {
            ElMessage.error("Huonekortin päivittäminen ei onnistunut!")
        }
    },
    formatTilat(tilat : TilaResponse[]) : string{
        if(tilat != undefined) {
            return tilat.map((t: any) => {
                return t.tilatunnus;
            }).join(", ");
        }
        else {
            return ""
        }
    },
    julkaiseHuonekortti(id : number) {
        return huonekorttiAPI.julkaiseHuonekortti(id)
    },
    hyvaksytaHuonekortti(id : number) {
        return huonekorttiAPI.hyvaksytaHuonekortti(id)
    },
    hyvaksyHuonekortti(id : number) {
        return huonekorttiAPI.hyvaksyHuonekortti(id)
    },
    hylkaaHuonekortti(id : number) {
        return huonekorttiAPI.hylkaaHuonekortti(id)
    }

}

export default { methods }
