import ComStatusTag from '@/components/common/ComStatusTag.vue';
import { provideHuonekortti } from '@/types/Symbols';
import { computed, defineComponent, inject } from 'vue';
export default defineComponent({
  components: {
    ComStatusTag
  },
  setup() {
    const huonekortti = inject(provideHuonekortti);
    const tilat = computed(() => {
      var _a;
      return (huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value) ? (_a = huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value.tilat) === null || _a === void 0 ? void 0 : _a.map(t => t.tilatunnus).join(", ") : "";
    });
    return {
      tilat,
      huonekortti
    };
  }
});