import { HuonekorttiStatusResponse } from '@/api/models/huonekortti-status-response';
import ComStatusTag from '@/components/common/ComStatusTag.vue';
import huonekorttiService from '@/methods/huonekorttiService';
import { provideHasPaakayttajaRights, provideWritableVastuuyksikot } from '@/types/Symbols';
import { computed, defineComponent, inject } from 'vue';
export default defineComponent({
  components: {
    ComStatusTag
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    huonekortti: {
      type: Object,
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const julkaiseHuonekortti = huonekorttiService.methods.julkaiseHuonekortti;
    const hyvaksytaHuonekortti = huonekorttiService.methods.hyvaksytaHuonekortti;
    const hyvaksyHuonekortti = huonekorttiService.methods.hyvaksyHuonekortti;
    const hylkaaHuonekortti = huonekorttiService.methods.hylkaaHuonekortti;
    const oikeudet = inject(provideWritableVastuuyksikot);
    const isPaakayttaja = inject(provideHasPaakayttajaRights);
    const readOnly = computed(() => {
      return (oikeudet === null || oikeudet === void 0 ? void 0 : oikeudet.value.find(o => o.id == props.huonekortti.vastuuyksikko.id)) ? false : true;
    });
    const julkaiseHuonekorttiClick = async () => {
      const response = await julkaiseHuonekortti(props.huonekortti.id);
      emitUpdate(response);
    };
    const hyvaksytaHuonekorttiClick = async () => {
      const response = await hyvaksytaHuonekortti(props.huonekortti.id);
      emitUpdate(response);
    };
    const hyvaksyHuonekorttiClick = async () => {
      const response = await hyvaksyHuonekortti(props.huonekortti.id);
      emitUpdate(response);
    };
    const hylkaaHuonekorttiClick = async () => {
      const response = await hylkaaHuonekortti(props.huonekortti.id);
      emitUpdate(response);
    };
    const emitUpdate = response => {
      if (response) {
        context.emit('update:modelValue', response);
      }
    };
    return {
      readOnly,
      HuonekorttiStatusResponse,
      isPaakayttaja,
      julkaiseHuonekorttiClick,
      hyvaksytaHuonekorttiClick,
      hyvaksyHuonekorttiClick,
      hylkaaHuonekorttiClick
    };
  }
});