import { reactive, readonly, computed} from "vue";
import huonekorttiAPI from "@/apis/huonekorttiAPI";
import Order from "@/types/OrderEnum";
import HuonekorttiSort from "@/types/HuonekorttiSortEnum";
import HuonekorttiListingParams from "@/types/HuonekorttiListingParams";
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response";

const state = reactive ({
    perPage: 30 as number,
    total: 0 as number,
    currentPage: 1 as number,
    sort: HuonekorttiSort.id as HuonekorttiSort,
    order: Order.asc as Order,
    vastuuyksikot: [] as number[],
    nimikkeet: false as boolean,
    tilanimike: "" as string,
    tilanimikeTasmennys: "" as string,
    tilatunnus: "" as string,
    status: [] as HuonekorttiStatusResponse[],
    poistettu: false,
    mutate: false
})

const url = "huonekortit"
const key = computed(() => {
    if(!state.vastuuyksikot || state.vastuuyksikot.length !== 0)
        return getters.getKey()
    else {
        return null
    }
})
const options = {
    dedupingInterval: 2000,
    revalidateOnFocus: false,
}

const methods = {
    setPerPage(val : number) : void {
        state.perPage = val
    },
    setCurrentPage(val : number) : void {
        state.currentPage = val
    },
    setTotalPagesFromHeader(val : any) : void {
        if(val) {
            state.total = parseInt(val["page-total-count"])
        }
        else {
            state.total = 0
        }
    },
    setSort(val : HuonekorttiSort) : void {
        state.sort = val
    },
    setOrder(val : Order) : void {
        state.order = val
    },
    setVastuuyksikot(val : number[]) : void {
        state.vastuuyksikot = val
    },
    setTilanimike(val : string) : void {
        state.tilanimike = val
    },
    setTilanimikeTasmennys(val : string) : void {
        state.tilanimikeTasmennys = val
    },
    setTilatunnus(val : string) : void {
        state.tilatunnus = val
    },
    setStatus(val : HuonekorttiStatusResponse[]) : void {
        state.status = val
    },
    setPoistettu(val : boolean) : void {
        state.poistettu = val
    },
    setAllFilters(val : string) : void {
        state.tilanimike = val
        state.tilanimikeTasmennys = val
        state.tilatunnus = val
    },
    setMutate(val : boolean) : void {
        state.mutate = val
    },
    loadKey(request : HuonekorttiListingParams) : string {

        const params = new URLSearchParams()

        if(request && request.vastuuyksikot) {
            params.set('vastuuyksikot',request.vastuuyksikot + '')
        }

        if(request && request.page) {
            params.set('page', request.page.toString())
        }

        if(request && request.perPage) {
            params.set('per_page', request.perPage.toString())
        }

        if(request && request.sort) {
            params.set('sort', request.sort.toString())
        }

        if(request && request.order) {
            const order = Order[request.order];

            params.set('order', order)
        }

        if(request && request.tilanimike) {
            params.set('tilanimike', request.tilanimike)
        }

        if(request && request.tilanimikeTasmennys) {
            params.set('tilanimike_tasmennys', request.tilanimikeTasmennys)
        }

        if(request && request.tilatunnus) {
            params.set('tilatunnus', request.tilatunnus)
        }

        if(request && request.nimikkeet) {
            params.set("nimikkeet", state.nimikkeet.toString())
        }

        if(request && request.status && request.status.length > 0) {
            params.set('status',request.status + '')
        }

        if(request && request.poistettu) {
            params.set("poistettu", request.poistettu.toString())
        }

        const urlString = url + "?" + params.toString()
        
        return urlString
    }
}

const getters = {
    getPerPage() : number {
        return state.perPage
    },
    getTotal() : number {
        return state.total
    },
    getCurrentPage() : number {
        return state.currentPage
    },  
    getVastuuyksikot() : number[] {
        return state.vastuuyksikot
    },
    getUrl() : string {
        return url
    },
    getMutate() : boolean {
        return state.mutate
    },
    getKey() : string {
        const key : HuonekorttiListingParams = {
            vastuuyksikot: state.vastuuyksikot,
            page: state.currentPage,
            perPage: state.perPage,
            sort: state.sort,
            order: state.order,
            tilanimike: state.tilanimike,
            tilanimikeTasmennys: state.tilanimikeTasmennys,
            tilatunnus: state.tilatunnus,
            nimikkeet: state.nimikkeet,
            status: state.status
        } 

        return methods.loadKey(key);
    }
}

const fetcher = (url : string) => {
    return huonekorttiAPI.getHuonekortit(url)
}

export default {
    state : readonly(state),
    methods,
    getters,
    fetcher,
    key,
    options
}