import { KayttajaResponse } from "@/api/models/kayttaja-response";
import kayttajaApi from "@/apis/kayttajaApi";
import { ElMessageBox, ElMessage } from "element-plus";
import useSWRV from "swrv";
import { createGlobalState } from "@vueuse/core";
import { ref } from "vue";

const key = "kayttaja"
const fetcher = () => {
    return kayttajaApi.getKirjautunutKayttaja()
}
const options = {
    dedupingInterval: 0,
    revalidateOnFocus: false,
}

const kayttajaState = createGlobalState(async () => {
    const kayttaja = ref<KayttajaResponse>()
    const kayttoehdot = ref<boolean>(false)

    const loadKayttaja = async () => {
        kayttaja.value = await kayttajaApi.getKirjautunutKayttaja()
    }

    if(!kayttaja.value) {
        await loadKayttaja()
    }

    if(kayttaja.value) {
        kayttoehdot.value = kayttaja.value.ehdot ?? false
    }

    return {
        kayttaja,
        kayttoehdot  
    }
})

const getters = {
    getKayttaja() {
        return useSWRV(key, fetcher, options)
    } 
}

const methods = {
    async resetPassword() {
        const response = await kayttajaApi.resetPassword()

        if(response) {
            ElMessageBox.alert("Salasanan vaihtoviesti on lähetetty sähköpostiisi.")
        }
        else {
            ElMessage.error("Salasanan vaihtoviestin lähetys ei onnistunut!")
        }
    },
    async consestGDPR() {
        const response = await kayttajaApi.consestGDPR()

        if(response) {
            ElMessage.success("Käyttöehdot hyväksytty!")

            return true
        }
        else {
            ElMessage.error("Käyttöehtojen hyväksyntä ei onnistunut!")

            return false
        }
    },
    mapKayttajaResponseToRequestPut(response : KayttajaResponse) {
        const sairaalat = response.Sairaalat?.map(s => {
            return {
                id: s.id,
                suunnittelija: s.suunnittelija,
                sairaala: {
                    id: s.sairaala?.id
                }
            }
        })

        return {
            email: response.email,
            etunimi: response.etunimi,
            sukunimi: response.sukunimi,
            sairaalat:  sairaalat
        }

    }
}

export default { 
    getters,
    methods,
    kayttajaState
}
