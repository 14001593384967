import { ElMessage } from 'element-plus';
import { defineComponent, ref, toRef, watch, watchEffect } from 'vue';
import { useMallihuonekortit } from './mallihuonekortitComposables';
export default defineComponent({
  props: {
    sairaala: {
      type: Number,
      required: true
    },
    tilanimike: {
      type: Number,
      required: true
    }
  },
  emits: ["mallihuonekorttiSelected"],
  setup(props, {
    emit
  }) {
    const {
      data,
      error,
      tilanimike
    } = useMallihuonekortit(toRef(props.sairaala));
    const mallihuonekorttiSelected = ref();
    const mallihuonekorttiEnable = ref(false);
    watchEffect(() => {
      tilanimike.value = props.tilanimike;
      mallihuonekorttiEnable.value = false;
      mallihuonekorttiSelected.value = undefined;
    });
    watch(mallihuonekorttiSelected, () => {
      emit("mallihuonekorttiSelected", mallihuonekorttiSelected.value);
    });
    watch(mallihuonekorttiEnable, () => {
      if (!mallihuonekorttiEnable.value) {
        mallihuonekorttiSelected.value = undefined;
      }
    });
    watch(error, () => {
      if (error) {
        ElMessage.error("Mallihuonekorttien lataus ei onnistunut!");
      }
    });
    return {
      data,
      mallihuonekorttiSelected,
      mallihuonekorttiEnable
    };
  }
});