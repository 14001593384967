import { HuonekorttiHistoriaTyyppi } from '@/api/models/huonekortti-historia-tyyppi';
import { provideHuonekortti } from '@/types/Symbols';
import { computed, defineComponent, inject } from 'vue';
import ComTilaHistoriaItem from './ComTilaHistoriaItem.vue';
export default defineComponent({
  components: {
    ComTilaHistoriaItem
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const huonekortti = inject(provideHuonekortti);
    const tilatCurrent = computed(() => {
      var _a;
      return (_a = huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value) === null || _a === void 0 ? void 0 : _a.tilat;
    });
    const historia = computed(() => {
      const temp = props.data;
      return temp.sort((a, b) => a.id - b.id).reverse();
    });
    const getCurrentData = val => {
      var _a, _b, _c;
      if (val.tyyppi != HuonekorttiHistoriaTyyppi.Muutos) {
        return undefined;
      }
      const i = historia.value.findIndex(h => h.id == val.id);
      const next = historia.value[i - 1];
      if (((_a = next === null || next === void 0 ? void 0 : next.tila) === null || _a === void 0 ? void 0 : _a.id) != ((_b = val.tila) === null || _b === void 0 ? void 0 : _b.id)) {
        return (_c = tilatCurrent.value) === null || _c === void 0 ? void 0 : _c.find(t => {
          var _a;
          return t.id == ((_a = val.tila) === null || _a === void 0 ? void 0 : _a.id);
        });
      } else {
        return next;
      }
    };
    return {
      historia,
      getCurrentData
    };
  }
});