import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    vastuuyksikot: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {};
  }
});