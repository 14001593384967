import { defineComponent } from 'vue';
import ComHankkeet from '@/components/etusivu/ComHankkeet.vue';
export default defineComponent({
  name: "EtusivuView",
  components: {
    ComHankkeet
  },
  setup() {
    return {};
  }
});