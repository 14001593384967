import { HuonekorttiStatusResponse } from '@/api/models/huonekortti-status-response';
import raportointiService from '@/methods/raportointiService';
import { provideHuonekortti } from '@/types/Symbols';
import { computed, defineComponent, inject } from 'vue';
import ComMoreButton from '../common/buttons/ComMoreButton.vue';
export default defineComponent({
  emits: ["showTiedot", "showMuutokset"],
  components: {
    ComMoreButton
  },
  setup() {
    const loadHuonekorttiRaportti = raportointiService.methods.loadHuonekorttiRaportti;
    const huonekortti = inject(provideHuonekortti);
    const isHyvaksytty = computed(() => {
      var _a;
      const status = (_a = huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value) === null || _a === void 0 ? void 0 : _a.status;
      if (status === HuonekorttiStatusResponse.Hyvaksytty) {
        return true;
      } else {
        return false;
      }
    });
    const tulostaClick = () => {
      if (huonekortti === null || huonekortti === void 0 ? void 0 : huonekortti.value) {
        loadHuonekorttiRaportti(1, [huonekortti.value.id]);
      }
    };
    return {
      isHyvaksytty,
      tulostaClick
    };
  }
});