import axios, { AxiosInstance } from "axios";
import { getAccessToken } from "@/auth/auth0-plugin";

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-type": "application/json"
  },
});

apiClient.interceptors.request.use(async function (config) {
  const token = await getAccessToken()

  if(config && config.headers) {
    config.headers['Authorization'] = token ? `Bearer ${token}` : '';
  }
  return config;

});

export default apiClient;