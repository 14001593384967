import { LiiteResponse } from "@/api/models/liite-response";
import { LiiteRequest } from "@/api/models/liite-request"
import http from "@/http-common";
import { errorHandler } from "./baseApi";
import { LiiteId } from "@/api/models/liite-id";
import { UploadRawFile } from "element-plus";
import { LiitetiedostoResponse } from "@/api/models/liitetiedosto-response";

class LiiteApi {
    getLiite(id : number) : Promise<LiitetiedostoResponse | undefined> {
        return http.get(`/liitteet/${id}`).then(response => {
            return response.data
        }).catch(error => {
            errorHandler(error)
        })
    }

    getLiitteet(viite : number, tyyppi : number) : Promise<LiiteResponse[] | undefined> {
        return http.get("/liitteet", { params: {
            viite: viite,
            tyyppi: tyyppi
        }}).then(response => {
            return response.data
        }).catch(error  => {
            errorHandler(error)
        })
    }

    addLiite(request : LiiteRequest) : Promise<LiiteId | undefined> {
        return http.post("/liitteet", request).then(response => {
            return response.data
        }).catch(error => {
            errorHandler(error)
        })
    }

    addLiiteFile(id : number, file : UploadRawFile) : Promise<LiiteResponse | undefined> {
        return http.post(`/liitteet/${id}`, file, { headers: {
            "content-type": file.type,
            "Content-Disposition": `attachment; filename="${file.name}"`  
        }}).then(reponse => {
            return reponse.data
        }).catch(error => {
            errorHandler(error)
        })
    }

    deleteLiite(id : number) : Promise<boolean> {
        return http.delete(`/liitteet/${id}`).then(() => {
            return true
        }).catch((error) => {
            errorHandler(error)
            
            return false
        })
    }
}

export default new LiiteApi