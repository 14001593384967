import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_space = _resolveComponent("el-space");
  return _ctx.data && _ctx.data.length > 0 ? (_openBlock(), _createBlock(_component_el_space, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_switch, {
      modelValue: _ctx.mallihuonekorttiEnable,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.mallihuonekorttiEnable = $event),
      "active-text": "Käytä mallihuonekorttia"
    }, null, 8, ["modelValue"]), _ctx.mallihuonekorttiEnable ? (_openBlock(), _createBlock(_component_el_select, {
      key: 0,
      modelValue: _ctx.mallihuonekorttiSelected,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.mallihuonekorttiSelected = $event),
      "value-key": "id",
      size: "small"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (mallihuonekortti, index) => {
        return _openBlock(), _createBlock(_component_el_option, {
          key: index,
          label: mallihuonekortti.toiminta,
          value: mallihuonekortti
        }, null, 8, ["label", "value"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue"])) : _createCommentVNode("", true)]),
    _: 1
  })) : _createCommentVNode("", true);
}