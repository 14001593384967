import { defineComponent } from 'vue';
import { Avatar } from '@element-plus/icons-vue';
import ComAvatar from '@/components/layout/header/ComAvatar.vue';
export default defineComponent({
  name: "LogoutButton",
  components: {
    Avatar,
    ComAvatar
  },
  setup() {
    return {};
  }
});