import ComTrashButton from '@/components/common/buttons/ComTrashButton.vue';
import { defineComponent, ref } from 'vue';
import ComRoskakoriContent from './ComRoskakoriContent.vue';
export default defineComponent({
  setup() {
    const show = ref(false);
    return {
      show
    };
  },
  components: {
    ComTrashButton,
    ComRoskakoriContent
  }
});