import "core-js/modules/es.array.push.js";
import ComLoading from '@/components/common/ComLoading.vue';
import hankeService from '@/methods/hankeService';
import { provideHanke, provideSelectedVastuuyksikot } from '@/types/Symbols';
import { defineComponent, inject } from 'vue';
import ComHanketiedotVaatimuksetFieldHanke from './ComHanketiedotVaatimuksetFieldHanke.vue';
import ComHanketiedotVaatimuksetFieldHankeKategoria from './ComHanketiedotVaatimuksetFieldHankeKategoria.vue';
import ComHanketiedotVaatimuksetFieldVastuuyksikko from './ComHanketiedotVaatimuksetFieldVastuuyksikko.vue';
export default defineComponent({
  components: {
    ComHanketiedotVaatimuksetFieldHanke,
    ComHanketiedotVaatimuksetFieldVastuuyksikko,
    ComHanketiedotVaatimuksetFieldHankeKategoria,
    ComLoading
  },
  props: {
    editable: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const {
      data: hankeVaatimuskategoriat
    } = hankeService.methods.getHankeVaatimuskategoriat();
    const hanke = inject(provideHanke);
    const vastuuyksikot = inject(provideSelectedVastuuyksikot);
    const getHankeVaatimus = kategoria => {
      var _a, _b;
      return (_b = (_a = hanke === null || hanke === void 0 ? void 0 : hanke.value) === null || _a === void 0 ? void 0 : _a.kategorisoidutVaatimukset) === null || _b === void 0 ? void 0 : _b.find(k => {
        var _a;
        return ((_a = k.kategoria) === null || _a === void 0 ? void 0 : _a.id) == kategoria;
      });
    };
    const saveHankeVaatimusClick = val => {
      if (hanke === null || hanke === void 0 ? void 0 : hanke.value) {
        hanke.value.vaatimukset = val.vaatimukset;
      }
    };
    const saveVastuuyksikkoVaatimukset = val => {
      const vastuuyksikko = vastuuyksikot === null || vastuuyksikot === void 0 ? void 0 : vastuuyksikot.value.find(v => v.id == val.id);
      if (vastuuyksikko) {
        vastuuyksikko.vaatimukset = val.vaatimukset;
      }
    };
    const addHankeKategoriaVaatimuksetClick = val => {
      var _a;
      if (hanke === null || hanke === void 0 ? void 0 : hanke.value) {
        (_a = hanke === null || hanke === void 0 ? void 0 : hanke.value.kategorisoidutVaatimukset) === null || _a === void 0 ? void 0 : _a.push(val);
      }
    };
    const updateHankeKategoriaVaatimuksetClick = val => {
      var _a, _b;
      const vaatimus = (_b = (_a = hanke === null || hanke === void 0 ? void 0 : hanke.value) === null || _a === void 0 ? void 0 : _a.kategorisoidutVaatimukset) === null || _b === void 0 ? void 0 : _b.find(k => k.id == val.id);
      if (vaatimus) {
        vaatimus.vaatimus = val.vaatimus;
      }
    };
    return {
      hankeVaatimuskategoriat,
      hanke,
      vastuuyksikot,
      saveHankeVaatimusClick,
      saveVastuuyksikkoVaatimukset,
      getHankeVaatimus,
      addHankeKategoriaVaatimuksetClick,
      updateHankeKategoriaVaatimuksetClick
    };
  }
});