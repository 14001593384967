import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "card-margin-bottom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_input = _resolveComponent("el-input");
  const _component_ComMallihuonekortitSelect = _resolveComponent("ComMallihuonekortitSelect");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_text = _resolveComponent("el-text");
  const _component_ComPlusButton = _resolveComponent("ComPlusButton");
  const _component_ComMinusButton = _resolveComponent("ComMinusButton");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
    inline: "",
    "label-position": "top",
    rules: _ctx.rules,
    ref: "addHuonekorttiRef",
    model: _ctx.huonekortti
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      lg: 12,
      sm: 24
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, null, {
        default: _withCtx(() => {
          var _a, _b;
          return [_createVNode(_component_el_col, {
            lg: 8,
            sm: 24
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "Tilanimike",
              prop: "tilanimike"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                size: "small",
                modelValue: _ctx.huonekortti.tilanimike.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.huonekortti.tilanimike.id = $event),
                style: {
                  "width": "100%"
                }
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tilanimikkeet, tilanimike => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: tilanimike.id,
                    label: tilanimike.nimi,
                    value: tilanimike.id
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            lg: 8,
            sm: 24
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "Täsmennys",
              prop: "toiminta"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                size: "small",
                modelValue: _ctx.huonekortti.toiminta,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.huonekortti.toiminta = $event),
                maxlength: 50,
                disabled: _ctx.mallihuonekortti
              }, null, 8, ["modelValue", "disabled"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            lg: 8,
            sm: 24
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "Vastuuyksikkö",
              prop: "vastuuyksikko"
            }, {
              default: _withCtx(() => [_ctx.writableVastuuyksikot ? (_openBlock(), _createBlock(_component_el_select, {
                key: 0,
                size: "small",
                modelValue: _ctx.huonekortti.vastuuyksikko.id,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.huonekortti.vastuuyksikko.id = $event),
                style: {
                  "width": "100%"
                }
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.writableVastuuyksikot, vastuuyksikko => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: vastuuyksikko.id,
                    value: vastuuyksikko.id,
                    label: vastuuyksikko.nimi
                  }, null, 8, ["value", "label"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])) : _createCommentVNode("", true)]),
              _: 1
            })]),
            _: 1
          }), ((_b = (_a = _ctx.hanke) === null || _a === void 0 ? void 0 : _a.sairaala) === null || _b === void 0 ? void 0 : _b.id) && _ctx.huonekortti.tilanimike.id ? (_openBlock(), _createBlock(_component_ComMallihuonekortitSelect, {
            key: 0,
            tilanimike: _ctx.huonekortti.tilanimike.id,
            sairaala: _ctx.hanke.sairaala.id,
            onMallihuonekorttiSelected: _ctx.mallihuonekorttiSelected
          }, null, 8, ["tilanimike", "sairaala", "onMallihuonekorttiSelected"])) : _createCommentVNode("", true)];
        }),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      lg: 10,
      sm: 24
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        prop: "tilat"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tilat, (tila, index) => {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: index
          }, [_createVNode(_component_el_col, {
            span: 3,
            style: {
              "text-align": "center"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: index == 0 ? 'Tilat' : ''
            }, {
              default: _withCtx(() => [_createVNode(_component_el_text, null, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(index + 1), 1)]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1032, ["label"])]),
            _: 2
          }, 1024), _createVNode(_component_el_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: index == 0 ? 'Tilatunnus' : ''
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: tila.tilatunnus,
                "onUpdate:modelValue": $event => tila.tilatunnus = $event,
                size: "small",
                placeholder: "Tilatunnus"
              }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
              _: 2
            }, 1032, ["label"])]),
            _: 2
          }, 1024), _createVNode(_component_el_col, {
            span: 7,
            offset: 1
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: index == 0 ? 'Pinta-ala m2' : ''
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: tila.pintaAla,
                "onUpdate:modelValue": $event => tila.pintaAla = $event,
                size: "small",
                placeholder: "Pinta-ala m2",
                precision: 2,
                step: 0.1,
                type: "number"
              }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
              _: 2
            }, 1032, ["label"])]),
            _: 2
          }, 1024), _createVNode(_component_el_col, {
            span: 4,
            offset: 1
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: index == 0 ? ' ' : ''
            }, {
              default: _withCtx(() => [index + 1 === _ctx.tilat.length ? (_openBlock(), _createBlock(_component_ComPlusButton, {
                key: 0,
                onClick: _cache[3] || (_cache[3] = $event => _ctx.addTila())
              })) : _createCommentVNode("", true), index + 1 !== _ctx.tilat.length ? (_openBlock(), _createBlock(_component_ComMinusButton, {
                key: 1,
                onClick: $event => _ctx.removeTila(index)
              }, null, 8, ["onClick"])) : _createCommentVNode("", true)]),
              _: 2
            }, 1032, ["label"])]),
            _: 2
          }, 1024)], 64);
        }), 128))]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      lg: 2,
      sm: 24
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: " "
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          type: "success",
          size: "small",
          onClick: _cache[4] || (_cache[4] = $event => _ctx.addHuonekortti())
        }, {
          default: _withCtx(() => [_createTextVNode("Lisää")]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["rules", "model"])]);
}