import "core-js/modules/es.array.push.js";
import { defineComponent, inject, ref, watchEffect } from 'vue';
import ComOikeusButtons from '../ComOikeusButtons.vue';
import ComSelect from '@/components/common/ComSelect.vue';
export default defineComponent({
  components: {
    ComOikeusButtons,
    ComSelect
  },
  emits: ["oikeudetChange"],
  setup(props, {
    emit
  }) {
    const vastuuyksikot = inject("vastuuyksikot");
    const vastuuyksikkoSelected = ref();
    const oikeudet = ref({
      luku: true,
      kirjoitus: false,
      raportointi: false
    });
    const valitseKaikkiclick = () => {
      vastuuyksikkoSelected.value = [];
      vastuuyksikkoSelected.value = vastuuyksikot.value.map(v => v.id);
    };
    watchEffect(() => {
      if (vastuuyksikkoSelected.value) {
        const response = [];
        vastuuyksikkoSelected.value.forEach(a => {
          const oikeus = {};
          oikeus.luku = oikeudet.value.luku;
          oikeus.kirjoitus = oikeudet.value.kirjoitus;
          oikeus.raportointi = oikeudet.value.raportointi;
          oikeus.vastuuyksikko = {
            id: a
          };
          response.push(oikeus);
        });
        emit("oikeudetChange", response);
      }
    });
    return {
      vastuuyksikkoSelected,
      vastuuyksikot,
      oikeudet,
      valitseKaikkiclick
    };
  }
});