import "core-js/modules/es.array.push.js";
import { defineComponent, inject, ref } from 'vue';
import { ElMessage } from 'element-plus';
import ComSuccessButton from '../common/buttons/ComSuccessButton.vue';
import ComUploadFile from '../common/ComUploadFile.vue';
import liiteService from '@/methods/liiteService';
export default defineComponent({
  components: {
    ComSuccessButton,
    ComUploadFile
  },
  props: {
    viite: {
      type: Number,
      required: true
    },
    tyyppi: {
      type: Number,
      required: true
    }
  },
  emits: ["liiteChange"],
  setup(props, {
    emit
  }) {
    const addLiite = liiteService.methods.addLiite;
    const liite = ref({});
    const fileRef = ref();
    const file = ref([]);
    const liitteet = inject("liitteet");
    const addFile = async () => {
      if (liitteet.value.length > 4) {
        ElMessage.error("Maksimissaan 5 liitettä per huonekortti!");
      } else {
        liite.value.tyyppi = {
          id: props.tyyppi
        };
        liite.value.viite = {
          id: props.viite
        };
        const response = await addLiite(file.value[0], liite.value);
        if (response) {
          liitteet.value.push(response);
          liite.value = {};
          file.value = [];
          emit("liiteChange");
        }
      }
    };
    return {
      liite,
      fileRef,
      file,
      addFile
    };
  }
});