import { computed, defineComponent } from 'vue';
import { htmlToText } from 'html-to-text';
import huonekorttiHistoriaService from '@/methods/huonekorttiHistoriaService';
export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    },
    previousData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const henkilomaaraNorm = computed(() => {
      var _a, _b, _c, _d;
      const a = (_b = (_a = props.previousData.henkilomaaraNorm) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '';
      const b = (_d = (_c = props.data.henkilomaaraNorm) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '';
      if (a === b) {
        return false;
      }
      return huonekorttiHistoriaService.methods.diffAndSanitize(a, b);
    });
    const henkilomaaraMax = computed(() => {
      var _a, _b, _c, _d;
      const a = (_b = (_a = props.previousData.henkilomaaraMax) === null || _a === void 0 ? void 0 : _a.toString().trim()) !== null && _b !== void 0 ? _b : '';
      const b = (_d = (_c = props.data.henkilomaaraMax) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '';
      if (a === b) {
        return false;
      }
      return huonekorttiHistoriaService.methods.diffAndSanitize(a, b);
    });
    const sahkoluokka = computed(() => {
      var _a, _b, _c, _d;
      const a = (_b = (_a = props.previousData.sahkoluokka) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '';
      const b = (_d = (_c = props.data.sahkoluokka) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '';
      if (a === b) {
        return false;
      }
      return huonekorttiHistoriaService.methods.diffAndSanitize(a, b);
    });
    const toimintaAika = computed(() => {
      var _a, _b;
      const a = (_a = props.previousData.toimintaAika) !== null && _a !== void 0 ? _a : '';
      const b = (_b = props.data.toimintaAika) !== null && _b !== void 0 ? _b : '';
      if (a === b) {
        return false;
      }
      return huonekorttiHistoriaService.methods.diffAndSanitize(a, b);
    });
    const toiminnanKuvaus = computed(() => {
      var _a, _b;
      const a = htmlToText((_a = props.previousData.toiminnanKuvaus) !== null && _a !== void 0 ? _a : '');
      const b = htmlToText((_b = props.data.toiminnanKuvaus) !== null && _b !== void 0 ? _b : '');
      if (a === b) {
        return false;
      }
      return huonekorttiHistoriaService.methods.diffAndSanitize(a, b);
    });
    const toiminnanVaatimukset = computed(() => {
      var _a, _b;
      const a = htmlToText((_a = props.previousData.toiminnanVaatimukset) !== null && _a !== void 0 ? _a : '');
      const b = htmlToText((_b = props.data.toiminnanVaatimukset) !== null && _b !== void 0 ? _b : '');
      if (a === b) {
        return false;
      }
      return huonekorttiHistoriaService.methods.diffAndSanitize(a, b);
    });
    const tyhja = computed(() => {
      if (!henkilomaaraMax.value && !henkilomaaraNorm.value && !sahkoluokka.value && !toimintaAika.value && !toiminnanKuvaus.value && !toiminnanVaatimukset.value) {
        return false;
      }
      return true;
    });
    return {
      henkilomaaraNorm,
      henkilomaaraMax,
      sahkoluokka,
      toimintaAika,
      toiminnanKuvaus,
      toiminnanVaatimukset,
      tyhja
    };
  }
});