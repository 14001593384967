import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Editor = _resolveComponent("Editor");
  return _ctx.disabled ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Editor, {
    init: _ctx.nonEditable,
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.model = $event),
    "initial-value": _ctx.modelValue,
    disabled: true
  }, null, 8, ["init", "modelValue", "initial-value"])])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_Editor, {
    init: _ctx.editable,
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.model = $event),
    "initial-value": _ctx.modelValue,
    disabled: false
  }, null, 8, ["init", "modelValue", "initial-value"])]));
}