import kayttajaService from '@/methods/kayttajaService';
import { defineComponent } from 'vue';
import ComEmpty from '@/components/common/ComEmpty.vue';
export default defineComponent({
  setup() {
    const resetPassword = kayttajaService.methods.resetPassword;
    const {
      data: kayttaja
    } = kayttajaService.getters.getKayttaja();
    const changePasswordClick = () => {
      resetPassword();
    };
    return {
      kayttaja,
      changePasswordClick
    };
  },
  components: {
    ComEmpty
  }
});