import "core-js/modules/es.array.push.js";
import kommenttiService from '@/methods/kommenttiService';
import { defineComponent, inject, ref } from 'vue';
import ComSuccessButton from '../common/buttons/ComSuccessButton.vue';
import ComNotifyKommentti from './ComNotifyKommentti.vue';
export default defineComponent({
  props: {
    viite: {
      type: Number,
      required: true
    },
    tyyppi: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const addKommentti = kommenttiService.methods.addKommentti;
    const kommentti = ref({});
    const kommentit = inject("kommentit");
    const addCommentClick = async () => {
      kommentti.value.tyyppi = {
        id: props.tyyppi
      };
      kommentti.value.viite = {
        id: props.viite
      };
      const response = await addKommentti(kommentti.value);
      if (response) {
        kommentit.value.push(response);
        kommentti.value.kommentti = "";
      }
    };
    const valitutKayttajatChange = val => {
      if (val) {
        kommentti.value.huomio = val.map(v => {
          return {
            id: v.id
          };
        });
      }
    };
    return {
      kommentti,
      addCommentClick,
      valitutKayttajatChange
    };
  },
  components: {
    ComSuccessButton,
    ComNotifyKommentti
  }
});