import { VastuuyksikkoRequest } from "@/api/models/vastuuyksikko-request";
import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response";
import vastuuyksikkoApi from "@/apis/vastuuyksikkoApi";
import { ElMessage } from "element-plus";

const methods = {
    async add (request : VastuuyksikkoRequest) {
        const response = await vastuuyksikkoApi.addVaastuuyksikko(request)

        if(!response) {
            ElMessage.error("Vastuuyksikön lisäys epäonnistui")
        }

        return response
    },
    async update (id : number, request : VastuuyksikkoRequest) {
        const response = await vastuuyksikkoApi.updateVastuuyksikko(id, request)

        if(!response) {
            ElMessage.error("Vastuuyksikon päivitys epäonnistui")
        }

        return response
    },
    async delete (id : number) {
        const response = await vastuuyksikkoApi.deleteVastuuyksikko(id)

        if(!response) {
            ElMessage.error("Vastuuyksikön poisto epäonnistui")
        }

        return response
    },
    mapResponseToRequest(response : VastuuyksikkoResponse, hankeId : number) {
        const request : VastuuyksikkoRequest = {
            nimi:  response.nimi,
            vaatimukset: response.vaatimukset,
            hanke: {
                id: hankeId
            }
        }

        return request
    }
}

export default {
    methods
}