import { KategoriaResponse } from "@/api/models/kategoria-response"
import huonekorttiAPI from "@/apis/huonekorttiAPI"
import { ElMessage, CascaderOption } from "element-plus"
import useSWRV from "swrv"
import LocalStorageCache from "swrv/dist/cache/adapters/localStorage"

const methods = {
    getNimikeKategoriat(nimikkeet : boolean) {
        const key = "/nimikkeet/kategoriat/?" + new URLSearchParams({nimikkeet : nimikkeet.toString()})
        const fetcher = (key : string, nimikkeet : boolean) => {
            return huonekorttiAPI.getNimikeKategoriat(nimikkeet)
        }
        const options = {
            dedupingInterval: 36000000,
            revalidateOnFocus: false,
            cache: new LocalStorageCache('nimikeKategoriat'),
        }

        const { data, error } = useSWRV([key, nimikkeet], fetcher, options)

        if(error.value) {
            ElMessage.error("Nimikkeiden lataus epäonnistui!")
        }
            
        return { data, error }
    },
    setNodes(kategoriat : KategoriaResponse[], poistetut? : boolean) {
        const nodes = [] as CascaderOption[]

        kategoriat.forEach(k => {
            const node = {} as CascaderOption
            node.value = k.id
            node.label = k.nimi
            node.disabled = !k.kaytossa
            k.children?.forEach(c => {
                const child = {} as CascaderOption
                child.value = c.id
                child.label = c.nimi
                child.disabled = !c.kaytossa
                c.nimikkeet?.forEach(n => {
                    const nimike = {} as CascaderOption
                    nimike.value = n.id
                    nimike.label = n.nimi
                    nimike.disabled = !n.kaytossa
                    if(!nimike.disabled || (nimike.disabled && poistetut)){
                        if(child.children == undefined) 
                            child.children = []

                        child.children.push(nimike)
                    }
                })
                if(!child.disabled || (child.disabled && poistetut)) {
                    if(node.children == undefined) 
                        node.children = []

                    node.children.push(child)
                }
            })
            if(!node.disabled || (node.disabled && poistetut)) {
                nodes.push(node)
            }
        })
        return nodes
    }
}

export default {
    methods
}