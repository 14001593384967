import http from "@/http-common";
import { VastuuyksikkoRequest } from "@/api/models/vastuuyksikko-request";
import { errorHandler } from "./baseApi";
import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response";

class vastuuyksikkoApi {
    addVaastuuyksikko(request : VastuuyksikkoRequest) : Promise<VastuuyksikkoResponse | undefined> {
        return http.post("vastuuyksikot", request).then(response => {
            return response.data
        }).catch(error => {
            errorHandler(error)
        })
    }

    updateVastuuyksikko(id : number, request : VastuuyksikkoRequest) : Promise<VastuuyksikkoResponse | undefined> {
        return http.put(`vastuuyksikot/${id}`, request).then(response => {
            return response.data
        }).catch(error => {
            errorHandler(error)
        })
    }

    deleteVastuuyksikko(id : number) : Promise<boolean> {
        return http.delete(`vastuuyksikot/${id}`).then(() => {
            return true
        }).catch(error => {
            errorHandler(error)

            return false
        })
    }
}

export default new vastuuyksikkoApi